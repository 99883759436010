import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactUsComponent } from './shared/contact-us/contact-us.component';
import { BuyerNotificationComponent } from './buyer/buyer-notification/buyer-notification.component';
import { BuyerAssistanceComponent } from './buyer/buyer-assistance/buyer-assistance.component';
import { MoreArticlesComponent } from './buyer/buyer-assistance/more-articles/more-articles.component';
import { ArticleDetailsComponent } from './buyer/buyer-assistance/article-details/article-details.component';
import { ProductDetailsComponent } from './buyer/product-details/product-details.component';
import { BrandComponent } from './buyer/brand/brand.component';
import { DealComponent } from './buyer/deal/deal.component';
import { CartComponent } from './buyer/cart/cart.component';
import { BidsOffersComponent } from './buyer/bids-offers/bids-offers.component';
import { MyBidsComponent } from './buyer/my-bids/my-bids.component';
import { WriteReviewComponent } from './buyer/write-review/write-review.component';
import { BuyerManageCategoriesComponent } from './buyer/buyer-manage-categories/buyer-manage-categories.component';
import { BuyerWalletComponent } from './buyer/buyer-wallet/buyer-wallet.component';
import { BuyerAddMoneyComponent } from './buyer/buyer-add-money/buyer-add-money.component';
import { BuyerManagePaymentMethodsComponent } from './buyer/buyer-manage-payment-methods/buyer-manage-payment-methods.component';
import { BuyerAddPaymentMethodComponent } from './buyer/buyer-add-payment-method/buyer-add-payment-method.component';
import { BuyerTransactionHistoryComponent } from './buyer/buyer-transaction-history/buyer-transaction-history.component';
import { BuyerTrackOrdersComponent } from './buyer/buyer-track-orders/buyer-track-orders.component';
import { BuyerViewOrderDetailsComponent } from './buyer/buyer-view-order-details/buyer-view-order-details.component';
import { BuyerWishlistComponent } from './buyer/buyer-wishlist/buyer-wishlist.component';
import { CheckoutComponent } from './buyer/checkout/checkout.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './shared/terms-of-use/terms-of-use.component';
import { InfringementPolicyComponent } from './shared/infringement-policy/infringement-policy.component';
import { AntiCounterfeitingPolicyComponent } from './shared/anti-counterfeiting-policy/anti-counterfeiting-policy.component';
import { CancellationReturnsPolicyComponent } from './shared/cancellation-returns-policy/cancellation-returns-policy.component';
import { InsurancePolicyComponent } from './shared/insurance-policy/insurance-policy.component';
import { HowItWorksComponent } from './shared/how-it-works/how-it-works.component';
import { ServicesComponent } from './shared/services/services.component';
import { PartnersComponent } from './shared/partners/partners.component';
import { AboutUsComponent } from './shared/about-us/about-us.component';

import { SellerAuthGuard } from './helpers/seller-auth.guard';
import { TestingComponent } from './testing/testing.component';
import { BuyerdashboardComponent } from './buyer/buyerdashboard/buyerdashboard.component';
import { BusinessDetailsBuyerComponent } from './buyer/buyerdashboard/business-details-buyer/business-details-buyer.component';
import { BankDetailsBuyerComponent } from './buyer/buyerdashboard/bank-details-buyer/bank-details-buyer.component';
import { AdminAuthGuard } from './helpers/admin-auth.guard';
import { BuyerAuthGuard } from './helpers/buyer-auth.guard';
import { LoggedinAuthGuard } from './helpers/loggedin-auth.guard';
import { GuestGuard } from './helpers/guest.guard';
import { RegistrationAuthGuard } from './helpers/registration-auth.guard';
import { CategoryComponent } from './buyer/category/category.component';
import { SearchComponent } from './buyer/search/search.component';
import { SerllerInfoComponent } from './buyer/serller-info/serller-info.component';
import { PersonalDetailsBuyerComponent } from './buyer/personal-details-buyer/personal-details-buyer.component';
import { CardConfirmationComponent } from './buyer/card-confirmation/card-confirmation.component';
import { AddMoneyCardConfirmationComponent } from './buyer/add-money-card-confirmation/add-money-card-confirmation.component';
import { PaymentSuccessComponent } from './buyer/payment-success/payment-success.component';
import { PaymentFailedComponent } from './buyer/payment-failed/payment-failed.component';
import { AllBidsAndOffersComponent } from './buyer/all-bids-and-offers/all-bids-and-offers.component';
import { BuyerOrderHistoryComponent } from './buyer/buyer-order-history/buyer-order-history.component';

import { InvoiceComponent } from './buyer/invoice/invoice.component';
import { ReturnPolicyComponent } from './shared/return-policy/return-policy.component';
import { BuyerChargesInvoiceComponent } from './buyer/buyer-charges-invoice/buyer-charges-invoice.component';
import { BuyerTransportationDocumentsComponent } from './buyer/buyer-transportation-documents/buyer-transportation-documents.component';
import { BuyerBuyNowPayLaterComponent } from './buyer/buyer-buy-now-pay-later/buyer-buy-now-pay-later.component';
import { BnplPaymentSuccessComponent } from './buyer/bnpl-payment-success/bnpl-payment-success.component';
import { BnplTransactionReportsComponent } from './buyer/bnpl-transaction-reports/bnpl-transaction-reports.component';
import { EquibizPayLaterComponent } from './shared/equibiz-pay-later/equibiz-pay-later.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
    {
        path: 'product',
        redirectTo: 'product',
        pathMatch: 'full'
    },
    {
        path: 'testing',
        component: TestingComponent
    },
    {
        path: 'how-it-works',
        component: HowItWorksComponent
    },
    {
        path: 'services',
        component: ServicesComponent
    },
    {
        path: 'partners',
        component: PartnersComponent
    },
    {
        path: 'about-us',
        component: AboutUsComponent
    },
    {
        path: 'contact-us',
        component: ContactUsComponent
    },
    {
        path: 'buyer-notification',
        component: BuyerNotificationComponent
    },
    {
        path: 'buyer/assistance',
        component: BuyerAssistanceComponent
    },
    {
        path: 'buyer/more-articles/:id',
        component: MoreArticlesComponent
    },
    {
        path: 'buyer/article-details/:id',
        component: ArticleDetailsComponent
    },
    {
        path: 'equibiz-pay-later',
        component: EquibizPayLaterComponent
    },

    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
        canActivate: [LoggedinAuthGuard]
    },

    {
        path: 'seller-info',
        component: SerllerInfoComponent
    },
    // {
    //     path: 'product/details/:uid',
    //     component: ProductDetailsComponent
    // },
    {
        path: 'brand/:brand_slug',
        component: BrandComponent
    },
    {
        path: 'category/:category_slug',
        component: CategoryComponent
    },
    {
        path: 'search',
        component: SearchComponent
    },
    {
        path: 'deal/:seller_product_id/:market_place',
        component: DealComponent
    },
    {
        path: 'cart',
        component: CartComponent
    },
    {
        path: 'checkout',
        component: CheckoutComponent
    },
    {
        path: 'buyer/bids-offers/:product_id',
        component: BidsOffersComponent
    },
    {
        path: 'buyer/my-bids',
        component: MyBidsComponent
    },
    {
        path: 'buyer/write-review',
        component: WriteReviewComponent
    },
    {
        path: 'buyer/buyer-wallet',
        component: BuyerWalletComponent
    },
    {
        path: 'buyer/buyer-add-money',
        component: BuyerAddMoneyComponent
    },
    {
        path: 'buyer/buyer-manage-payment-methods',
        component: BuyerManagePaymentMethodsComponent
    },
    {
        path: 'buyer/buyer-transaction-history',
        component: BuyerTransactionHistoryComponent
    },
    {
        path: 'buyer/buyer-view-order-details/:orderId',
        component: BuyerViewOrderDetailsComponent
    },
    {
        path: 'buyer/buyer-wishlist',
        component: BuyerWishlistComponent
    },
    
    // {
    //     path: 'buyer/buyer-add-payment-method',
    //     component: BuyerAddPaymentMethodComponent
    // },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent
    },
    {
        path: 'return-policy',
        component: ReturnPolicyComponent
    },
    {
        path: 'terms-of-use',
        component: TermsOfUseComponent
    },
    {
        path: 'infringement-policy',
        component: InfringementPolicyComponent
    },
    {
        path: 'anti-counterfeiting-policy',
        component: AntiCounterfeitingPolicyComponent
    },
    {
        path: 'logistics-policy',
        component: CancellationReturnsPolicyComponent
    },
    {
        path: 'insurance-policy',
        component: InsurancePolicyComponent
    },
    {
        path: 'buyer/buyer-buy-now-pay-later',
        component: BuyerBuyNowPayLaterComponent
    },



    //Buyer Routes
    {
        path: "buyer",
        canActivate: [BuyerAuthGuard],
        children: [
            { path: "dashboard", component: BuyerdashboardComponent },
            { path: "personal-details", component: PersonalDetailsBuyerComponent },
            { path: 'business-details', component: BusinessDetailsBuyerComponent },
            { path: 'bank-details', component: BankDetailsBuyerComponent },
            { path: 'card-confirmation', component: CardConfirmationComponent },
            { path: 'add-money/card/callback', component: AddMoneyCardConfirmationComponent },
            { path: 'payment/success', component: PaymentSuccessComponent },
            { path: 'payment/failed', component: PaymentFailedComponent },
            { path: 'bnpl/payment/success', component: BnplPaymentSuccessComponent },
            { path: 'bnpl/transaction/reports', component: BnplTransactionReportsComponent },
            { path: 'bids-offers', component: AllBidsAndOffersComponent },
            { path: 'buyer-track-orders', component: BuyerTrackOrdersComponent },
            { path: 'order-history', component: BuyerOrderHistoryComponent },
            { path: 'invoice', component: InvoiceComponent },
            { path: 'charges-invoice', component: BuyerChargesInvoiceComponent },
            { path: 'manage-categories', component: BuyerManageCategoriesComponent },
            { path: 'transportation-documents', component: BuyerTransportationDocumentsComponent },
        ]
    },

    //Common Routes
    { path: 'buyer/business-details', component: BusinessDetailsBuyerComponent },
    { path: 'buyer/bank-details', component: BankDetailsBuyerComponent },
    { path: 'reset-password/:reset-token', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
    { path: 'setpassword/:signup-token', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },

    {
        path: '**', // Navigate to Home Page if not found any page
        redirectTo: 'home',
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        useHash: false,
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
