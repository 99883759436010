import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CartService {

    constructor(private _http: HttpClient) { }

    getcartdetails(data) {
        return this._http.post(environment.api_url+'sma/cartdetails',data);
    }

    removeall(data) {
        return this._http.post(environment.api_url+'sma/cart/remove/seller',data);
    }

    removeproduct(data) {
        return this._http.post(environment.api_url+'sma/cart/remove',data);
    }

    cartrelatedproducts(data) {
        return this._http.post(environment.api_url+'sma/cartrelatedproducts',data);
    }

}
