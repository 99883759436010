<app-header-one [sticky]="true"></app-header-one>
<div class="container pb-5">
    <!-- BREADCRUMB -->
    <nav class="mb-2 pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
        <ol class="breadcrumb p-0 bg-transparent">
            <li class="breadcrumb-item">
                <a href="javascript:void(0);" class="txtseconday" [routerLink]="['/home']">Home
                </a>
            </li>
            <li class="breadcrumb-item" *ngFor="let cat of detail.category_title;">
                <a href="javascript:void(0);" class="txtseconday" [routerLink]="['/category/'+cat.slug]">{{ cat.name }}
                </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">{{ detail.name }}</li>
        </ol>
    </nav>
    <!-- /BREADCRUMB -->
    <!-- PRODUCT DESCRIPTION -->
    <div class="row mb-4 pdl-rw">
        <div class="col-lg-9 mb-4">
            <!-- PRODUCT TOP SLIDER -->
            <div class="row">
                <div class="col-lg-5 dtlitem dealdtlitem flx45">
                    <div class="feedback-slides mb-4  ">
                        <div class="client-feedback dtlfull">
                            <ngx-slick-carousel class="carousel feedback" #slickModal="slick-carousel"
                                [config]="imagesSlider">
                                <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                                    <img src="{{ slide.img }}" alt="">
                                </div>
                            </ngx-slick-carousel>
                        </div>
                    </div>

                    <div class="client-thumbnails dtlthmb text-center">
                        <ngx-slick-carousel class="carousel thumbs" #slickModal="slick-carousel"
                            [config]="thumbnailsSlider ">
                            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
                                <div class="thmbnaildtl">
                                    <img src="{{ slide.img }}" alt="" width="100%">
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
                <div class="col-lg-7 flx55">
                    <div class="pdscp-top th-cmn-bd pb-1  mb-3">
                        <h2 class="fs22 f-600 mb-2 pr-0">{{ detail.name }}</h2>
                        <p class="fs14 wt700 txtdefault pt-2">{{ detail.variant_value_txt }}</p>
                    </div>
                    <div class="pdscp-cntnt">
                        <p class="fs14 f-600 txtdefault mb-2 d-inline-block mr-4 pr-2">Total Listed Quantity: <span
                                class="f-800 fs16 dark-text">{{ detail.total_qty }}</span></p>

                        <p class="fs14 f-600 txtdefault mb-2 d-inline-block mr-4 pr-2" *ngIf="detail.child">Minimum Order Quantity: <span
                                    class="f-800 fs16 dark-text">{{ detail.min_qty }}</span></p>

                        <p class="fs14 f-600 txtdefault mb-2 d-inline-block">Pack of: <span
                                class="f-800 fs16 dark-text">{{ detail.pack_of }}</span></p> <br />


                        <!-- Mother Child Product - start -->


                        <div class="card card-ui mb-3 shadow-2 parentChild p-10" *ngFor="let child of detail.child_records">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="productImg"><img src="{{ child.image }}"></div>
                                </div>
                                <div class="col-md-9">
                                    <div class="productInfo_title">{{ child.name }}</div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="pdscp-cntnt d-flex align-items-center">
                                                <p class="fs14 f-600 txtdefault mb-0 d-inline-block">Total Listed Quantity:
                                                    <span class="f-800 fs16 dark-text">{{ child.stock_qty > 0?child.stock_qty:'' }}</span></p> <span class="px-3 text-muted">|</span> 
        
                                                <p class="fs14 f-600 txtdefault mb-0">Price Per Unit:
                                                    <span class="f-800 ml-1 fs20 txtactive">{{ child.price }}</span>
                                                </p>
                                            </div>
                                        </div>
        
                                        <div class="col-md-12">
                                            <div class="mb-0 odqty pt-2">
                                                <div class="input-group align-items-center">
                                                    <input class="form-control border radius10" placeholder="Enter Order Qty." name="child_quantity" [(ngModel)]="child.selected_qty" [disabled]="child.selected" />
                                                    <div class="input-group-addon pl-2">
                                                        <button class="btn-d-outline d-inline-block" *ngIf="!child.selected" (click)="addchild(child)">Add <i class="las la-plus"></i></button>
                                                        <button class="btn-d-outline d-inline-block" *ngIf="child.selected" (click)="child.selected = false; child.selected_qty = ''">Remove <i class="las la-plus"></i></button>
                                                    </div>
                                                </div>
                                                <p class="fs14 pt-1 mt-1 mb-0 txtdefault">Please Enter {{ child.min_qty }} or more</p>
                                            </div>
                                        </div>

                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3 odqty pt-2" *ngIf="!detail.outofstock && detail.child">
                            <div class="input-group align-items-center">
                                <div class="input-group-addon pl-2">
                                    <button class="btn btn-mod btn-color btn-medium btn-round ripple px-4" (click)="addtocart('buynow')">Buy now</button>
                                </div>
                            </div>
                        </div>

                        <!-- Mother Child Product - end -->


                        <!-- Normal Product Price - start -->
                        <ng-container *ngIf="!detail.child">
                            <p class="fs14 f-600 txtdefault mb-3 mr-3  d-inline-block">Price Per Unit:
                                <span class="f-800 ml-1 fs20 txtactive">₹ {{ detail.sale_price }}</span>
                                <span class=" fs16 txtdisable ml-1 crossprice">₹ {{ detail.regular_price }}</span>
                            </p>
                            <p class="pricetag d-inline-block"><i class="p-2 mr-2"></i><span class="f-800 dark-text">{{
                                    detail.offer_percentage }}% Off</span> on MRP</p>

                            <div class="select-size" *ngFor="let variant of detail.product_variant; let i = index;">
                                <div class="f-600" *ngIf="variant.name != 'Color'">{{ variant.name }}:</div>
                                <div class="f-600" *ngIf="variant.name == 'Color'">{{ variant.name }}: {{ variant.sname }}
                                </div>
                                <div class="select-prod-size mb-30" *ngIf="variant.name != 'Color'">
                                    <ul>
                                        <li *ngFor="let meta of variant.meta; let k = index;">
                                            <a href="javascript:;" [ngClass]="{'selected': meta.selected === true}"
                                                (click)="getselectedvariant(meta, variant)">{{ meta.variant_value }}</a>
                                        </li>
                                    </ul>
                                </div>


                                <div class="select-prod-color mb-30" *ngIf="variant.name == 'Color'">
                                    <ul>
                                        <li *ngFor="let meta of variant.meta; let k = index;">
                                            <a href="javascript:;" [ngClass]="{'selected': meta.selected === true}"
                                                (click)="getselectedvariant(meta, variant)"><img src="{{ meta.image }}"
                                                    alt="{{ meta.variant_value }}" title="{{ meta.variant_value }}"></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <!-- <div class="row" style="display: none;">
                                <div class="col-lg-9">
                                    <div class="dealod mt-2">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Order Quantity</th>
                                                    <th scope="col">Price Per Unit</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>50 - 99</td>
                                                    <td>₹ 7,999</td>
                                                </tr>
                                                <tr>
                                                    <td>100 - 199</td>
                                                    <td>₹ 7,399</td>
                                                </tr>
                                                <tr>
                                                    <td>200 - 300</td>
                                                    <td>₹ 6,699</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div> -->
                            <div class="mb-3 odqty pt-2" *ngIf="!detail.outofstock">
                                <div class="input-group align-items-center">
                                    <input class="form-control border radius10" placeholder="Enter the Order Quantity"
                                        [(ngModel)]="buynow_order_quantity" (keyup)="changebuynowquantity()" />
                                    <div class="input-group-addon pl-2">
                                        <button class="btn-d-outline d-inline-block" (click)="addtocart('buynow')">Buy
                                            now</button>
                                    </div>
                                    <div class="input-group-addon pl-2">
                                        <a (click)="open(content)" href="javascript:void(0);"
                                            class="p-2 fs16 add-whislist-link ">
                                            <img src="assets/images/svg/addcart.svg" class="mr-2" />
                                            <span class="fs16">Add to Cart</span></a>
                                    </div>
                                </div>
                                <p class="fs14 pt-1 mt-1 mb-0 txtdefault">Please Enter {{ detail.min_qty }} or more</p>
                            </div>

                            <div class="mb-3 odqty pt-2" *ngIf="detail.outofstock">
                                <div class="input-group align-items-center">
                                    <div class="input-group-addon pl-2">
                                        <button class="btn-d-outline d-inline-block">Out of Stock</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <!-- Normal Product Price - end -->

                    </div>
                </div>
            </div>
            <!-- /PRODUCT TOP SLIDER -->

            <div class="slrinfo pt-4" *ngIf="combo_products_list.length > 0 && !detail.outofstock">
                <div class="row mb-2 align-items-center">
                    <div class="col-6 pr-0">
                        <h4 class="fs16 wt700 mb-0 txtdefault">Frequently bought together</h4>
                    </div>
                </div>
                <div class="card card-ui mb-3 shadow-2" *ngFor="let combo of combo_products_list">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="productInfo">
                                <div class="productImg"><img src="{{ combo.front_image }}"></div>
                                <div class="productInfo_title">{{ combo.name }}</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="pdscp-cntnt">
                                <p class="fs14 f-600 txtdefault mb-1 d-inline-block">Total Listed Quantity:
                                    <span class="f-800 fs16 dark-text">{{ combo.total_qty }}</span></p> <span class="px-3 text-muted">|</span> 

                                <p class="fs14 f-600 txtdefault mb-1 d-inline-block">Pack of:
                                    <span class="f-800 fs16 dark-text">{{ combo.pack_of }}</span></p>

                                <p class="fs14 f-600 txtdefault mb-1">Price Per Unit:
                                    <span class="f-800 ml-1 fs20 txtactive">₹ {{ combo.sale_price }}</span>
                                    <span class=" fs16 txtdisable ml-1 crossprice">₹ {{ combo.regular_price }}</span>
                                </p>
                                <p class="pricetag d-inline-block"><i class="p-2 mr-2"></i><span class="f-800 dark-text">{{ combo.offer_percentage }}% Off</span> on MRP</p>
                            </div>
                        </div>

                        <div class="col-md-4">
                            <div class="mb-3 odqty pt-2">
                                <div class="input-group align-items-center">
                                    <input class="form-control border radius10" placeholder="Enter Order Qty." name="combo_quantity" [(ngModel)]="combo.selected_qty" [disabled]="combo.selected" />
                                    <div class="input-group-addon pl-2">
                                        <button class="btn-d-outline d-inline-block" *ngIf="!combo.selected" (click)="addcombo(combo)">Add <i class="las la-plus"></i></button>
                                        <button class="btn-d-outline d-inline-block" *ngIf="combo.selected" (click)="combo.selected = false; combo.selected_qty = ''">Remove <i class="las la-plus"></i></button>
                                    </div>
                                </div>
                                <!-- <p class="fs14 pt-1 mt-1 mb-0 txtdefault">Please Enter {{ combo.min_qty }} or more</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- SELLER INFORMATION -->
            <div class="slrinfo pt-4">
                <div class="row mb-2 align-items-center">
                    <div class="col-6 pr-0">
                        <h4 class="fs16 wt700 mb-0 txtdefault">Seller Information</h4>
                    </div>
                    <div class="col-6 pl-0 text-right knslr">
                        <a href="javascript:void(0);" class="add-whislist-link" [routerLink]="['/seller-info/']"
                            [queryParams]="{s_id: detail.s_id}"><span class="fs16">Know more about the Seller</span></a>
                    </div>
                </div>
                <div class="card card-ui">
                    <div class="row pb-2">
                        <div class="col-md-5 pr-0">
                            <p class="txtdefault fs14">Seller Name & Ratings:</p>
                            <div class="slrinfowrp">
                                <img class="sellerthumb" src="assets/images/svg/seller.svg" />
                                <h4 class="fs18 wt700 txtdefault lnht24"> {{ detail.seller_name }}</h4>
                                <div class="ratingcal">
                                    <div class="ratings star-ratings pr-1 d-inline-block">
                                        <ngb-rating [max]="5" [(rate)]="detail.seller_rating" [readonly]="true" class="rating_star"></ngb-rating>
                                    </div> <br />
                                    <i class="fs12 txtseconday ">({{ detail.seller_rating_count }} ratings)</i>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <p class="txtdefault fs14 mb-2">User Satisfaction: (No. of people in %)</p>
                            <p class="txtseconday fs14">75%</p>
                        </div>
                        <div class="col-md-3 mbl-spllc">
                            <p class="txtdefault fs14 mb-2">Supply Location:</p>
                            <p class="txtdefault fs14 spl-lc">
                                <svg xmlns="http://www.w3.org/2000/svg" width="9.375" height="11.752"
                                    viewBox="0 0 9.375 11.752">
                                    <path id="Path_2306" data-name="Path 2306"
                                        d="M25.689,5.029a4.825,4.825,0,0,0-4.154,5.16c.285,3.272,3.293,5.711,4.324,6.457a.562.562,0,0,0,.677-.012c1.071-.836,4.357-3.67,4.357-6.942a4.691,4.691,0,0,0-5.2-4.663Zm.514,6.524a1.861,1.861,0,1,1,1.861-1.861A1.861,1.861,0,0,1,26.2,11.553Z"
                                        transform="translate(-21.517 -5.002)" fill="#5e6a7c" />
                                </svg>

                                {{ detail.seller_supply_location }}

                            </p>
                        </div>
                    </div>
                    <!-- <div class="card pl-4 pt-4 keyperform pb-2 slrkeyperform">
                        <h4 class="fs16 f-700 mb-3 mt-0 txtdefault">Key Performance</h4>
                        <ul class="row mb-0">
                            <li class="col-md-3">
                                <div class="keyratewrp">
                                    <div class="keyrate">
                                        <img src="assets/images/png/circle.png" />
                                        <span class="f-700 txtdefault">{{
                                            detail.seller_key_performance?.order_fulfilment_rating }}</span>
                                        <div class="ratings star-ratings pr-1 d-inline-block">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <p class="txtdefault">Order Fulfillment</p>
                                </div>
                            </li>
                            <li class="col-md-3">
                                <div class="keyratewrp">
                                    <div class="keyrate">
                                        <img src="assets/images/png/circle.png" />
                                        <span class="f-700 txtdefault">{{ detail.seller_key_performance?.deliery_rating
                                            }}</span>
                                        <div class="ratings star-ratings pr-1 d-inline-block">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <p class="txtdefault">Delivery</p>
                                </div>
                            </li>
                            <li class="col-md-3">
                                <div class="keyratewrp">
                                    <div class="keyrate">
                                        <img src="assets/images/png/circle.png" />
                                        <span class="f-700 txtdefault">{{
                                            detail.seller_key_performance?.quality_assurance }}</span>
                                        <div class="ratings star-ratings pr-1 d-inline-block">
                                            <i class="fa fa-star" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <p class="txtdefault">Quality Assurance</p>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
            <!-- /SELLER INFORMATION -->
        </div>
        <div class="col-lg-3">
            <!-- CARD REPEAT -->
            <!-- <div class="card card-ui mb-3">
                <div>
                    <h4 class="txtdefault fs16 wt700 mb-0">Shipping Address </h4>
                    <div class="mb-3">
                        <a *ngIf="this.sma_shipping_id" (click)="addaddr(newadrs, 'shipping', 'add')" href="javascript:void(0);"
                        class="link-alt fs13">Add
                    </a>
                    <a (click)="addaddr(newadrs, 'shipping', 'edit')" href="javascript:void(0);"
                        class="link-alt fs13">Edit
                    </a>
                   </div>
                   
                    
                    <p class="txtseconday fs14 lnht24" *ngIf="sma_shipping_name">{{ sma_shipping_name }}</p>
                    <a *ngIf="detail.shipping_address.length > 0" (click)="open(shippingcontent)" href="javascript:void(0);"
                        class="btn-d-outline d-inline-block place-bid px-4">Change
                    </a>
                </div>
            </div> -->
            <!-- /CARD REPEAT -->
            <!-- CARD REPEAT -->
            <!-- <div class="card card-ui mb-3">
                <div>
                    <h4 class="txtdefault fs16 wt700 mb-0">Billing Address</h4>
                    <div class="mb-3"><a (click)="addaddr(newadrs, 'billing', '')" href="javascript:void(0);"
                        class="link-alt fs13">Request to Change Address
                    </a></div>
                    <p class="txtseconday fs14 lnht24" *ngIf="selected_billing_address_text">{{ selected_billing_address_text }}</p>
                </div>
            </div> -->
            <!-- /CARD REPEAT -->
            <!-- CARD REPEAT -->
            <div class="card card-ui mb-3">
                <div>
                    <h4 class="txtdefault fs16 wt700 mb-3">Logistic Charges</h4>

                    <div class="freeDelivery">
                        Free Delivery
                    </div>
                    <div class="ord-adrs-artcl">
                        <p class="txtdefault fs14 lnht24 mb-0 cssinptchk">
                            <span class="css-check">
                                <input class="css-checkbx" name="expdlv" type="radio"
                                    [checked]="selected_delivery == 'standard'" (click)="changedelivery('standard')" />
                                <label class="css-label">&nbsp;</label>
                            </span>
                            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="21.334" height="15.778"
                                viewBox="0 0 21.334 15.778">
                                <path id="np_delivery_4410921_000000"
                                    d="M18.4,30.9a2.778,2.778,0,0,1-2.688-2.083H11.368a2.778,2.778,0,0,1-5.375,0H3.819a.694.694,0,0,1-.694-.694V16.319a.694.694,0,0,1,.694-.694H17.014a.694.694,0,0,1,.694.694V19.1h2.778a.7.7,0,0,1,.542.264l2.778,3.472a.7.7,0,0,1,.153.431v4.861a.694.694,0,0,1-.694.694H21.091A2.778,2.778,0,0,1,18.4,30.9Zm-1.389-2.778a1.389,1.389,0,1,0,.465-1.035,1.389,1.389,0,0,0-.465,1.035ZM8.681,26.737a1.39,1.39,0,1,0,.982.407,1.388,1.388,0,0,0-.982-.407Zm12.41.694H22.57V23.507l-2.417-3.021H17.709v4.952a2.718,2.718,0,0,1,.694-.09,2.778,2.778,0,0,1,2.688,2.083Zm-9.723,0h4.347a2.692,2.692,0,0,1,.6-1.146V17.014H4.514V27.431H5.993a2.778,2.778,0,0,1,5.375,0Z"
                                    transform="translate(-2.875 -15.375)" fill="#1f1d44" stroke="#fcfcfc"
                                    stroke-width="0.5" />
                            </svg> Standard Delivery
                        </p>
                        <!-- <p class="txtdefault fs14 lnht24 mb-0">Shipping charges: {{ detail.standard_delivery_charge }}</p> -->
                        <p class="txtseconday fs12 dlnht20 mb-2">
                            <b>Expected Delivery:</b> 5-7 days from the date of Purchase</p>
                        <!-- <a class="fs14 add-whislist-link" href="javascript:void(0);"
                            (click)="open(logisticsCharges)"><span>View Details</span></a> -->
                    </div>
                </div>
                <div class="ord-adrs-artcl" *ngIf="market_place == 'bulk'">
                    <p class="txtdefault fs14 cssinptchk lnht24 mb-0">
                        <span class="css-check">
                            <input class="css-checkbx" name="expdlv" type="radio"
                                [checked]="selected_delivery == 'express'" (click)="changedelivery('express')" />
                            <label class="css-label">&nbsp;</label>
                        </span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20.398" height="13.82"
                            viewBox="0 0 20.398 13.82">
                            <path id="np_delivery_2964601_000000"
                                d="M24.071,23.787a2.357,2.357,0,0,0-2.121-1.311H18.581V19.941a.429.429,0,0,0-.429-.429H7.691a.426.426,0,0,0-.3.125.43.43,0,0,0,.3.734H17.723V30.655H13.306a2.246,2.246,0,0,0-4.41,0H7.464a.43.43,0,1,0,0,.86H8.9a2.246,2.246,0,0,0,4.41,0h5.821a2.246,2.246,0,0,0,4.41,0h1.432a.431.431,0,0,0,.429-.43V26.541a.435.435,0,0,0-.045-.192ZM11.1,32.472a1.388,1.388,0,1,1,1.388-1.388A1.388,1.388,0,0,1,11.1,32.472ZM21.95,23.335a1.5,1.5,0,0,1,1.353.837l1.235,2.471v.3H18.581v-3.6Zm-.618,9.137a1.388,1.388,0,1,1,1.387-1.388,1.388,1.388,0,0,1-1.387,1.388Zm2.205-1.818h0a2.246,2.246,0,0,0-4.41,0h-.545V27.8h5.956v2.858Zm-16.9-7.866h6.149a.43.43,0,1,1,0,.86H6.639a.43.43,0,1,1,0-.86ZM5,26.257a.429.429,0,0,1,.429-.43h5.323a.43.43,0,1,1,0,.86H5.429A.429.429,0,0,1,5,26.257Z"
                                transform="translate(-5 -19.511)" fill="#1f1d44" />
                        </svg> Express Delivery
                    </p>
                    <!-- <p class="txtdefault fs14 lnht24 mb-0">Shipping charges: {{ detail.express_deliery_charge }}</p> -->
                    <p class="txtseconday fs12 dlnht20 mb-2">
                        <b>Expected Delivery:</b> 5-7 days from the date of Purchase</p>
                    <a class="fs14 add-whislist-link" href="javascript:void(0);"
                        (click)="open(logisticsChargesexpress)"><span>View Details</span></a>
                </div>
            </div>
            <!-- /CARD REPEAT -->
            <!-- CARD REPEAT -->
            <div class="card card-ui mb-3">
                <div>
                    <h4 class="txtdefault fs16 wt700 mb-3">Return Policy</h4>
                    <p class="txtdefault fs14 cssinptchk lnht24 mb-0">
                        <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="15.64" height="15.64"
                            viewBox="0 0 15.64 15.64">
                            <path id="np_product-return_4376313_000000"
                                d="M33.64,25.82a7.82,7.82,0,0,1-15.64,0,.489.489,0,1,1,.977,0,6.842,6.842,0,1,0,6.842-6.842,6.768,6.768,0,0,0-4.777,1.955h1.844a.489.489,0,0,1,0,.977H19.955a.488.488,0,0,1-.489-.489V18.489a.489.489,0,1,1,.977,0v1.667A7.74,7.74,0,0,1,25.82,18a7.829,7.829,0,0,1,7.82,7.82Zm-3.421-1.955v4.4a.489.489,0,0,1-.317.457l-3.91,1.466a.484.484,0,0,1-.344,0l-3.91-1.466a.489.489,0,0,1-.317-.457v-4.4a.489.489,0,0,1,.317-.457l3.91-1.466a.5.5,0,0,1,.344,0l3.91,1.466a.489.489,0,0,1,.317.457Zm-6.917,0,2.518.944,2.518-.944-2.518-.944Zm-.9,4.06,2.932,1.1V25.67L22.4,24.57Zm6.842,0V24.57l-2.932,1.1v3.354Z"
                                transform="translate(-18 -18)" fill="#1f1d44" />
                        </svg> Return Policy.
                        <!-- <p class="txtseconday fs12 dlnht20 mb-1">{{  detail.return_summary.substring(0, 170) }}</p> -->
                        <a class="fs14 add-whislist-link" href="javascript:void(0);" (click)="open(returnpolicycontent)"><span>View Details</span></a>
                    </p>
                </div>
            </div>
            <div class="card card-ui mb-3">
                <div>
                    <h4 class="txtdefault fs16 wt700 mb-3">Warranty</h4>
                    <p class="txtdefault fs14 cssinptchk lnht24 mb-0">
                        <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="15.64" height="15.64"
                            viewBox="0 0 15.64 15.64">
                            <path id="np_product-return_4376313_000000"
                                d="M33.64,25.82a7.82,7.82,0,0,1-15.64,0,.489.489,0,1,1,.977,0,6.842,6.842,0,1,0,6.842-6.842,6.768,6.768,0,0,0-4.777,1.955h1.844a.489.489,0,0,1,0,.977H19.955a.488.488,0,0,1-.489-.489V18.489a.489.489,0,1,1,.977,0v1.667A7.74,7.74,0,0,1,25.82,18a7.829,7.829,0,0,1,7.82,7.82Zm-3.421-1.955v4.4a.489.489,0,0,1-.317.457l-3.91,1.466a.484.484,0,0,1-.344,0l-3.91-1.466a.489.489,0,0,1-.317-.457v-4.4a.489.489,0,0,1,.317-.457l3.91-1.466a.5.5,0,0,1,.344,0l3.91,1.466a.489.489,0,0,1,.317.457Zm-6.917,0,2.518.944,2.518-.944-2.518-.944Zm-.9,4.06,2.932,1.1V25.67L22.4,24.57Zm6.842,0V24.57l-2.932,1.1v3.354Z"
                                transform="translate(-18 -18)" fill="#1f1d44" />
                        </svg> Warranty
                        <a class="fs14 add-whislist-link" href="javascript:void(0);" (click)="open(warrantycontent)"><span>View Details</span></a>
                    </p>
                    <!-- <div *ngFor="let war of detail.warranty;">
                        <p class="txtdefault fs14 cssinptchk lnht24 mb-0">
                            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="14.263" height="16.64"
                                viewBox="0 0 14.263 16.64">
                                <g id="Group_14178" data-name="Group 14178" transform="translate(-490 -373)">
                                    <path id="Path_8724" data-name="Path 8724"
                                        d="M73.246,55.538l-6.537-2.377a.6.6,0,0,0-.407,0l-6.537,2.377a.6.6,0,0,0-.391.559v4.347a8.874,8.874,0,0,0,4.127,7.52l2.685,1.709a.6.6,0,0,0,.639,0l2.685-1.709a8.876,8.876,0,0,0,4.127-7.52V56.1a.6.6,0,0,0-.391-.559Zm-.8,4.905a7.692,7.692,0,0,1-3.577,6.518l-2.365,1.5-2.365-1.5a7.692,7.692,0,0,1-3.577-6.518V56.512l5.943-2.16,5.943,2.16Z"
                                        transform="translate(430.626 319.875)" fill="#1f1d44" />
                                    <path id="Path_8725" data-name="Path 8725"
                                        d="M70.048,67.865l-.841.841,2.377,2.377a.6.6,0,0,0,.841,0l4.16-4.16-.841-.841L72,69.821Z"
                                        transform="translate(424.533 311.846)" fill="#1f1d44" />
                                </g>
                            </svg>
                            {{ war.key }}
                        </p>
                        <p class="txtseconday fs12 dlnht20 mb-0">{{ war.value }}</p>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <ng-template #newadrs let-modal>
        <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title text-center cg-ttl pb-2">{{!this.sma_shipping_id ? "Add New Shipping/Billing Address" : "Update New Shipping/Billing Address"}}</h5>
                <form [formGroup]="addShippingForm">
                    <div class="form-container mdl-frm-lbl mb-3">
                        <div class="row">
                            <div class="col-sm-12 mb-4">
                                <div class="floating-label mb-0">      
                                <textarea class="form-control" placeholder="" formControlName="address"></textarea>
                                <span class="highlight"></span>
                                <label>Address</label>
                                </div> 
                            </div>
                        </div>
    
                        
                        <div class="row">
    
                            <div class="col-sm-6 mb-4">
                                <div class="floating-label mb-0">
                                    <select class="form-control" formControlName="state" (change)="getcity()">
                                        <option selected="selected" value="">Please Select</option>
                                        <option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
                                    </select>
                                    <span class="highlight"></span>
                                    <label>State</label>
                                </div>
                            </div>
                            <div class="col-sm-6 mb-4">
                                <div class="floating-label mb-0">
                                    <select class="form-control" formControlName="city">
                                        <option selected="selected" value="">Please Select</option>
                                        <option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
                                    </select>
                                    <span class="highlight"></span>
                                    <label>City</label>
                                </div>
                            </div>
    
                            <div class="col-sm-6 mb-4">
                                <div class="floating-label mb-0">      
                                <input type="text" class="form-control" placeholder="" formControlName="pincode" />
                                <span class="highlight"></span>
                                <label>Pincode</label>
                                </div> 
                            </div>
                            <div class="col-sm-6 mb-4">
                                <div class="floating-label mb-0">      
                                <input type="text" class="form-control" placeholder="" formControlName="address_name" />
                                <span class="highlight"></span>
                                <label>Name this address as (optional)</label>
                                </div> 
                            </div>
                        </div>
                    </div>   
    
                    <div class="cta  pb-4 text-center">
                        <button  class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250" (click)="saveshippingform()" [disabled]="addShippingForm.invalid">{{!this.sma_shipping_id ? "Add Address" : "Update Address"}}</button>
                    </div>
                </form>
    
          </div>
    </ng-template>
    <!-- /PRODUCT DESCRIPTION -->

    <div class="mb-4" *ngIf="chunked.length > 0">
        <h4 class="f-700 fs16 txtdefault mb-2 pb-2">Technical Specifications &nbsp;<span
                class="fontdefault f-500 fs14 txtfade">{{ detail.name }}</span></h4>

        <div class="tech-spec">
            <div class="row no-gutters" *ngFor="let chunk of chunked; let i = index;">
                <div class="col-md-6" *ngFor="let rec of chunk; let k = index;">
                    <div class="tech-item d-flex">
                        <div class="title">{{ rec.label }}</div>
                        <div class="desc">{{ rec.value }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- INSTRUCTION -->
    <div class="mb-4 pt-2">
        <div class="row mb-3 align-items-center p-rvw">
            <div class="col-sm-6">
                <h4 class="f-700 fs16 txtdefault mb-2 pb-1">Product Reviews & Ratings</h4>
            </div>
            <div class="col-sm-6 text-right">
                <div class="cstm-slct-wrp hideintab d-inline-block w-100 maxsrt">
                    <select class="form-control  cs-form-control text-capitalize" [(ngModel)]="sort_by_rating" (change)="reviewsorting()">
                        <option value="date_asc">Sort By Date (ASC)</option>
                        <option value="date_desc">Sort By Date (DESC)</option>
                        <option value="rating_asc">Sort By Rating (ASC)</option>
                        <option value="rating_desc">Sort By Rating (DESC)</option>
                    </select>
                </div>

            </div>
        </div>
        <div class="showintablet ovrl-tbl-rt">
            <div class="row align-items-center w-full-vsm">
                <div class="col-6 pr-0">
                    <div class="ovlrating hideinmd">
                        <h4 class="fs14 f-600 mb-2">Overall Rating</h4>
                        <div class="ratingcal">
                            <div class="ratings star-ratings pr-1 d-inline-block">
                                <ngb-rating [max]="5" [(rate)]="star_count" [readonly]="true" class="rating_star"></ngb-rating>
                            </div>
                            <span class="fs16 f-700 txtdefault">{{ star_count }}</span> out of <span
                                class="fs16 f-700 txtdefault">5</span><br />
                            <i class="fs12 txtseconday pt-2 ">({{ rating_total_records }} ratings)</i>
                            <div class="fltsortnv">
                                <a href="javascript:void(0);" class="sorticon mr-2">
                                    <img src="assets/images/svg/sort.svg">
                                </a>
                                <a href="javascript:void(0);" (click)="ratingtglclass()" class="filtericon">
                                    <img src="assets/images/svg/filter.svg">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 text-right">
                    <div class="py-2">
                        <a href="javascript:;" routerLink="['/buyer/write-review']" class="btn-d-outline wr-btn d-inline-block">Write a Review</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 filtercol lsting rating-filter filtermbl"
                [ngClass]="ratingclassstatus ? 'showfilter' : ''">
                <div class="fltrshead">
                    <h4>Filter</h4>
                    <a (click)="ratingtglclass()" class="closefilter" href="javascript:void(0);"><svg
                            _ngcontent-serverApp-c132="" xmlns="http://www.w3.org/2000/svg" width="6.508" height="6.508"
                            viewBox="0 0 6.508 6.508">
                            <path _ngcontent-serverApp-c132="" id="np_cross_1707162_000000"
                                d="M30.883,30.883a.507.507,0,0,1,.717,0l2.389,2.389,2.389-2.389a.507.507,0,0,1,.717.717L34.7,33.988l2.389,2.389a.507.507,0,0,1-.717.717L33.988,34.7,31.6,37.094a.507.507,0,0,1-.717-.717l2.389-2.389L30.883,31.6A.507.507,0,0,1,30.883,30.883Z"
                                transform="translate(-30.734 -30.734)" fill="#1f1d44"></path>
                        </svg></a>
                </div>
                <div class="ovlrating">
                    <h4 class="fs14 f-600 mb-2">Overall Rating</h4>
                    <div class="ratingcal">
                        <div class="ratings star-ratings pr-1 d-inline-block">
                            <ngb-rating [max]="5" [(rate)]="star_count" [readonly]="true" class="rating_star"></ngb-rating>
                        </div>
                        <span class="fs16 f-700 txtdefault">{{ star_count }}</span> out of <span
                            class="fs16 f-700 txtdefault">5</span><br />
                        <i class="fs12 txtseconday pt-2 pb-3">({{ rating_total_records }} ratings)</i><br />
                        <div class="py-2 pt-3">
                            <a href="javascript:;" [routerLink]="['/buyer/write-review']" class="btn-d-outline  d-inline-block ">Write a Review</a>
                        </div>
                    </div>

                </div>
                <ngb-accordion #acc="ngbAccordion"
                    activeIds="ratingfilter, toggle-2, toggle-3, brand, startingprice, ram, internalstorage, screensize, activationstatus, supplylocation, countryorigin"
                    class="accordion">
                    <ngb-panel id="ratingfilter" opened class="bg-transparent" title="Filter By Ratings">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(5)"/>
                                        <label class="css-label">
                                            <span class="fs16 txtseconday mr-2">5</span>
                                            <span class="ratings star-ratings pr-1 d-inline-block">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(4)"/>
                                        <label class="css-label">
                                            <span class="fs16 txtseconday mr-2">4 and above</span>
                                            <span class="ratings star-ratings pr-1 d-inline-block">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(3)"/>
                                        <label class="css-label">
                                            <span class="fs16 txtseconday mr-2">3 and above</span>
                                            <span class="ratings star-ratings pr-1 d-inline-block">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(2)"/>
                                        <label class="css-label">
                                            <span class="fs16 txtseconday mr-2">2 and above</span>
                                            <span class="ratings star-ratings pr-1 d-inline-block">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" (change)="updatereviewfilters(1)"/>
                                        <label class="css-label">
                                            <span class="fs16 txtseconday mr-2">1 and above</span>
                                            <span class="ratings star-ratings pr-1 d-inline-block">
                                                <i class="fa fa-star" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                                <i class="fa fa-star-o" aria-hidden="true"></i>
                                            </span>
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </ng-template>
                    </ngb-panel>
                    <!-- <ngb-panel id="toggle-2" title="Filter By Ratings">
                        <ng-template ngbPanelContent>
                            <ul class="list-group">
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">All</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">Only Images</label>
                                    </div>
                                </li>
                                <li class="list-group-item">
                                    <div class="css-check">
                                        <input class="css-checkbx" type="checkbox" />
                                        <label class="css-label">Only Videos</label>
                                    </div>
                                </li>


                            </ul>
                        </ng-template>
                    </ngb-panel> -->

                </ngb-accordion>
            </div>
            <div class="col-lg-9 listing-content rating-content pl-lg-4">
                <div class="card cardfadembl p-3 px-0 pt-0 radius15">
                    <div class="card-body pt-0 pb-0">
                        <div class="pd-rt-rw">
                            <table class="table p-slr-tbl mb-0">
                                <thead class="txtdefault fs14 theader">
                                    <tr>
                                        <th width="14%" class="text-center" scope="col">Product Image</th>
                                        <th width="16%" scope="col">Date of Review</th>
                                        <th width="40%" scope="col">User’s Review</th>
                                        <th scope="col">User’s Rating</th>
                                        <!-- <th scope="col" class="text-center">Action</th> -->

                                    </tr>
                                </thead>
                                <tbody>

                                    <!--Empty State-->
                                    <tr *ngIf="!review_records.length">
                                        <td colspan="4">
                                            <div class="row pt-40 pb-40">
                                                <div class="col-md-12 text-center">
                                                    <div class="mb-20"><img src="/assets/images/empty-state.png">
                                                    </div>
                                                    <div class="fs16">There are no records to display!</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <!--End Empty State-->

                                    <!-- REPEAT -->
                                    <tr *ngFor="let rec of review_records;">
                                        <td class="text-center od3">
                                            <img width="50" *ngIf="rec.product_image" src="{{ rec.product_image }}">
                                        </td>
                                        <td class="od4">
                                            <p class="txtseconday mb-0">{{ rec.review_date }}</p>
                                        </td>
                                        <td class="od2">
                                            <p class="txtseconday mb-0 lnht24">{{ rec.review }}</p>
                                        </td>
                                        <td class="od1">
                                            <div class="ratings star-ratings">
                                                <ngb-rating [max]="5" [(rate)]="rec.rating" [readonly]="true" class="rating_star"></ngb-rating>
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- /REPEAT -->


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row mb-40" *ngIf="review_pagination.totalPages> 1">
                    <div class="col-md-6">
                        <div class="text-left pl-3">
                            <nav class="mt-3 tbpg rows-per-page">
                                <div>Rows per page:</div>
                                <select class="select-box" [(ngModel)]="page_per_view"
                                    (change)="setpageperview()">
                                    <option *ngFor="let option of page_per_view_options"
                                        [ngValue]="option.value">
                                        {{option.name}}</option>
                                </select>
                            </nav>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-right pr-3">
                            <nav class="mt-3 tbpg">
                                <ul class="pagination align-items-center">
                                    <li class="page-item"
                                        [ngClass]="{disabled:review_pagination.currentPage === 1}"><a
                                            class="page-link previcon" href="javascript:;"
                                            (click)="setpage(1)"></a>
                                    </li>
        
                                    <li class="page-item"
                                        [ngClass]="{disabled:review_pagination.currentPage === 1}"><a
                                            class="page-link sprevicon" href="javascript:;"
                                            (click)="setpage(review_pagination.currentPage - 1)"></a></li>
        
                                    <li class="page-item" i *ngFor="let page of review_pagination.pages"
                                        [ngClass]="{active:review_pagination.currentPage === page}"><a
                                            class="page-link" href="javascript:;"
                                            (click)="setpage(page)">{{page}}</a></li>
        
                                    <li
                                        *ngIf="(review_pagination.totalPages - review_pagination.startPage) > 5 ">
                                        <a (click)="setpage(review_pagination.startPage + 5)">...</a>
                                    </li>
                                    <li
                                        *ngIf="(review_pagination.totalPages - review_pagination.startPage) > 5">
                                        <a
                                            (click)="setpage(review_pagination.totalPages)">{{review_pagination.totalPages}}</a>
                                    </li>
        
                                    <li class="page-item"
                                        [ngClass]="{disabled:review_pagination.currentPage === review_pagination.totalPages}">
                                        <a class="page-link snexticon" href="javascript:;"
                                            (click)="setpage(review_pagination.currentPage + 1)"></a>
                                    </li>
                                    <li class="page-item"
                                        [ngClass]="{disabled:review_pagination.currentPage === review_pagination.totalPages}">
                                        <a class="page-link nexticon" href="javascript:;"
                                            (click)="setpage(review_pagination.totalPages)"></a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- INSTRUCTION -->
    <div class="mb-5 faqpanel accordionarrow">
        <h4 class="f-700 fs22 txtdefault pb-20">Frequently Asked Questions</h4>
        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
            <ngb-panel title="What is the minimum order quantity?">
                <ng-template ngbPanelContent>
                    <p class="txtdefault fs16 lnht24 mb-0">It is the minimum quantity of products below which the seller is not willing to accept the order.</p>
                </ng-template>
            </ngb-panel>
            <ngb-panel title="How do I get the purchased products? Who will provide the logistic services?">
                <ng-template ngbPanelContent>
                    As a buyer, you need not worry about arranging a courier or any 3rd party logistics as Equibiz provides integrated logistics services to arrange the shipment of the consignment from the supplier’s place. 
                </ng-template>
            </ngb-panel>
            <ngb-panel title="What are the charges buyers are required to pay?">
                <ng-template ngbPanelContent>
                    The buyer may require paying charges for logistics, convenience, and insurance(optional). The charges are displayed to the buyer at the time of deal booking.
                </ng-template>
            </ngb-panel>
            <ngb-panel title="As a buyer, can I add more than one delivery location?">
                <ng-template ngbPanelContent>
                    Yes, the buyer has the option to select the preferred delivery location at the time of deal booking. The buyer can set more than one delivery location from the buyer panel.
                </ng-template>
            </ngb-panel>
            <ngb-panel title="What are the Logistics options available at the time of Deal Booking ?">
                <ng-template ngbPanelContent>
                    Standard and Express delivery logistics options are available at the time of deal booking and buyers can choose any one logistics option as per their business requirement. The expected delivery time in the case of express delivery is three to five working days and in the case of standard, it is five to seven working days.
                </ng-template>
            </ngb-panel>
            <ngb-panel title="How do I make the payment for my purchase?">
                <ng-template ngbPanelContent>
                    At the time of check out, there are various payment options like Equibiz internal wallet, Net Banking, Debit Card, Credit Card, UPI, etc. available to select as a payment method. Except for the Equibiz wallet, other payment options may have certain Payment Gateway charges which will be applicable at the time of making a payment.
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <!-- /INSTRUCTION -->
    <!-- MORE PRODUCTS -->
    <h4 class="f-700 fs16 txtdefault mb-3 pb-1" *ngIf="productListing.length > 0">More Products by {{ detail.seller_name
        }}</h4>
    <div class="row p-list-item row-cols-xl-5 row-cols-lg-4 row-cols-md-3 two-col-vsm  pd-list-item"
        *ngIf="productListing.length > 0">
        <!-- REPEAT -->
        <div class="col mb-3" *ngFor="let product of productListing; let i = index;">
            <div class="product-item  pt-2" [routerLink]="['/product/details/'+product.unique_id]">
                <div class="ex-deal" style="display:block" *ngIf="product.total_bid > 0"><span>{{
                    product.total_bid }} {{ product.total_bid > 1 ?'Bids': 'Bid' }}</span></div>
                <div class='image pt-0'>
                    <div class="d-table">
                        <div class="d-table-cell align-middle">
                            <img [src]='product.p_front_image'>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="product-name fs14 ">
                            {{ product.name }}
                        </div>
                        <div class="ratingitem py-1">
                            <div class="ratings star-ratings pr-1 d-inline-block">
                                <ngb-rating [max]="5" [(rate)]="product.star_rating" [readonly]="true" class="rating_star"></ngb-rating>
                            </div>

                            <i class="fs10 txtseconday pt-2 ">({{product.total_rating}})</i>

                        </div>
                    </div>
                </div>
                <div class="row no-gutters d-flex justify-content-between">
                    <div class="col-5">
                        <div class="seller-count txtdefault">
                            Total Stock <br /> <span class="fs14 f-700">{{ product.total_stock }}</span>
                        </div>
                    </div>
                    <div class="col-7">
                        <div class="price">
                            Starting at <br /><span><i class="fa fa-inr"></i> {{ product.sales_price }} <b
                                    class="strikethrough">{{ product.regular_price }}</b></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /REPEAT -->

    </div>
</div>

<!-- ADD TO CART MODAL -->
<ng-template #content let-modal class="dealaddtoCart">
    <div class="modal-header text-center nobd pt-4 mt-1 pb-1">
        <h4 class="modal-title mx-auto wt700 fs24 txtdefault">Add to Cart</h4>
        <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body pt-sm-3 pt-1">
        <div class="row mb-sm-4 mb-3">
            <div class="col-sm-7">
                <div class="dealod mt-2">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Order Quantity</th>
                                <th scope="col">Price Per Unit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>₹ {{ detail.sale_price }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-sm-5">
                <div class="mt-md-3 mt-2">
                    <p class="wt700 fs14 txtdefault mb-sm-3 mb-0">Total Listed Quantity</p>
                    <span class="fs20 wt700 txtdefault">{{ detail.total_qty }}</span>
                </div>
            </div>
        </div>


        <div class="row pb-sm-4 ">
            <div class="col-sm-12">
                <div class="odqty">
                    <div class="input-group plcbidinpt mb-3">
                        <input type="text" class="form-control radius10 txtseconday"
                            placeholder="Enter the Order Quantity" [(ngModel)]="total_order_quantity"
                            (change)="changeaddtocartquantity()">
                        <div class="input-group-addon pl-2">
                            <button class="buybtn ht50 d-inline-block px-4 radius10" type="button"
                                (click)="addtocart('cart')">Add to Cart</button>
                        </div>
                        <div class="input-group-addon pl-4 mr-0">
                            <div>
                                <div class="wt700 fs14 txtdefault fs-12-vsm mb-sm-2 mb-0">Total Order Value</div>
                                <span class="fs20 wt700 txtactive">₹ {{ total_order_value }}</span>
                            </div>
                        </div>
                    </div>
                    <p class="fs12 txtdefault mb-0">Please Enter {{ detail.min_qty }} or more</p>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /ADD TO CART MODAL -->

<!-- SHIPPING ADDRESS MODAL -->
<ng-template #shippingcontent let-modal>
    <div class="modal-header text-center nobd pt-4 mt-1 pb-1">
        <h4 class="modal-title mx-auto  wt700 fs20 txtdefault">Shipping Address</h4>
        <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="sa-rw mb-4">
            <p class="fs14 txtdefault mb-3 lnht20">Primary Supply Location:</p>
            <ul class="mb-0">
                <li class="cssinptchk">
                    <span class="css-check">
                        <input class="css-checkbx" name="expdlv" type="radio"
                            [checked]="detail.default_shipping_address.selected"
                            (change)="changeaddress('default', detail.default_shipping_address.id)" />
                        <label class="css-label">&nbsp;</label>
                    </span>
                    <p class="fs14 txtdefault f-600 mb-0 lnht20">{{ detail.default_shipping_address.name }}</p>
                    <p class="fs14 txtseconday mb-0 lnht20">{{ detail.default_shipping_address.address }}</p>
                </li>
            </ul>
        </div>
        <div class="sa-rw" *ngIf="detail.shipping_address.length > 0">
            <p class="fs14 txtdefault mb-3">Other supply Locations:</p>
            <ul class="mb-0">
                <li class="mb-3 cssinptchk" *ngFor="let address of detail.shipping_address; let k = index;">
                    <span class="css-check">
                        <input class="css-checkbx" name="expdlv" type="radio" [checked]="address.selected"
                            (change)="changeaddress('other', k)" />
                        <label class="css-label">&nbsp;</label>
                    </span>
                    <p class="fs14 txtdefault f-600 lnht20 mb-0">{{ address.name }}</p>
                    <p class="fs14 txtseconday lnht20 mb-0">{{ address.address }}</p>
                </li>
            </ul>
        </div>
        <div class="text-center pb-4 pt-3">
            <button class="buybtn d-inline-block px-4 nobd radius10" type="button"
                (click)="modal.dismiss('Cross click')">Select Address</button>
        </div>
    </div>

</ng-template>
<!-- /SHIPPING ADDRESS MODAL -->

<!-- LOGISTIC CHARGES MODAL -->
<ng-template #logisticsCharges let-modal>
    <div class="modal-header text-center nobd pt-4 mt-1 pb-1 d-block">
        <h4 class="modal-title mx-auto f-700 fs20 txtdefault">Standard Delivery (Inclusive of GST%)</h4>
        <div class="fs12 f-600 mb-2">Under this delivery mode you are expected to receive stock in 6 working days.</div>
        <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="modal-scrollbar">
            <div class="notifications-container theme-tab">
                <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
                    <ngb-tab>
                        <ng-template ngbTabTitle>Rate for within City</ng-template>
                        <ng-template ngbTabContent>
                            <div class="tab-content">
                                <table class="table table-bordered xs">
                                    <thead>
                                        <tr>
                                            <th>Weight Bracket</th>
                                            <th>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of standard_city_records;">
                                            <td>{{ record.weight }}</td>
                                            <td>{{ record.total }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab>
                        <ng-template ngbTabTitle>Rate for Rest of India</ng-template>
                        <ng-template ngbTabContent>
                            <div class="tab-content">
                                <table class="table table-custom xs">
                                    <thead>
                                        <tr>
                                            <th>Weight Bracket</th>
                                            <th>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of standard_outsidecity_records;">
                                            <td>{{ record.weight }}</td>
                                            <td>{{ record.total }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </div>

</ng-template>
<!-- /SHIPPING ADDRESS MODAL -->

<!-- LOGISTIC CHARGES MODAL -->
<ng-template #logisticsChargesexpress let-modal>
    <div class="modal-header text-center nobd pt-4 mt-1 pb-1 d-block">
        <h4 class="modal-title mx-auto f-700 fs20 txtdefault">Express Delivery (Inclusive of GST%)</h4>
        <div class="fs12 f-600 mb-2">Recommended for the long distance movement of bulk consignment of products like mobile phones, tablets,etc. 
         Under this mode, logistic partner will preferably move the consignment by air and you are expected to receive stock in 3 working days.</div>
        <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="modal-scrollbar">
            <div class="notifications-container theme-tab">
                <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
                    <ngb-tab>
                        <ng-template ngbTabContent>
                            <div class="tab-content">
                                <table class="table table-bordered xs">
                                    <thead>
                                        <tr>
                                            <th>Weight Bracket</th>
                                            <th>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of express_records;">
                                            <td>{{ record.weight }}</td>
                                            <td>{{ record.total }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </div>

</ng-template>
<!-- /LOGISTIC CHARGES MODAL -->

<!-- RETURN POLICY MODAL -->
<ng-template #returnpolicycontent let-modal>
    <div class="modal-header text-center nobd pt-4 mt-1 pb-1">
        <h4 class="modal-title mx-auto f-700 fs20 txtdefault">Return Policy</h4>
        <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="modal-scrollbar">
            <angular-editor [(ngModel)]="detail.return_summary" [config]="editor_config"></angular-editor>
            <!-- {{ detail.return_summary }} -->
        </div>
    </div>

</ng-template>

<ng-template #warrantycontent let-modal>
    <div class="modal-header text-center nobd pt-4 mt-1 pb-1">
        <h4 class="modal-title mx-auto f-700 fs20 txtdefault">Warranty</h4>
        <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="modal-scrollbar">
            <div *ngFor="let war of detail.warranty;">
                <p class="txtdefault fs14 cssinptchk lnht24 mb-0">
                    <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="14.263" height="16.64"
                        viewBox="0 0 14.263 16.64">
                        <g id="Group_14178" data-name="Group 14178" transform="translate(-490 -373)">
                            <path id="Path_8724" data-name="Path 8724"
                                d="M73.246,55.538l-6.537-2.377a.6.6,0,0,0-.407,0l-6.537,2.377a.6.6,0,0,0-.391.559v4.347a8.874,8.874,0,0,0,4.127,7.52l2.685,1.709a.6.6,0,0,0,.639,0l2.685-1.709a8.876,8.876,0,0,0,4.127-7.52V56.1a.6.6,0,0,0-.391-.559Zm-.8,4.905a7.692,7.692,0,0,1-3.577,6.518l-2.365,1.5-2.365-1.5a7.692,7.692,0,0,1-3.577-6.518V56.512l5.943-2.16,5.943,2.16Z"
                                transform="translate(430.626 319.875)" fill="#1f1d44" />
                            <path id="Path_8725" data-name="Path 8725"
                                d="M70.048,67.865l-.841.841,2.377,2.377a.6.6,0,0,0,.841,0l4.16-4.16-.841-.841L72,69.821Z"
                                transform="translate(424.533 311.846)" fill="#1f1d44" />
                        </g>
                    </svg>
                    {{ war.key }}
                </p>
                <angular-editor [(ngModel)]="war.value" [config]="editor_config"></angular-editor>
                <!-- <p class="txtseconday fs12 dlnht20 mb-0">{{ war.value }}</p> -->
            </div>        
        </div>
    </div>

</ng-template>
<!-- /PRIVACY POLICY MODAL -->
<app-footer-one></app-footer-one>