<app-header-one [sticky]="true"></app-header-one>
<script src="https://unpkg.com/card@2.3.0/dist/card.js"></script>
<section class="page-section pt-20 pb-xs-40">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-md-12">
                <h4 class="fs24 wt700 txtdefault">
                    <a href="javascript:void();" class="go-back"><i class="fa fa-angle-left"></i></a>
                    Checkout
                </h4>
            </div>
            <div class="col-sm-6 col-7 pr-0">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);" [routerLink]="['/home']">Home</a>
                        </li>
                        <li class="breadcrumb-item active">Checkout</li>
                    </ol>
                </nav>
            </div>
        </div>
        <!--- /HEAD  --->
        <div class="row" *ngIf="minimum_amount_failed">
            <div class="col-md-9">
                <div class="alert alert-danger">Minimum deal amount should be Rs.50 to proceed.</div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-9">
                <!-- <p class="text-muted fs14">Insurance</p> -->
                <div class="card card-ui mb-3">
                    <div class="row d-flex align-items-center">
                        <div class="col-md-12">
                            <div class="cssinptchk css-check">
                                <label class="css-label" for="gst_flag">
                                    <div class="d-flex align-items-center">
                                        <input type="checkbox" name="gst_flag" id="gst_flag" class="css-checkbx"
                                    (click)="click_gst_flag()" />
                                        <span class="fs16 f-700 txtdefault mb-10">Do you want GST Invoice?</span>
                                    </div>
                                </label>
                            </div>

                            <form [formGroup]="gstForm">
                                <div class="form-container mdl-frm-lbl mb-3 mt-1">
                                    <div class="row">
                                        <div class="col-sm-6 mb-4" *ngIf="gst_flag">
                                            <div class="floating-label mb-0">      
                                            <input (change)="gstdetails()" *ngIf="gst_flag" type="text" name="gst_number" formControlName="gst_number" class="form-control" placeholder="" />
                                            <span class="highlight"></span>
                                            <label>Please enter your GST Number</label>
                                            </div> 
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="gst_flag && this.billingAddress.address">
                                        <div class="col-md-6">
                                            <div class="fs16 f-700 txtdefault mb-10">
                                                Billing Address 
                                            </div>
                                            {{this.billingAddress.address}}<br>
                                            {{this.billingAddress.state}}{{this.billingAddress.city}}<br>
                                            {{this.billingAddress.pincode}}.
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

                <div class="card card-ui mb-3">
                    <div class="row d-flex align-items-center">
                        <div class="col-md-12">
                            <div class="fs16 f-700 txtdefault mb-10">
                                Shipping Address 
                            </div>
                            <div class="text-muted">
                                <div class="mb-3">
                                <a class="btn-d-outline btn-sm d-inline-block" (click)="addaddr(newadrs, 'shipping', 'add', '')" href="javascript:void(0);">Add New     Shipping Address
                                </a>
                                <div class="table-responsive mt-2 brndmgnttable" *ngIf="sma_shipping_details.length > 0">
                                    <table class="table custom-table xs">
                                        <thead>
                                            <tr>
                                                <th width="20%"></th>
                                                <th width="20%">Address</th>
                                                <th width="15%">State</th>
                                                <th width="15%">City</th>
                                                <th width="15%">Pincode</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!-- TR -->
                                            <tr *ngFor="let record of sma_shipping_details; let i = index;">
                                                <td>
                                                    <div class="cssinptchk css-check">
                                                        <input type="radio" name="shippingAddressId" id="shippingType" class="css-checkbx"
                                                            (click)="click_shipping_address(record._id)" />
                                                        <label class="css-label" for="shippingType">
                                                            <div class="d-flex align-items-center">
                                                                <span class="f-700 fs14 mr-4">Delivery to</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>{{ record.address }}</td>
                                                <td>{{ record.state }}</td>
                                                <td>{{ record.city }}</td>
                                                <td>{{ record.pincode }}</td>
                                                <td>
                                                    <a (click)="addaddr(newadrs, 'shipping', 'edit', record._id)" href="javascript:void(0);"
                                                    class="link-alt fs13">Edit
                                                </a>
                                            </td>
                                            </tr>
                                            <!-- /TR -->
                                        </tbody>
                                    </table>
                                </div>
                                
                               </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card-ui mb-3" *ngIf="!gst_flag">
                    <div class="row d-flex align-items-center">
                        <div class="col-md-12">
                            <div class="fs16 f-700 txtdefault mb-10">
                                Billing Address 
                            </div>

                            <div class="text-muted">
                                <div class="mb-3">
                                    <a class="btn-d-outline d-inline-block" (click)="addaddr(newadrs, 'billing', 'add', '')" href="javascript:void(0);">Add New Billing Address
                                </a>
                                <div class="table-responsive brndmgnttable" *ngIf="sma_billing_details.length > 0">
                                    <table class="table custom-table xs">
                                        <thead>
                                            <tr>
                                                <th width="20%"></th>
                                                <th width="20%">Address</th>
                                                <th width="15%">State</th>
                                                <th width="15%">City</th>
                                                <th width="15%">Pincode</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!-- TR -->
                                            <tr *ngFor="let record of sma_billing_details; let i = index;">
                                                <td>
                                                    <div class="cssinptchk css-check">
                                                        <input type="radio" name="billingAddressId" id="billing_type" class="css-checkbx"
                                                            (click)="click_billing_address(record._id)" />
                                                        <label class="css-label" for="billing_type">
                                                            <div class="d-flex align-items-center">
                                                                <span class="f-700 fs14 mr-4">Bill to</span>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td>{{ record.address }}</td>
                                                <td>{{ record.state }}</td>
                                                <td>{{ record.city }}</td>
                                                <td>{{ record.pincode }}</td>
                                                <td> 
                                                    <a (click)="addaddr(newadrs, 'billing', 'edit', record._id)" href="javascript:void(0);"
                                                    class="link-alt fs13">Edit
                                                </a>
                                            </td>
                                            </tr>
                                            <!-- /TR -->
                                        </tbody>
                                    </table>
                                </div>
                               
                               </div>
                             <p class="txtseconday fs14 lnht24" >{{ sma_billing_name }}</p>
                            </div>
                        </div>
                    </div>
                </div>
               

                <ng-template #newadrs let-modal>
                    <div class="modal-body px-4 mx-2 text-center mdlcmnui pt-4 mt-3">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
                              <span aria-hidden="true">&times;</span>
                            </button>
                            <h5 *ngIf="modelType == 'shipping'" class="modal-title text-center cg-ttl pb-2">{{!this.sma_shipping_id ? "Add New Shipping Address" : "Update New Shipping Address"}}</h5>
                            <h5 *ngIf="modelType == 'billing'" class="modal-title text-center cg-ttl pb-2">{{!this.sma_billing_id ? "Add New Billing Address" : "Update New Billing Address"}}</h5>
                            <form [formGroup]="addShippingForm">
                                <div class="form-container mdl-frm-lbl mb-3">
                                    <div class="row">
                                        <div class="col-sm-12 mb-4">
                                            <div class="floating-label mb-0">      
                                            <textarea class="form-control" placeholder="" formControlName="address"></textarea>
                                            <span class="highlight"></span>
                                            <label>Address</label>
                                            </div> 
                                        </div>
                                    </div>
                
                                    
                                    <div class="row">
                                        <div class="col-sm-6 mb-4">
                                            <div class="floating-label mb-0">
                                                <select class="form-control" formControlName="state" (change)="getcity()">
                                                    <option selected="selected" value="">Please Select</option>
                                                    <option *ngFor="let item of stateArray" [ngValue]="item.value">{{item.name}}</option>
                                                </select>
                                                <span class="highlight"></span>
                                                <label>State</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 mb-4">
                                            <div class="floating-label mb-0">
                                                <select class="form-control" formControlName="city">
                                                    <option selected="selected" value="">Please Select</option>
                                                    <option *ngFor="let item of cityArray" [ngValue]="item.value">{{item.name}}</option>
                                                </select>
                                                <span class="highlight"></span>
                                                <label>City</label>
                                            </div>
                                        </div>

                                        <div class="col-sm-6 mb-4" *ngIf="modelType == 'shipping'">
                                            <div class="floating-label mb-0">      
                                            <input type="number" class="form-control" (keyup)="phonenumber()" placeholder="" formControlName="phone_number" />
                                            <span class="highlight"></span>
                                            <label>Phone Number</label>
                                            </div> 
                                        </div>
                
                                        <div class="col-sm-6 mb-4">
                                            <div class="floating-label mb-0">      
                                            <input type="text" class="form-control" placeholder="" formControlName="pincode" />
                                            <span class="highlight"></span>
                                            <label>Pincode</label>
                                            </div> 
                                        </div>
                                        <div class="col-sm-6 mb-4">
                                            <div class="floating-label mb-0">      
                                            <input type="text" class="form-control" placeholder="" formControlName="address_name" />
                                            <span class="highlight"></span>
                                            <label>Name this address as (optional)</label>
                                            </div> 
                                        </div>
                                    </div>
                                </div>   
                
                                <div class="cta  pb-4 text-center">
                                    <button *ngIf="modelType == 'shipping'"  class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250" (click)="saveshippingform()" [disabled]="addShippingForm.invalid || checkphonenumber">{{!this.sma_shipping_id ? "Add Address" : "Update Address"}}</button>
                                    <button *ngIf="modelType == 'billing'"  class="btn btn-mod btn-color btn-medium btn-round ripple mnbtn250" (click)="saveshippingform()" [disabled]="addShippingForm.invalid">{{!this.sma_billing_id ? "Add Address" : "Update Address"}}</button>
                                </div>
                            </form>
                
                      </div>
                </ng-template>

                

                <p class="text-muted fs14 mt-4">Payment Method</p>
                <div class="card card-ui mb-3 eq-checkout">
                    <div class="gstfq-wp roundradio">
                        <!-- <div *ngIf="BNPLPaymentStatus == true">
                            <div class="cssinptchk css-check">
                                <input type="radio" name="payment_from" class="css-checkbx" value="bnpl" [(ngModel)]="payment_method"
                                    (click)="enablebnpl()" [disabled]="minimum_amount_failed" />
                                <label class="css-label w-100">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="d-flex align-items-center mb-2">
                                                <img src="assets/images/icons/wallet-icon.svg" class="mr-2">
                                                <span class="f-700 fs14">BNPL Payment <span class="ml-2 alert alert-info">Interest FREE - 30 Days</span></span>
                                            </div>
                                            <div><b>Balance:</b> ₹  {{ BNPLPaymentDetails?.accountDetails?.account?.balance?.value }}  <span class="px-3">|</span><b>Limit:</b> ₹  {{BNPLPaymentDetails?.accountDetails?.account?.limit?.value}}
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <div class="col-md-12 d-flex justify-content-start" *ngIf="payment_method == 'bnpl'">
                                <button class="buybtn btn fs16 radius10" *ngIf="wallet_button" (click)="bnplPayment()">Pay
                                    {{ checkout_amount }}</button>
                            </div>
                        </div> -->
                        <!-- <div class="cssinptchk css-check">
                            <input type="radio" name="payment_from" class="css-checkbx" value="wallet"
                                [(ngModel)]="payment_method" (click)="placeorder('wallet')" [disabled]="minimum_amount_failed"/>
                            <label class="css-label w-100">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="d-flex align-items-center">
                                            <img src="assets/images/icons/wallet-icon.svg" class="mr-2">
                                            <span class="f-700 fs14">Equibiz Wallet</span>
                                        </div>
                                        <div>Balance : {{ walletbalance }}</div>
                                    </div>
                                </div>
                            </label>
                        </div>
                        <div class="col-md-12 d-flex justify-content-start" *ngIf="payment_method == 'wallet'">
                            <button class="buybtn btn fs16 radius10" *ngIf="wallet_button" (click)="walletpayment()">Pay
                                {{ checkout_amount }}</button>
                        </div> -->

                        <div class="cssinptchk css-check">
                            <input type="radio" name="payment_from" class="css-checkbx" value="cashfree"
                                [(ngModel)]="payment_method" (click)="placeorder('pg', 'cashfree')" [disabled]="minimum_amount_failed" />
                            <label class="css-label">
                                <div class="d-flex align-items-center md-block">
                                    <span class="f-700 fs14 mr-4">Select Payment Methods</span>
                                    <img src="/assets/images/checkout-payments.png">
                                </div>
                            </label>
                        </div>
                        <!-- <hr>
                        <div class="cssinptchk css-check">
                            <input type="radio" name="payment_from" class="css-checkbx" value="payu"
                                [(ngModel)]="payment_method" (click)="placeorder('pg', 'payu')" [disabled]="minimum_amount_failed" />
                            <label class="css-label">
                                <div class="d-flex align-items-center md-block">
                                    <span class="f-700 fs14 mr-4">Other Payment Methods</span>
                                    <img src="/assets/images/checkout-payments.png">
                                </div>
                            </label>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="d-flex align-items-center justify-content-end">
                        <div class="fs14 txtdefault">
                            <svg xmlns="http://www.w3.org/2000/svg" class="mr-2"
                                xmlns:xlink="http://www.w3.org/1999/xlink" width="13.184" height="14.061"
                                viewBox="0 0 13.184 14.061">
                                <defs>
                                    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1"
                                        gradientUnits="objectBoundingBox">
                                        <stop offset="0" stop-color="#79b7ff" />
                                        <stop offset="1" stop-color="#07f" />
                                    </linearGradient>
                                </defs>
                                <path id="Icon_awesome-shield-alt" data-name="Icon awesome-shield-alt"
                                    d="M13.5,2.3,8.225.1A1.322,1.322,0,0,0,7.211.1L1.938,2.3a1.317,1.317,0,0,0-.813,1.217c0,5.452,3.145,9.22,6.084,10.445a1.323,1.323,0,0,0,1.013,0c2.354-.981,6.086-4.367,6.086-10.445A1.319,1.319,0,0,0,13.5,2.3ZM7.72,12.259l0-10.464,4.831,2.013A9.412,9.412,0,0,1,7.72,12.259Z"
                                    transform="translate(-1.125 -0.002)" fill="url(#linear-gradient)" />
                            </svg>
                            Safe and Secure Purchase.
                        </div>
                    </div>
                </div>

                <div *ngIf="payment_method == 'cashfree'">
                    <p class="text-muted fs14">Select Payment Type</p>
                    <div class="card card-ui mb-3 eq-selectPg">
                        <div class="gstfq-wp roundradio">
                            <div class="cssinptchk css-check">
                                <input type="radio" name="payment_type" id="card_payment_type" class="css-checkbx"
                                    (click)="makepayment('card')" />
                                <label class="css-label" for="card_payment_type">
                                    <div class="d-flex align-items-center">
                                        <span class="f-700 fs14 mr-4">Credit/Debit Card</span>
                                    </div>
                                </label>
                            </div>
                            <hr>
                            <div class="cssinptchk css-check">
                                <input type="radio" name="payment_type" id="upi_payment_type" class="css-checkbx"
                                    (click)="makepayment('upi')" />
                                <label class="css-label" for="upi_payment_type">
                                    <div class="d-flex align-items-center">
                                        <span class="f-700 fs14 mr-4">UPI</span>
                                    </div>
                                </label>
                            </div>
                            <hr>
                            <div class="cssinptchk css-check">
                                <input type="radio" name="payment_type" id="netbanking_payment_type" class="css-checkbx"
                                    (click)="makepayment('netbanking')" />
                                <label class="css-label" for="netbanking_payment_type">
                                    <div class="d-flex align-items-center">
                                        <span class="f-700 fs14 mr-4">Net Banking</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card card-ui mb-3" style="display: none;" id="dropincontainerMainDiv">
                    <div class="customCard" id="drop_in_container">
                    </div>
                </div>

                <div *ngIf="saved_cards_records.length > 0 && payment_type == 'cardd'">
                    <p class="text-muted fs14">Select from Saved Cards</p>
                    <div class="card card-ui mb-3 eq-savedCards">
                        <div class="gstfq-wp roundradio">
                            <div class="saved-item cssinptchk css-check"
                                *ngFor="let card of saved_cards_records; let i = index;">
                                <label class="css-label">
                                    <div>
                                        <div class="row d-flexa align-items-center justify-content-between">
                                            <div class="col-md-2">
                                                <span class="f-700 fs15 captiablize">{{ card.name }}</span>
                                            </div>
                                            <div class="col-md-4">
                                                <span class="f-500 fs15">{{ card.number }}</span>
                                            </div>
                                            <div class="col-md-2">
                                                <span class="f-500 fs15">{{ card.expiry }}</span>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="text" class="form-control" placeholder="cvv"
                                                    [(ngModel)]="card.cvv">
                                            </div>
                                            <div class="col-md-2">
                                                <button class="btn buybtn fs16 btn-block radius10"
                                                    (click)="submitsavedcard(card)">Pay Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="payment_type == 'cardd'">
                    <p class="text-muted fs14">Enter Card Details</p>
                    <div class="card card-ui mb-3">
                        <div class="customCard" id="card_in_container">
                            <form [formGroup]="creditCardForm" #demoForm="ngForm" (ngSubmit)="cardsubmit('new')"
                                novalidate>
                                <div class="row d-flex justify-content-center">
                                    <div class="col-md-6">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Card Number</label>
                                                    <input class="card-input__input" id="cc-number"
                                                        formControlName="creditCard" type="tel" autocomplete="cc-number"
                                                        ccNumber>
                                                    <span class="help-block"
                                                        *ngIf="cc.creditCard.invalid && cc.creditCard.touched">Enter
                                                        Valid Card Number</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">Card Expiry Date</label>
                                                    <input class="card-input__input" id="cc-exp-date"
                                                        formControlName="expirationDate" type="tel"
                                                        autocomplete="cc-exp" ccExp>
                                                    <span class="help-block"
                                                        *ngIf="cc.expirationDate.invalid && cc.expirationDate.touched">Enter
                                                        Valid Expiry Date</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="">CVV</label>
                                                    <input class="card-input__input" id="cc-cvc" formControlName="cvv"
                                                        type="tel" autocomplete="off" ccCvc>
                                                    <span class="help-block"
                                                        *ngIf="cc.cvv.invalid && cc.cvv.touched">Enter Valid CVV
                                                        Number</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="">Card Holder Name</label>
                                                    <input class="card-input__input" formControlName="cardHolderName"
                                                        type="text" autocomplete="off" style="text-transform:uppercase">
                                                    <span class="help-block"
                                                        *ngIf="cc.cardHolderName.invalid && cc.cardHolderName.touched">Enter
                                                        card holder name.</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div class="css-check">
                                                <input class="css-checkbx" type="checkbox"
                                                    formControlName="save_card" />
                                                <label class="css-label">Save Card for Faster Checkout</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="mt-30">
                                    <button class="btn px-4 buybtn fs18 btn-block radius10"
                                        [disabled]="creditCardForm.invalid">Pay {{ checkout_amount }} </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-md-3">
                <div class="card card-ui mb-4">
                    <div class="d-flex align-items-center">
                        <div class="mr-3"><img src="{{ first_record.product_image }}" style="max-width:80px"></div>
                        <h5 class="mb-0 fs14 f-600">{{ first_record.product_name }}</h5>
                    </div>
                    <hr>
                    <div class="row d-flex justify-content-between" *ngIf="!first_record.child">
                        <div class="col-6 col-md-6 py-2 f-600">Price Per Unit: &nbsp;<i placement="top"
                                ngbTooltip="Inclusive of GST">
                                <img src="/assets/images/icons/info.png"></i></div>
                        <div class="col-6 col-md-6 py-2 text-right">₹ {{ first_record.price_per_unit }}</div>
                    </div>
                    <div class="row d-flex justify-content-between">
                        <div class="col-6 col-md-6 py-2 f-600">Order Quantity:</div>
                        <div class="col-6 col-md-6 py-2 text-right">{{ first_record.qty }}
                            <a href="javascript:;" class="ml-1" (click)="openWindowCustomClass(content2)"><img
                                    src="/assets/images/icons/edit.png"></a>
                        </div>
                    </div>
                    <!-- <div class="row d-flex justify-content-between">
                        <div class="col-6 col-md-6 py-2 f-600">Logistics:</div>
                        <div class="col-6 col-md-6 py-2 text-right">{{ first_record.logistics }}
                            <a href="javascript:;" class="ml-1" (click)="openWindowCustomClass(content2)"><img
                                    src="/assets/images/icons/edit.png"></a>
                        </div>
                    </div>
                    <div class="row d-flex justify-content-between">
                        <div class="col-md-12 py-1 f-600">Shipping Address:</div>
                        <div class="col-md-12 py-2 text-left">{{ first_record.shipping_address }}
                            <a href="javascript:;" class="ml-1" (click)="openWindowCustomClass(content2)"><img
                                    src="/assets/images/icons/edit.png"></a>
                        </div>
                    </div> -->
                    <hr *ngIf="records.length > 1">
                    <div class="text-center" *ngIf="records.length > 1">
                        <a href="javascript:;" class="link-alt fs14" (click)="openWindowCustomClass(content2)">{{
                            records.length - 1 }} more items</a>
                    </div>
                    <hr>
                    <div class="row d-flex justify-content-between">
                        <div class="col-md-12 py-1 f-600">
                            <div class="fs20 f-600">Total Order Value:<br><span class="f-700 f22">{{ total_amount }} <a
                                        href="javascript:;" class="ml-1" (click)="open(content)">&nbsp;<i
                                            placement="top" ngbTooltip="View Price Breakup">
                                            <img src="/assets/images/icons/info.png"></i></a></span></div>
                        </div>

                    </div>
                </div>

                <div class="card card-ui mb-3">
                    <div class="row d-flex align-items-center">
                        <div class="col-md-12">
                            <div class="fs20 f-600">Checkout Amount:<br><span class="f-700 f22">{{ checkout_amount
                                    }}</span></div>
                            <a href="javascript:;" class="link-alt fs12"
                                (click)="openbreakup(contentcheckoutbreakup)">View Detailed Price Breakup</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Order Total -->
<ng-template #content let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead text-center">
                    <h4 class="txtdefault wt700 fs22 mb-20">Order Total</h4>
                </div>

                <div class="inrcard p-3 radius10 position-relative">
                    <div class="row">
                        <div class="col-md-12">
                            <!-- <div class="row d-flex justify-content-between">
							<div class="col-md-6 py-1">Order Quantity:</div>
							<div class="col-md-6 py-1 text-right">EQB9876598754ABCD</div>
						</div>
						<div class="row d-flex justify-content-between">
							<div class="col-md-6 py-1">Price Per Unit: &nbsp;<i placement="top" ngbTooltip="Inclusive of GST"><img src="/assets/images/icons/info.png"></i></div>
							<div class="col-md-6 py-1 text-right">₹ 2,369</div>
						</div>
						<hr> -->
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 py-1">Net Goods Value:</div>
                                <div class="col-md-6 py-1 text-right">{{ net_amount }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 py-1">GST:</div>
                                <div class="col-md-6 py-1 text-right">{{ gst_amount }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-6 py-1" ngbDropdown>
                                    <p class="fs14 txtactive" id="dropdownBasic1" ngbDropdownToggle>Other Charges
                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.143" height="5.849"
                                            viewBox="0 0 10.143 5.849">
                                            <path id="Path_2277" data-name="Path 2277"
                                                d="M.9.155A.528.528,0,0,0,.155.9l3.92,3.92L.155,8.742A.528.528,0,0,0,.9,9.488L5.194,5.195a.527.527,0,0,0,0-.746Z"
                                                transform="translate(9.893 0.25) rotate(90)" fill="#ff7135"
                                                stroke="#ff7135" stroke-width="0.5" />
                                        </svg>
                                        <i placement="top" ngbTooltip="other charges include shipping charges"><img
                                                src="assets/images/svg/info.svg" /></i>
                                    </p>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1"
                                        *ngIf="other_charges.length > 0">
                                        <button ngbDropdownItem *ngFor="let other of other_charges; let i = index;">{{
                                            other }} </button>
                                    </div>
                                </div>
                                <div class="col-6 pl-0 text-right">
                                    <label class="fs14  txtdefault">{{ other_amount }}</label>
                                </div>
                            </div>
                            <div class="row d-flex justify-content-between"
                                *ngIf="insurance_amount > 0 && enable_insurance">
                                <div class="col-md-6 py-1">Insurance:</div>
                                <div class="col-md-6 py-1 text-right">{{ insurance_amount }}</div>
                            </div>
                            <!-- <div class="row d-flex justify-content-between">
							<div class="col-md-6 py-1">Coupon Discount:</div>
							<div class="col-md-6 py-1 text-right text-active">- {{ discount_amount }}</div>
						</div> -->
                            <hr>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 py-1 f-700">Total Order Value:</div>
                                <div class="col-md-6 py-1 text-right f-700">{{ total_amount }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /UPLOAD INVOICE -->

<!-- Order Total -->
<ng-template #content2 let-modal>
    <div class="modal-body p-0">
        <div class="checkout-summary">
            <div class="d-flex justify-content-between">
                <div class="fs20 mb-10 f-700 text-muted">Order Summary</div>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
            </div>
            <div class="card card-ui mb-3" *ngFor="let record of records; let i = index;">
                <div class="d-flex align-items-center">
                    <div class="mr-3"><img src="{{ record.product_image }}" style="max-width:80px"></div>
                    <h5 class="mb-0 fs14 f-600">{{ record.product_name }}</h5>
                </div>
                <hr class="mb-10">
                <table class="table checkout-table mb-0">
                    <thead>
                        <tr>
                            <!-- <th width="40%"><b>Shipping Address</b></th>
                            <th><b>Logistics</b></th> -->
                            <th *ngIf="!record.child"><b>Price Per Unit</b></th>
                            <th *ngIf="!record.child"><b>Order quantity</b></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <!-- <td *ngIf="!record.change_address">{{ record.shipping_address }} <a href="javascript:;"
                                    class="ml-2" (click)="changeaddress(record)"><img
                                        src="/assets/images/icons/edit-big.png"></a></td>
                            <td *ngIf="record.change_address">
                                <select class="form-control" [(ngModel)]="record.selected_address">
                                    <option value="">Select</option>
                                    <option *ngFor="let addr of shipping_addresses;" value="{{ addr.id }}">{{
                                        addr.address }}</option>
                                </select>
                                <a href="javascript:void(0);" (click)="updateaddress(record)">Save</a>
                            </td> -->
                            <td *ngIf="!record.change_logistics">{{ record.logistics == 'standard'?'Standard
                                Delivery':'Express Delivery' }} <a href="javascript:;" class="ml-2"
                                    (click)="changedelivery(record)"><img src="/assets/images/icons/edit-big.png"></a>
                            </td>
                            <td *ngIf="record.change_logistics">
                                <select class="form-control" [(ngModel)]="record.logistics">
                                    <option value="standard" [selected]="record.logistics == 'standard'">Standard
                                        Delivery</option>
                                    <option *ngIf="record.map_type == 'bulk'" value="express" [selected]="record.logistics == 'express'">Express Delivery
                                    </option>
                                </select>
                                <a href="javascript:void(0);" (click)="updatedelivery(record)">Save</a>
                                <!-- class="tddsv" -->
                            </td>
                            <td *ngIf="!record.child">₹ {{ record.price_per_unit }}</td>
                            <td *ngIf="!record.child">
                                <div class="checkout-qty d-flex align-items-center">
                                    <div>
                                        <a href="javascript:;" class="text-muted"
                                            (click)="changequantity(record, 'minus')"><i class="fa fa-minus"
                                                aria-hidden="true"></i></a>
                                    </div>
                                    <div>
                                        <input type="text" class="form-control" style="width:60px"
                                            [(ngModel)]="record.qty" name="qty"
                                            (change)="changequantity(record, 'change')">
                                    </div>
                                    <div>
                                        <a href="javascript:;" (click)="changequantity(record, 'plus')"><i
                                                class="fa fa-plus" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- If record have child -->
                        <ng-container *ngIf="record.child">
                            <tr *ngFor="let rechild of record.child_records">
                                <td colspan="3">
                                    <div class="d-flex align-items-center">
                                        <div class="mr-2"><img src="{{ rechild.image?rechild.image:'assets/images/products/p-2.jpg' }}" style="max-width:80px"></div>
                                        <div class="txtdefault wt700 mb-0 fs14 lnht20">{{ rechild.product_name }}</div>
                                    </div>
                                </td>
                                <td>₹ {{ rechild.price_per_unit }}</td>
                                <td>
                                    <div class="checkout-qty d-flex align-items-center">
                                        <div>
                                            <a href="javascript:;" class="text-muted"
                                                (click)="changequantity(rechild, 'minus')"><i class="fa fa-minus"
                                                    aria-hidden="true"></i></a>
                                        </div>
                                        <div>
                                            <input type="text" class="form-control" style="width:60px"
                                                [(ngModel)]="rechild.qty" name="qty"
                                                (change)="changequantity(rechild, 'change')">
                                        </div>
                                        <div>
                                            <a href="javascript:;" (click)="changequantity(rechild, 'plus')"><i
                                                    class="fa fa-plus" aria-hidden="true"></i></a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <!-- If record have child -->


                    </tbody>
                </table>
            </div>
            <!-- <div class="card card-ui mb-3">
				<div class="d-flex align-items-center">
					<div class="mr-3"><img src="assets/images/p-2.jpg" style="max-width:80px"></div>
					<h5 class="mb-0 fs14 f-600">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage)  | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</h5>
				</div>
				<hr class="mb-10">
				<table class="table checkout-table mb-0">
					<thead>
						<tr>
							<th width="50%">Shipping Address</th>
							<th>Logistics</th>
							<th>Price Per Unit</th>
							<th>Order quantity</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>MS Ali Road, Grant Road East, Bharat Nagar <a href="javascript:;" class="ml-2"><img src="/assets/images/icons/edit-big.png"></a></td>
							<td>Standard Delivery</td>
							<td>₹ 7,599</td>
							<td>
								<div class="checkout-qty d-flex align-items-center">
									<div><a href="javascript:;" class="text-muted"><i class="fa fa-minus" aria-hidden="true"></i></a></div>
									<div><input type="text" class="form-control" style="width:60px" value="50" name="qty"></div>
									<div><a href="javascript:;"><i class="fa fa-plus" aria-hidden="true"></i></a></div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="card card-ui mb-3">
				<div class="d-flex align-items-center">
					<div class="mr-3"><img src="assets/images/p-2.jpg" style="max-width:80px"></div>
					<h5 class="mb-0 fs14 f-600">Vivo X60 Pro (Midnight Black, 12GB RAM, 256GB Storage)  | Upto 12 Months No Cost EMI | Extra 4000 Off on Exchange</h5>
				</div>
				<hr class="mb-10">
				<table class="table checkout-table mb-0">
					<thead>
						<tr>
							<th width="50%">Shipping Address</th>
							<th>Logistics</th>
							<th>Price Per Unit</th>
							<th>Order quantity</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>MS Ali Road, Grant Road East, Bharat Nagar <a href="javascript:;" class="ml-2"><img src="/assets/images/icons/edit-big.png"></a></td>
							<td>Standard Delivery</td>
							<td>₹ 7,599</td>
							<td>
								<div class="checkout-qty d-flex align-items-center">
									<div><a href="javascript:;" class="text-muted"><i class="fa fa-minus" aria-hidden="true"></i></a></div>
									<div><input type="text" class="form-control" style="width:60px" value="50" name="qty"></div>
									<div><a href="javascript:;"><i class="fa fa-plus" aria-hidden="true"></i></a></div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div> -->
        </div>
    </div>
</ng-template>
<!-- /UPLOAD INVOICE -->

<!-- Insurance Content MODAL -->
<ng-template #contentinsurance let-modal>
    <div class="modal-header nobd text-center pt-4 pb-0">
        <h4 class="modal-title fs22 txtdefault f-700 mx-auto pt-3">Insurance Terms & Conditions</h4>
    </div>
    <div class="modal-body">
        <div class="terms-conditions">
            <h5><b>Tata AIG General Insurance Company Limited</b></h5>
            <div class="fs18 f-700 mb-10">MARINE CARGO OPEN POLICY</div>
            <div><b>VOYAGE: </b></div>
            <p>From anywhere in India to anywhere in India.</p>
            <div><b>INSURING CLAUSES: </b></div>
            <p>Inland Transit (Rail/Road/Air) Clause - A 2010<br>
            Strikes Riots and Civil Commotion Clause - 2010<br>
            Limitation of Liability Clause<br>
            Termination of Transit Clause (Terrorism) JC 2009/056 (01.01.09)<br>
            Institute Radioactive Contamination, Chemical, Biological, Bio-Chemical, Electromagnetic Weapons Exclusion Clause CL.370 (10.11.03)<br>
            Institute Cyber Attack Exclusion Clause CL.380 (10.11.03)<br>
            Institute Standard Conditions for Cargo Contracts 1/4/82<br>
            Important Notice Clause</p>
            <div><b>WARRANTIES, CONDITIONS & EXCLUSIONS:</b></div>
            <p>Institute Replacement clause: In the event of loss or damage to any part or parts of an insured machine caused by a peril covered by the Policy, the sum recoverable shall not exceed the cost of replacement or repair of such part or parts plus charges for forwarding and refitting, if incurred, but excluding duty unless the full duty is included in the amount insured, in which case loss, if any, sustained by payment of additional duty shall also be recoverable.</p>
            <p>ISSUING OF CERTIFICATES CONDITION: The Assured will be authorized to issue Certificate of Insurance through e-marine which is Web based tool. As per Statutory Provision, premium adequacy of sum insured has to be ensured at any point of time. (not applicable for Project policies). This condition is applicable for those voyages where type of declaration opted as Marine certificate under the policy.</p>
            <p>CANCELLATION CLAUSE CONDITION: This contract may be cancelled by either the Company or the Assured giving 30 day&rsquo;s notice in writing to take effect from the midnight of date of notice but risks covered by Institute War Clauses may be cancelled at seven day&rsquo;s notice and risks covered by the Institute Strikes Clauses may be cancelled at seven day&rsquo;s notice, or at forty-eight hour&rsquo;s notice in respect of shipments to or from the United States of America. Notice shall commence from midnight of the day when it is issued but cancellation shall not apply to any risk which have attached in accordance with the cover granted hereunder before the cancellation becomes effective.</p>
            <p>Excluding coverage in respect of Institute War Clauses (Cargo) and Institute Strikes Clauses (Cargo) where Tata AIG risk commences from Bahrain, Kuwait, Oman, Qatar, Saudi Arabia and United Arab Emirates and/or where Tata AIG risk terminates at Bahrain, Kuwait, Oman, Qatar, Saudi Arabia and United Arab Emirates. The Reinstatement of Institute War Clauses (Cargo) and Institute Strikes Clauses (Cargo) shall be subject to an additional premium at the rate of 0.05% on the shipment value, to be paid/remitted to the Tata AIG prior to the commencement of the transit, failing which Tata AIG shall be absolved of all liabilities</p>
            <p>VERIFICATION OF RECORD CONDITION: The Company shall have the privilege at any time during business hours to inspect the records of the Assured in respect of the shipment or sending or risk falling within the terms of this contract.</p>
            <p>COMMUNICABLE DISEASE EXCLUSION LMA 5394 (amended) This exclusion applies to all original risks (including individual declarations made under facilities). 1. Notwithstanding any provision to the contrary within this insurance agreement, this insurance agreement excludes any loss, damage, liability, claim, cost or expense of whatsoever nature, directly or indirectly caused by, contributed to by, resulting from, a rising out of, or in connection with a Communicable Disease or the fear or threat (whether actual or perceived) of a Communicable Disease regardless of any other cause or event contributing concurrently or in any other sequence thereto. 2. As used herein, a Communicable Disease means any disease which can be transmitted by means of any substance or agent from any organism to another organism where:</p>
            <p>2.1. the substance or agent includes, but is not limited to, a virus, bacterium, parasite or other organism or any variation thereof, whether deemed living or not, and</p>
            <p>2.2. the method of transmission, whether direct or indirect, includes but is not limited to, airborne transmission, bodily fluid transmission, transmission from or to any surface or object, solid, liquid or gas or between organisms, and</p>
            <p>2.3. the disease, substance or agent can cause or threaten damage to human health or human welfare or can cause or threaten damage to, deterioration of, loss of value of, marketability of or loss of use of property. Notwithstanding the foregoing, losses resulting directly or indirectly from, or caused by, contributed to by, resulting from, arising out of, or in connection with any otherwise covered peril under the Policy and not otherwise excluded under this insurance agreement shall be covered.</p>
            <p>Notwithstanding anything to the contrary stated in this policy below conditions shall be applicable for Return/ Used / Second Hand/ Scrap/Rejected items: (a) All pre-existing losses or damages prior to commencement of transit under the policy are specifically excluded from the scope of cover. (b) Coverage shall be subject to Institute Cargo Clause &apos;B&apos; + War &amp; Strikes / Inland Transit Clause &lsquo;B&rsquo; + Strike Riots Civil Commotion + Non-Delivery of the consignment on the conveyance, as applicable (c) Basis of Valuation applicable shall be: Depreciated Market Value. (d) Replacement (second-hand) Machinery Clause : In the event of claim for loss or damage to any part or parts of the Insured Interest in consequence of a peril covered by this insurance, the amount recoverable here under shall not exceed such proportion of the cost of replacement of the parts lost or damaged as the insured value bears to the value of new machinery, plus additional charges for forwarding and refitting the new parts if incurred. Provided always that in no case shall the liability of Underwriters exceed the insured value of the complete machine. (d) In case mode of conveyance under the policy also cover transits through air then, such type of cargoes will be covered for loss or damage where proximate cause is due to Fire, Explosion, Lightning, Collision with or by the carrying vehicle/aircraft, Overturning of the carrying vehicle/aircraft, Derailment or accidents of like nature to the carrying railway wagon/ vehicle/aircraft, Strike Riots Civil Commotion and Non Delivery of the consignment on the conveyance Excluding loss and damage to the software from the scope of the policy.</p>
            <div><b>JOINT EXCESS LOSS CYBER LOSSES CLAUSE (JX2020-007) </b></div>
            <p>1. Notwithstanding any other term of this Contract save for Clause 2 below, in no case shall this Contract cover loss, damage, liability, or expense directly or indirectly caused by or contributed to by or arising from the use or operation of an Information Technology Device as a means for inflicting harm.</p>
            <p>2. Where this clause is endorsed on policies covering risks of war, civil war, revolution, rebellion, insurrection, or civil strife arising therefrom, or any hostile act by or against a belligerent power, or terrorism or any person acting from a political motive, Clause 1 shall not exclude losses which would otherwise be covered arising from the use of any Information Technology Device in the launch and/or guidance system and/or firing mechanism of any weapon or missile.</p>
            <p>3.Where the use or operation of an Information Technology Device was not used as a means of inflicting harm, any ensuing loss, damage, liability or expense shall be excluded unless a physical peril named in the Information Technology Hazards Clause within JELC CL432 (16/10/2017) was also a significant cause of a loss. In such case this Contract shall cover the loss, damage, liability or expense in accordance with but only to the extent provided for in that Clause, whether that Clause forms part of this Contract or not.</p>
            <p>4. For the purposes of this clause, an Information Technology Device shall mean any computer system, hardware, software, programme, code, data, process, virus, information repository, microchip, integrated circuit or similar device in or connected with computer equipment or non-computer equipment, whether the property of a direct insured or not.</p>
            <p>In case transit is through post, courier or registered post parcel no claim for loss /damage will be admitted if proved to be due to incorrect/ambiguous insufficient address on the package or having been unaccepted by the addressee.</p>
            <p>ADDITIONAL CONDITIONS APPLICABLE FOR TAIL-END RISKS OF CIF/CIP IMPORTS or where the overseas leg is not required to be insured by the Importer: Notwithstanding anything to the contrary stated in this policy below conditions shall be applicable: (a) All pre-existing damages prior to commencement of the transit under the policy are specifically excluded. (b) Coverage shall be subject to Inland Transit Clause &lsquo;B&rsquo; + SRCC + Non-delivery of the carrying conveyance + Act of God (AOG) perils. (c) In case mode of conveyance under the policy also cover transits through air then subject matter insured will be covered for Fire, Explosion, Lightning, Collision with or by the carrying vehicle/aircraft, Overturning of the carrying vehicle/aircraft, Derailment or accidents of like nature to the carrying railway wagon/ vehicle/aircraft, Strike Riots Civil Commotion, Non Delivery of the consignment on the conveyance and Act of God (AOG) perils Notwithstanding to the contrary, Duration Clause (6.1.6) under the Inland Transit Clause (2010) is deleted and replaced with &quot;in respect of transit by Air only until expiry of 30 days after unloading the subject- matter insured from the aircraft at the final place of discharge&quot;</p>
            <p>In case claim experience declared by the client at the time of quoting or gets deteriorated post quoting, the same should be declared to the Insurer, failing which Insurer shall be absolved of all liabilities ab-initio Excluding mechanical, electrical, electronic, electro-mechanical and internal derangement losses from scope of cover of the policy unless the loss or damage is caused due to Institute Cargo Clauses - B (2009) perils or Inland Transit Clause - B (2010) perils, as applicable. In case mode of conveyance under the policy also cover transits through air then, such losses will be covered where proximate cause of loss is due to : Fire, Explosion, Lightning, Collision with or by the carrying vehicle/aircraft, Overturning of the carrying vehicle/aircraft, Derailment or accidents of like nature to the carrying railway wagon/ vehicle/aircraft Excluding damage to labels unless the goods themselves are damaged at the same time. Excluding bulk cargo, break-bulk cargo and road tanker movements from the scope of the policy. Warranted that if the weight of the cargo exceeds than Registration Laden weight/ Licensed Carrying Capacity of the vehicle, as mentioned in the Registration Certificate of the vehicle, then any loss or damage attributable to overloading is not covered under the policy, unless at the time of loading, the assured or its employees were not privy to such overloading where such loading is taking place after the attachment of risk as per the Duration Clause of the corresponding Institute Cargo Clauses (2009) and /or Inland Transit Clauses (2010) as applicable . Warranted that the Goods are transported in Closed Wagons/Trucks or Trucks to be covered with Tarpaulin or any other water proof Material. However, any loss due to Ingress of Rain Water/Moisture in Cargo because of Improper Precaution / Faulty Tarpaulin / Material used by transporter for which client is not Privy is Considered to be covered.</p>
            <p>Excluding Shortages from Seal intact containers and/or sound packing and/or unexplained shortages Where any item insured under this policy consists of articles in a pair or set, the Company&rsquo;s liability shall not exceed the value of any particular part or parts which may be lost or damaged without reference to any special value which such articles may have as part of such pair or set and in any event not more than a proportionate part of the insured value of the pair or set of Valuation stated in the Policy.(not applicable for Project policies)</p>
            <p>Excluding Denting, Bending, Scratching, Chipping, Damage to Paint &amp; Polish work unless caused by Inland Transit Clause (B)/ Institute Cargo Clause (B) perils</p>
            <p>Excluding Rusting, oxidization, discoloration, unless caused by Inland Transit Clause (B)/ Institute Cargo Clause (B) perils</p>
            <div><b>EXCESS</b></div>
            <p>DOMESTIC : 1 % of consignment value or INR 10,000 whichever is higher for each and every claim.</p>
        </div>
    </div>
    <div class="modal-footer  text-center nobd mx-auto pb-4 mb-3">
        <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3"
            (click)="modal.close('Close click')">Cancel</button>
        <button [disabled]="minimum_amount_failed" type="button" class="btn buybtn fs16 radius10" *ngIf="!enable_insurance" (click)="enableinsurance('yes')">{{
            !enable_insurance? 'Agree and Apply':'Remove insurance' }}</button>
    </div>
</ng-template>
<!-- /Insurance Content MODAL -->

<!-- Breakup Details -->
<ng-template #contentcheckoutbreakup let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>
    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead text-center">
                    <h4 class="txtdefault f-700 fs16 mb-20">Detailed Price Breakup</h4>
                </div>

                <div class="inrcard p-3 radius10 position-relative mb-2"
                    *ngFor="let record of popupbox_records; let i = index;">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-4 card-price-info f-700">Product:</div>
                                <div class="col-md-8 card-price-info text-right f-700">
                                    <span class="mr-3">{{ record.product_name }}</span>
                                    <span class="pd-taglist">
                                        <span class="f-600 fs10 radius15">{{ record.map_type }}</span>
                                    </span>
                                </div>
                            </div>
                            <hr class="mt-1 mb-1">
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">Payable Amount:</div>
                                <div class="col-md-6 card-price-info text-right">{{ record.pay_amount }} ({{
                                    record.pay_percentage }}%)</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">Pending Amount:</div>
                                <div class="col-md-6 card-price-info text-right">{{ record.pending_amount }} ({{
                                    record.pending_percentage }}%)</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="inrcard p-3 radius10 position-relative">
                    <div class="row d-flex justify-content-between">
                        <div class="col-md-6 card-price-info">Payable Amount:</div>
                        <div class="col-md-6 card-price-info text-right">{{ subtotal_amount }}</div>
                    </div>
                    <div class="row d-flex justify-content-between">
                        <div class="col-md-6 card-price-info">Other Charges:</div>
                        <div class="col-md-6 card-price-info text-right">{{ other_amount }}</div>
                    </div>
                    <hr class="mt-1 mb-1">
                    <div class="row d-flex justify-content-between">
                        <div class="col-md-6 f-700">Total Checkout Amount:</div>
                        <div class="col-md-6 text-right f-700">{{ checkout_amount }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /Breakup Details -->


<app-footer-one></app-footer-one>