import { Component, OnInit, NgZone, HostListener } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { EncrDecrService } from 'src/app/helpers/EncrDecrService';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { CheckoutService } from './checkout.service';
import { CreditCardValidators } from 'angular-cc-library';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { SignupService } from '../../shared/components/modal/signup-modal/signup-modal.service';
import { BuyerBuyNowPayLaterService } from "../buyer-buy-now-pay-later/buyer-buy-now-pay-later.component.service";
import { PersonalDetailsService } from 'src/app/seller/personal-details/personal-details.service';
import { HttpClient } from '@angular/common/http';

declare const window: any;

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
    host: { '(window:payment.success)': 'onSuccess($event)' }
})
export class CheckoutComponent implements OnInit {

    //Credit Card Form
    creditCardForm: FormGroup;
    submitted: boolean = false;
    
    public minimum_amount_failed:boolean = false;
    public enable_insurance: boolean = false;
    public enable_bnpl: boolean = false;
    closeResult = '';
    public pg_type:string = 'cashfree';
    public loggedin_user_token: string;
    public wallet_button: boolean = false;
    public popupbox_records: any = [];
    public selectedCard: any = {};
    public saved_cards_records: any = [];
    public shipping_addresses: any = [];
    public payment_type: string;
    public records: any = [];
    public first_record: any = {};
    public walletbalance: any;
    public total_amount: any;
    public checkout_amount: any;
    public subtotal_amount: number;
    public net_amount: any;
    public gst_amount: any;
    public payment_method: string;
    public other_charges: any;
    public other_amount: any;
    public discount_amount: any;
    public insurance_amount: any;
    public display_payment_gateway: boolean = false;
    public order_token: string;
    public order_unique_id: string;
    public BNPLPaymentStatus: any = false;
    public BNPLPaymentDetails: any = {};
    public BNPLPaymentURL: string;
    addShippingForm: FormGroup;
    public sma_shipping_id: any = '';
    public sma_shipping_details: any = {};
    public sma_billing_id: any = '';
    public sma_billing_name: any = '';
    public sma_billing_details: any = {};
    public modelType: string = '';
    stateArray: any = [];
    cityArray: any = [];
    public shippingAddressId:string;
    public billingAddressId:string;
    public gst_flag:boolean;
    public gst_number:string;
    gstForm: FormGroup;
    public billingAddress:any = {};
    public checkphonenumber: any = false;

    constructor(
        private modalService: NgbModal,
        private _checkoutservice: CheckoutService,
        private _EncrDecr: EncrDecrService,
        private _toastr: ToasterService,
        private ngZone: NgZone,
        private fb: FormBuilder,
        private _router: Router,
        private _route: ActivatedRoute,
        private _signupservice: SignupService,
        private http: HttpClient,
        private BuyerBuyNowPayLaterService: BuyerBuyNowPayLaterService,
        private _personaldetailsservice: PersonalDetailsService,
    ) { }


    ngOnInit(): void {
        this.creditCardForm = this.fb.group({
            cardHolderName: ['', [Validators.required]],
            creditCard: ['', [CreditCardValidators.validateCCNumber]],
            expirationDate: ['', [CreditCardValidators.validateExpDate]],
            cvv: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
            save_card: [false],
        });
        this.loggedin_user_token = this._signupservice.gettoken();
        this.invokeStripe();
        this.getcheckoutdetails();
        this.getuserdetails();
        this.getsavedcard();
        // this.bnplStatus();
        //window['angularComponentReference'] = { component: this, zone: this.ngZone, loadAngularFunction: () => this.jspaymentsuccess(), };
        this.addShippingForm = this.fb.group({
            address: this.fb.control(null, [Validators.required]),
            state: this.fb.control(null, [Validators.required]),
            city: this.fb.control(null, [Validators.required]),
            phone_number: this.fb.control('', [Validators.pattern('[- +()0-9]+'), Validators.maxLength(10), Validators.minLength(10)]),
            pincode: this.fb.control(null, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
            address_name: this.fb.control(null)
        });
        this.gstForm = this.fb.group({
            gst_number: this.fb.control(null)
        });
        this.getstate();
        this.getshippingdetails();
    }

    getupdatecity(state) {
        var data = {};
        data = { state: state };
        this._personaldetailsservice.getcity(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.cityArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    gstdetails() {
        let val = this.gstForm.get('gst_number').value;
        if(val.length == 15){
            let gstin = this._EncrDecr.set(this.gstForm.get('gst_number').value);
            this._checkoutservice.gstdetails({ gstin: gstin }).subscribe((data) => {
            if(data['status'] == "success") {
                var pr_address = data['gst_info']['gstinfo']['pr_address'];
                var address = '';
                if (pr_address.bno) {
                    address = pr_address.bno;
                }
                if (pr_address.bnm) {
                    address = address + ", " + pr_address.bnm;
                }
                if (pr_address.st) {
                    address = address + ", " + pr_address.st;
                }
                if (pr_address.loc) {
                    address = address + ", " + pr_address.loc;
                }
                this.billingAddress = {
                    address : address,
                    state : pr_address.stcd,
                    city : pr_address.dst,
                    pincode : pr_address.pncd
                }
            }
        });
        }
    }

    phonenumber() {
        let val = this.addShippingForm.get('phone_number').value;
        if(val.toString().length != 10){
            this.checkphonenumber = true;
        }
        else if(val.toString().length == 10){
            this.checkphonenumber = false;
        }
    }

    click_shipping_address(id) {
        this.shippingAddressId = id;
    }

    click_billing_address(id) {
        this.billingAddressId = id;
    }

    click_gst_flag() {
        this.gst_flag = !this.gst_flag;
    }

    getcity(type='add') {
        var data = {};
        if(type == 'add'){
            data = { state: this.addShippingForm.get('state').value };
        }
        
        this._personaldetailsservice.getcity(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.cityArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    addaddr(type, modelType, model, id) {
        if(modelType == 'shipping'){
        if(model == 'edit') {
            let data = this.sma_shipping_details.find(a=> a._id == id);
            this.getupdatecity(data.state);
            this.addShippingForm.patchValue({
                'address' : data.address,
                'state' : data.state,
                'city' : data.city,
                'pincode' : data.pincode,
                'phone_number' : data.phone_number,
                'address_name' : data.address_name
            });
            this.sma_shipping_id = id;
        }
        else {
            this.addShippingForm.patchValue({
                'address' : '',
                'state' : '',
                'city' : '',
                'pincode' : '',
                'phone_number': '',
                'address_name' : ''
            });
            this.sma_shipping_id = '';
        }
    }
    else{
        if(model == 'edit') {
            let data = this.sma_billing_details.find(a=> a._id == id);
            this.getupdatecity(data.state);
            this.addShippingForm.patchValue({
                'address' : data.address,
                'state' : data.state,
                'city' : data.city,
                'pincode' : data.pincode,
                'address_name' : data.address_name
            });
            this.sma_billing_id = id;
        }
        else {
            this.addShippingForm.patchValue({
                'address' : '',
                'state' : '',
                'city' : '',
                'pincode' : '',
                'address_name' : ''
            });
            this.sma_billing_id = '';
            this.sma_billing_name = '';
        }
    }
        this.modelType = modelType;
        this.adrsmdl(type);
    }
    adrsmdl(newadrs) {
        this.modalService.open(newadrs, { windowClass: 'lgmodal mblbtm' });
    }

    getshippingdetails() {
        this._personaldetailsservice.getshippingdetails(null).subscribe((data) => {
            if (data['status'] == "success") {

                this.sma_shipping_details = data['records'].filter(a=> a.type == 'shipping');
                this.sma_billing_details = data['records'].filter(a=> a.type == 'billing');
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    saveshippingform() {
        var data = {
            shipping_id: this.sma_shipping_id && this.modelType == 'shipping' ? this.sma_shipping_id : '',
            billing_id: this.sma_billing_id && this.modelType == 'billing' ? this.sma_billing_id : '',
            type: this.modelType,
            address: this.addShippingForm.get('address').value,
            state: this.addShippingForm.get('state').value,
            city: this.addShippingForm.get('city').value,
            pincode: this.addShippingForm.get('pincode').value,
            phone_number: this.addShippingForm.get('phone_number').value,
            address_name: this.addShippingForm.get('address_name').value,
        };
        this._personaldetailsservice.addshippingaddress(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this._toastr.success('New shipping/billing address has been added successfully.');
                this.getshippingdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getstate() {
        this._personaldetailsservice.getstate(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.stateArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    

      bnplStatus() {
        let data = {};
        this.BuyerBuyNowPayLaterService.bnplStatus(data).subscribe((data) => {
          if (data["status"] == "success"){
            this.BNPLPaymentDetails = {status: data['bnpl_status'] , accountDetails: data['accountDetails']}
            this.bnplInformation();
          } 
        });
      }

      bnplInformation() {
        let data = {};
        this.BuyerBuyNowPayLaterService.bnplInformation(data).subscribe((data) => {
          if (data["status"] == "success"){
            this.BNPLPaymentStatus = data['result']['bnpl'];
          } 
        });
      }

    get cc() { return this.creditCardForm.controls; }

    enableinsurance(type = 'no') {
        if(type == 'no'){
            this.enable_insurance = false;
        }else{
            this.enable_insurance = true;
        }
        this.getcheckoutdetails('yes');
    }

    getcheckoutdetails(is_insurance = 'no') {
        this._checkoutservice.getcheckoutdetails({ insurance: this.enable_insurance, payment_type: this.payment_type, is_bnpl: this.enable_bnpl }).subscribe((data) => {
            if (data['status'] == "success") {
                if (is_insurance == 'yes') {
                    if (this.enable_insurance) {
                        this._toastr.success('Insurance has been added successfully.');
                    }
                    if (!this.enable_insurance) {
                        this._toastr.success('Insurance has been removed successfully.');
                    }

                }
                this.popupbox_records = data['price_breakdown'];

                // this.order_token = '';
                // this.order_unique_id = '';
                this.subtotal_amount = data['sub_total'];
                this.shipping_addresses = data['shipping_address'];
                this.records = data['records'];
                this.total_amount = data['totalamount'];
                this.minimum_amount_failed = data['minimum_amount_failed'];
                this.other_charges = data['other_charges'];
                this.other_amount = data['otheramount'];
                this.net_amount = data['netamount'];
                this.gst_amount = data['totalgst'];
                this.insurance_amount = data['insurance_amount'];
                this.discount_amount = data['discount_amount'];
                this.checkout_amount = data['checkout_amount'];
                if (this.records.length > 0) {
                    this.first_record = this.records[0];
                }
                this.modalService.dismissAll();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    cardsubmit(type) {
        this.submitted = true;
        var request_data = {};
        if (type == 'new') {
            request_data = {
                type: type,
                order_token: this.order_token,
                order_unique_id: this._EncrDecr.set(this.order_unique_id),
                card_holder: this._EncrDecr.set(this.creditCardForm.get('cardHolderName').value),
                card_number: this._EncrDecr.set(this.creditCardForm.get('creditCard').value),
                card_expiry: this._EncrDecr.set(this.creditCardForm.get('expirationDate').value),
                card_cvv: this._EncrDecr.set(this.creditCardForm.get('cvv').value),
                save_card: this.creditCardForm.get('save_card').value,
            };
        } else {
            request_data = {
                type: type,
                card_id: this.selectedCard.id,
                order_token: this.order_token,
                order_unique_id: this._EncrDecr.set(this.order_unique_id),
                card_cvv: this._EncrDecr.set(this.selectedCard.cvv),
            };
        }

        this._checkoutservice.submitcard(request_data).subscribe((data) => {
            if (data['status'] == "success") {
                let redirect_url = data['redirect_url'];
                window.location.href = redirect_url;
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    submitsavedcard(card) {
        this.selectedCard = card;
        this.cardsubmit('saved');
    }

    getuserdetails() {
        this._checkoutservice.getuserdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.walletbalance = data['data']['walletbalance'];
            }
        }, (error) => {
        });
    }

    updatedetails(type, id, value) {
        this._checkoutservice.updatedetails({ type: type, id: id, value: value }).subscribe((data) => {
            if (data['status'] == "success") {
                this.getcheckoutdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changeaddress(record) {
        record.change_address = true;
    }

    updateaddress(record) {
        record.change_address = false;
        this.updatedetails('address', record.id, record.selected_address);
    }

    changedelivery(record) {
        record.change_logistics = true;
    }

    updatedelivery(record) {
        record.change_logistics = false;
        this.updatedetails('logistics', record.id, record.logistics);
    }

    changequantity(record, type) {

        if (type == 'minus') {
            var qty = parseInt(record.qty) - 1;
            if (qty < 1) {
                qty = 1;
            } else {
                qty = qty;
            }
        } else if (type == 'plus') {
            qty = record.qty + 1;
        } else {
            var mqty = parseInt(record.qty);
            if (mqty < 1) {
                qty = 1;
            } else {
                qty = mqty;
            }
        }
        // if (type == 'minus') {
        //     var qty = record.qty - 1;
        //     if (qty < record.min_qty) {
        //         record.qty = record.min_qty;
        //     } else {
        //         record.qty = qty;
        //     }
        // } else if (type == 'plus') {
        //     record.qty = record.qty + 1;
        // } else {
        //     var mqty = record.qty;
        //     if (mqty < record.min_qty) {
        //         record.qty = record.min_qty;
        //     } else {
        //         record.qty = mqty;
        //     }
        // }
        this.updatedetails('quantity', record.id, qty);
    }

    open(content) {

        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    openbreakup(content) {

        this.modalService.open(content, { windowClass: 'viewAllProducts', ariaLabelledBy: 'modal-basic-title', size: 'md', centered: true, }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    openinsurance(contentinsurance, type = 'button') {
        if (this.enable_insurance && type == 'button') {
            this.enableinsurance('no');
        } else {
            this.modalService.open(contentinsurance, { ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, }).result.then((result) => {
                this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }

    }


    openWindowCustomClass(content) {
        this.modalService.open(content, { windowClass: 'viewAllProducts', size: 'lg', centered: true, });
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    walletpayment() {
        this._checkoutservice.walletpayment({ order_id: this.order_unique_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this._router.navigate(['/buyer/payment/success'])
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    bnplPayment() {
        if(this.BNPLPaymentURL) location.replace(this.BNPLPaymentURL);
    }

    async enablebnpl() {
        this.enable_bnpl = true;
        await this.getcheckoutdetails();
        this.placeorder('bnpl');
    }

    placeorder(type, pgtype='') {
        let gst_number = (this.gstForm.get('gst_number').value);
        
        if(!this.shippingAddressId){
            this._toastr.error('Please select shipping address.');
            return false;
        }

        if (!this.gst_flag) {
            if (!this.billingAddressId) {
                this._toastr.error('Please select billing address.');
                return false;
            }
        }

        if(this.minimum_amount_failed){
            this._toastr.error('Minimum deal amount should be Rs.50 to proceed.');
            return false;
        }

        if(type != 'bnpl') {
            this.enable_bnpl = false;
            this.getcheckoutdetails();
        }
        this.payment_type = '';
        this.pg_type = pgtype;
        let pg_type = '';
        if(this.pg_type){
            pg_type = this._EncrDecr.set(this.pg_type);
        }
        this._checkoutservice.placeorder({ insurance: this.enable_insurance, type: this._EncrDecr.set(type), pg_type:pg_type, shippingAddressId : this.shippingAddressId, billingAddressId: this.billingAddressId, gst_number: gst_number, gst_flag : this.gst_flag, billingAddress: this.billingAddress }).subscribe((data) => {
            if (data['status'] == "success") {
                if (type == 'wallet' || type == 'bnpl') {
                    this.wallet_button = true;
                } else {
                    this.wallet_button = false;
                }
                this.order_token = data['order_token'];
                this.order_unique_id = data['order_unique_id'];
                this.BNPLPaymentURL = data['payment_url']
                if(this.pg_type == 'payu'){
                    const product = data['pay_details'];
                    console.log(product);
                    const paymentDetails = {
                        payu_url: product.payu_url,
                        first_name: product.first_name, 
                        email: product.email,
                        mobile: product.mobile, 
                        callback_url: product.call_back_url, 
                        payu_cancel_url: product.payu_cancel_url, 
                        payu_fail_url: product.payu_fail_url, 
                        payu_merchant_key: product.payu_merchant_key, 
                        payu_sha_token: product.payu_sha_token, 
                        txnid: product.txnId, 
                        plan_name: product.plan_name, 
                        amount: product.amount, 
                        service_provider: product.service_provider
                    };

                    console.log(paymentDetails);
                    let paymentString = `
                    <html>
                        <body>
                        <form action="${paymentDetails.payu_url}" method="post" id="payu_form">
                            <input type="hidden" name="firstname" value="${paymentDetails.first_name}"/>
                            <input type="hidden" name="email" value="${paymentDetails.email}"/>
                            <input type="hidden" name="phone" value="${paymentDetails.mobile}"/>
                            <input type="hidden" name="surl" value="${paymentDetails.callback_url}"/>
                            <input type="hidden" name="curl" value="${paymentDetails.payu_cancel_url}"/>
                            <input type="hidden" name="furl" value="${paymentDetails.payu_fail_url}"/>
                            <input type="hidden" name="key" value="${paymentDetails.payu_merchant_key}"/>
                            <input type="hidden" name="hash" value="${paymentDetails.payu_sha_token}"/>
                            <input type="hidden" name="txnid" value="${paymentDetails.txnid}"/>
                            <input type="hidden" name="productinfo" value="${paymentDetails.plan_name}"/>
                            <input type="hidden" name="amount" value="${paymentDetails.amount}"/>
                            <input type="hidden" name="service_provider" value="${paymentDetails.service_provider}"/>
                            <button type="submit" value="submit" #submitBtn></button>
                        </form>
                        <script type="text/javascript">document.getElementById("payu_form").submit();</script>
                        </body>
                    </html>`;

                    const winUrl = URL.createObjectURL(
                        new Blob([paymentString], { type: "text/html" })
                    );
                
                    window.location.href = winUrl;
                }


            } else if (data['status'] == "error") {
                this.payment_method = '';
                this._toastr.error(data['message']);
            }
        });
    }


    //@HostListener('window:payment.success', ['$event']) 
    function(response) {
        var event = new CustomEvent("payment.success",
            {
                detail: response,
                bubbles: true,
                cancelable: true
            }
        );
        window.dispatchEvent(event);
    }


    makepayment(type) {
        this.payment_type = type;
        var insurance = this.enable_insurance?'yes':'no';
        // if (this.payment_type == 'card') {
        //     this.getcheckoutdetails(insurance);
        //     var main = document.getElementById('dropincontainerMainDiv');
        //     main.style.display = main.style.display === '' ? 'none' : 'none';
        // }
        this.display_payment_gateway = false;
        if (type == 'upi' || type == 'netbanking' || type == 'card') {
            this.getcheckoutdetails(insurance);
            this.display_payment_gateway = true;
            var main = document.getElementById('dropincontainerMainDiv');
            main.style.display = main.style.display === 'none' ? '' : '';
            var parent = document.getElementById('drop_in_container');
            setTimeout(() => {
                parent.innerHTML = '';
                let cashfree = new (<any>window).Cashfree();
                cashfree.initialiseDropin(parent, {
                    orderToken: this.order_token,
                    onSuccess: function (data) {
                        var order_id = data.order.orderId;
                        var transaction_amount = data.transaction.transactionAmount;
                        var transaction_id = data.transaction.transactionId;
                        var http = new XMLHttpRequest();
                        var url = environment.api_url + "sma/buyer/cf/confirmorder";
                        var params = 'type=' + type + '&order_id=' + order_id + '&transaction_amount=' + transaction_amount + '&transaction_id=' + transaction_id;
                        http.open('POST', url, true);

                        //Send the proper header information along with the request
                        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
                        http.setRequestHeader("Accept", "application/json");
                        http.setRequestHeader("Authorization", 'Bearer ' + localStorage.getItem('loggedInUserToken'));
                        http.onreadystatechange = function () {
                            //Call a function when the state changes.
                            if (http.readyState == 4 && http.status == 200) {
                                let response = JSON.parse(http.responseText);
                                if (response.status == 'success') {
                                    window.location.href = '/buyer/payment/success'
                                } else {
                                    window.location.href = '/buyer/payment/failed';
                                }
                            }
                        }
                        http.send(params);
                    },
                    onFailure: function (data) {
                        if (data.order.status == 'ERROR') {
                            window.location.href = '/buyer/payment/failed';
                        } else {
                            if (data.transaction.txStatus == 'FAILED') {
                                window.location.href = '/buyer/payment/failed';
                            }
                        }
                    },
                    components: [type], //'order-details', //'card', 'upi', 'app', 'netbanking'
                    style: {
                        backgroundColor: '#ffffff',
                        color: '#FF7135',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        errorColor: '#ff0000',
                        theme: 'light', //(or dark)
                    },
                });
            }, 3000)
        }
    }

    openpaymentdiv(type) {
        this.display_payment_gateway = true;
        this.makepayment(type);
    }

    getsavedcard() {
        this._checkoutservice.savedcards(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.saved_cards_records = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }


    invokeStripe() {
        if (!window.document.getElementById('stripe-script')) {
            const script = window.document.createElement('script');
            script.id = 'stripe-script';
            script.type = 'text/javascript';
            script.src = environment.cashfree_sandbox;
            script.onload = () => { };
            window.document.body.appendChild(script);
        }
    }

}
