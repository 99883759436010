import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BuyerTransactionHistoryService {

    constructor(private _http: HttpClient) { }

    gettransactiondetails(data) {
        return this._http.post(environment.api_url + 'sma/buyer/transaction/history', data);
    }

}
