<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="">
    <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row no-gutters align-items-center justify-content-between">
             <div class="col-md-3">
             	<a [routerLink]="['/home']"><img src="assets/images/spice-logo.png"></a>
                
             </div>
             <div class="col-md-6">
                <div class="input-group-custom">
					<div class="ng-autocomplete">
						<ng-autocomplete 
							[data]="result" 
							[searchKeyword]="keyword" 
							(selected)='selectEvent($event)'
							(inputChanged)='onChangeSearch($event)' 
							(inputFocused)='onFocused($event)' 
							[itemTemplate]="itemTemplate"
							placeholder="Search Products, Brands and Categories"
							(keydown.enter)="submitsearch($event)"
							 >
						</ng-autocomplete>
						<ng-template #itemTemplate let-item>
							<a href="javascript:void(0)">
								<div class="d-flex">
									<span [innerHTML]="item.name"></span>
									<span class="current text-right"> in {{item.parent}}</span>
								</div>
							</a>
						</ng-template>
						
						<div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
					</div>
                   <!-- <input class="form-control py-2 border-right-0 border" type="text" placeholder="Search Products, Brands and Categories" id="example-search-input">
                   <div class="input-group-append-custom">
                      <img src="assets/images/svg/search_logo.svg">
                   </div> -->
                </div>
            </div>
			<div class="col-md-3 mblsignup">
				<div class="text-right" *ngIf="!token">
					<button type="button" class="btn btn-custom" (click)="SignIn.openSigninModal('home')">Login/Join Free</button>
				</div>
				<!-- Post Login-->
				<div class="text-right d-flex align-items-center justify-content-end" *ngIf="token">
					<a href="javascript:;" class="mr-5" [routerLink]="['/cart']">
						<div class="cart">
							<img src="assets/images/icons/cart.svg">
							<div class="cart-count"><span>{{ this.cart_count }}</span></div>
						</div>
					</a>
					<a class="mr-5" href="javascript:void(0);">
						<div class="header-notifications">
                            <ul class="header-dropdown">
                                 <li class="onhover-dropdown mobile-account">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.1" height="19.463" viewBox="0 0 20.1 19.463">
                                       <g id="Group_98" data-name="Group 98" transform="translate(-1331.4 -25.5)">
                                          <g id="np_bell_4209940_000000" transform="translate(1313.9 8.363)" opacity="0.6">
                                             <path id="Path_2300" data-name="Path 2300" d="M44.794,76.5H40.215a.715.715,0,1,0,0,1.431h4.579a.715.715,0,1,0,0-1.431Z" transform="translate(-15.705 -41.331)" fill="#5e6a7c"/>
                                             <path id="Path_2301" data-name="Path 2301" d="M35.385,32.479H34.3V26.1a7.5,7.5,0,1,0-14.994,0v6.382H18.215a.715.715,0,0,0,0,1.431h17.17a.715.715,0,1,0,0-1.431Z" transform="translate(0 0)" fill="#5e6a7c"/>
                                          </g>
                                          <circle id="Ellipse_6" data-name="Ellipse 6" cx="4" cy="4" r="4" transform="translate(1343 26)" fill="#e11f23" stroke="#fff" stroke-width="1"/>
                                       </g>
                                    </svg>
                                    <span class="header-notifications-container">
                                    <ul class="onhover-show-div">
                                       <li class="header"><div class="fs14 f-700 text-dark">Notification</div></li>
                                       <li>No Notifications Found</li>
                                       <!-- <li>
                                          <div class="new-one"></div>
                                          <div class="fs14 text-dark">
                                            Book now to get 10% Off. It is an Excellent Deal
                                          </div>
                                          <div class="fs13">2 min ago</div>
                                        </li>
                                        <li>
                                          <div class="new-one"></div>
                                          <div class="fs14 text-dark">
                                            Xiaomi has launched a new product. Xiaomi has launched a new product.
                                          </div>
                                          <div class="fs13">15 mins ago</div>
                                        </li>
                                        <li>
                                            <div class="fs14 text-dark">
                                              Pre-book amount Rs. 7,999 is to be paid.
                                            </div>
                                            <div class="fs13">Yesterday, 12:54 pm</div>
                                        </li>
                                        <li class="footer text-center">
                                            <a href="javascript:;" class="link-alt fs14">View all notifications</a>
                                        </li> -->
                                    </ul>
                                    </span>
                                 </li>
                            </ul>
                        </div>
					</a>
					<div class="profile" [routerLink]="['/buyer/dashboard']" *ngIf="usertype == 1 && user_details && user_token && user_reg_status == 'yes' && user_status == 'yes'">
						<div class="icon"><img src="assets/images/product/as-avt.jpg"></div>
						<div>Hi {{user_details.name}}</div>
					</div>

					<div class="profile" *ngIf="usertype == 1 && user_details && user_token && ( user_reg_status == 'no' || user_status == 'no')">
						<div class="icon"><img src="assets/images/product/as-avt.jpg"></div>
						<div>Hi {{user_details.name}}</div>
					</div>
				</div>
				<!--End Post Login-->
			</div>
          </div>
        </div>
      </div>

      	<div class="divider"></div>
		<div class="loc-head-mn">
		  <div class="container">
			<div class="row no-gutters align-items-center">
			  <div class="col-md-4">
				<ul class="header-dropdown">
				  <li class="onhover-dropdown mobile-account menu-item topbar-menu hideinmbl" *ngIf="user_token && menu_categories.length > 0">
					<img src="assets/images/svg/location_gray.svg"> Favourite Categories
					<ul class="onhover-show-div w-3">
						<li *ngFor="let rec of menu_categories"><a herf="javascript:;" [routerLink]="['/category/'+rec.slug]">{{ rec.name }}</a></li>
					</ul>
				  </li> 
				</ul>
			  </div>
			  <div class="col-md-8 text-right right-header">
				<ul class="header-dropdown">
				  	<!-- <li class="onhover-dropdown mobile-account menu-item topbar-menu">
						Equibiz Exclusive
						<ul class="onhover-show-div w-2">
							<li><div class="header-title">Equibiz Exclusive</div></li>
							<li><a href="javascript:;">Latest Gadgets on Equibiz</a></li>
							<li><a href="javascript:;">News and Reviews</a></li>
						</ul>
				  	</li> -->
				  	<!-- <li class="menu-item"><a href="javascript:;">EB Market Pulse</a></li> -->
				  	<li class="onhover-dropdown mobile-account menu-item topbar-menu">
						Assistance
						<ul class="onhover-show-div w-3">
							<li *ngFor="let ass of assistance_records"><a herf="javascript:;" [routerLink]="['/buyer/more-articles/'+ass.id]">{{ ass.title }}</a></li>
							<li><a href="javascript:;" [routerLink]="['/buyer/assistance']" class="btn btn-custom">Help Center</a></li>
						</ul>
				  	</li>
				  	<!--Post Login-->
					  <li class="mobile-account menu-item" *ngIf="token">
						<div class="d-flex align-items-end" [routerLink]="['/buyer/dashboard']">
							<div>EB Dashboard</div>	
						</div>		
					</li>
				  	<!-- <li class="mobile-account menu-item" *ngIf="token">
				  		<div class="d-flex align-items-end">
					  		<div><img src="assets/images/icons/wallet-icon.svg"></div> &nbsp;
					  		<div>{{wallet_amount}}</div>	
				  		</div>		
				  	</li> -->
					<li class="menu-item" *ngIf="token"><a href="javascript:;" (click)="logout()"><svg class="me-4" xmlns="http://www.w3.org/2000/svg" width="15.231" height="20.444" viewBox="0 0 15.231 20.444">
						<g id="np_log-out_738609_000000" opacity="0.8">
						<path id="Path_2333" data-name="Path 2333" d="M12.929.319q2.968.749,5.938,1.487c.174.043.18.147.18.287q.006,3.19.016,6.38.008,4.62.017,9.239c0,.185-.041.276-.225.34q-3.349,1.154-6.691,2.326c-.061.021-.123.035-.213.061-.008-.149-.02-.28-.021-.409q-.031-3.31-.062-6.619-.057-5.619-.117-11.238C11.744,1.447,11.73.722,11.719,0h.039c.388.108.778.224,1.171.323Zm-.353,10.475a.4.4,0,0,0,.64-.043.832.832,0,0,0,.1-.863.507.507,0,0,0-.493-.348.976.976,0,0,0-.245.147.862.862,0,0,0,0,1.107Z" transform="translate(-3.849 0.004)" fill="#5e6a7c"/>
						<path id="Path_2334" data-name="Path 2334" d="M54.971,7.508c-.173,0-.235.042-.233.232.009,1.213,0,2.426,0,3.64v.41c-.311,0-.626,0-.932-.009-.03,0-.078-.123-.078-.189-.006-.753,0-1.506,0-2.26V6.693c0-.12,0-.214.169-.212,1.18.006,2.361,0,3.542,0h0c.033,0,.067,0,.1.01V7.512H57.3c-.774,0-1.551,0-2.331,0Z" transform="translate(-50.661 -5.155)" fill="#5e6a7c"/>
						<path id="Path_2335" data-name="Path 2335" d="M53.136,67.577c.263.011.533,0,.805,0v3.631H56.9V72.26c-.078,0-.119.01-.176.01-1.192,0-2.384,0-3.575,0-.176,0-.217-.055-.216-.225q.008-2.129,0-4.257c0-.166.047-.22.207-.216Z" transform="translate(-49.863 -53.76)" fill="#5e6a7c"/>
						<path id="Path_2336" data-name="Path 2336" d="M57.468,39.009a.637.637,0,0,0,.272.394q.889.679,1.772,1.366a.468.468,0,0,0,.73-.156h0a.67.67,0,0,0,.05-.233c.008-.15,0-.3,0-.462a3.187,3.187,0,0,1,1.963.668,3.368,3.368,0,0,1,1.191,1.736,3.364,3.364,0,0,0-3.155-4.347V37.5h0a.477.477,0,0,0-.171-.378.461.461,0,0,0-.6,0c-.23.176-.459.354-.688.53-.363.28-.724.562-1.089.839a.627.627,0,0,0-.272.394v.12Z" transform="translate(-57.468 -29.444)" fill="#5e6a7c"/>
						</g>
					</svg> &nbsp;Logout</a></li>
				  	<!--End Post Login-->
				</ul>
			  </div>
			</div>
		  </div>
	  	</div>

    </div>
    <div class="kyc-alert" *ngIf="user_token && (user_reg_status == 'no' || user_status == 'no')">
    	<div class="icon"><img src="assets/images/icons/alert-bell.png"></div>
    	<div class="info" *ngIf="user_reg_status == 'no'">Please complete your registration to get onboarded into our portal. 
			<a href="javascript:;" [routerLink]="['/registration/buyer/details']">Go To Registration</a>

		</div>
    	<div class="info" *ngIf="user_reg_status == 'yes' && user_status == 'no'">Your account is pending for approval from admin.</div>
    </div>
  </div>
</header>

<app-signup-modal #signIn></app-signup-modal>
