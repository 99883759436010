import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class BuyerTransportationDocumentsService {

    constructor(private _http: HttpClient) { }

    documentrecords(data) {
        return this._http.post(environment.api_url + 'sma/buyer/report/mytransportation', data);
    }

}