import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { SignupModalComponent } from '../../components/modal/signup-modal/signup-modal.component';
import { SignupService } from '../../components/modal/signup-modal/signup-modal.service';
import { FooterOneService } from './footer-one.service';

@Component({
  selector: 'app-footer-one',
  templateUrl: './footer-one.component.html',
  styleUrls: ['./footer-one.component.scss']
})
export class FooterOneComponent implements OnInit {

    @ViewChild("signIn") SignIn: SignupModalComponent;

    subscribeForm: FormGroup;
    public buttonLoading:boolean = false;
    @Input() class: string = 'footer-light' // Default class 
    @Input() themeLogo: string = 'assets/images/icon/logo.png' // Default Logo
    @Input() newsletter: boolean = true; // Default True

    public today: number = Date.now();
    disabled = false;
    public categories: any = [];
    public user_token:string = '';
    public user_reg_status:string = '';
    public user_details: any;

    public assistance_records:any = [];
    constructor(
        private fb: FormBuilder,
        private _footerservice: FooterOneService,
        private _toastr: ToasterService,
        private _signupservice: SignupService,
        private _router: Router,
        private _route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.user_token = this._signupservice.gettoken();
        this.user_details = this._signupservice.getuserdetails();
        this.user_reg_status = this._signupservice.getuserregstatus();

        this._signupservice.tokenChanged.subscribe((response: string) => {
            this.user_token = response;
        });

        this._signupservice.userLoggedinUserDetails.subscribe((response: string) => {
            this.user_details = response;
        });

        this._signupservice.userRegStatusChanged.subscribe((response: string) => {
            this.user_reg_status = response;
        });        
        this.getcategories();
        this.subscribeForm = this.fb.group({
            subscribe_email: this.fb.control(null, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')])
        });

        //Assitance Details
        this.assistance_records = this._signupservice.getbuyerassistance();
        this._signupservice.buyerAssistanceChanged.subscribe((response: string) => {
            this.assistance_records = response;
        });
    }


    getcategories() {
        this._footerservice.getcategories(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.categories = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        }, (error) => {

        });
    }

    openCurrentOrder(cat) {
        cat.mouseover = true;
    }

    closeCurrentOrder(item) {
        item.mouseover = false;
    }

    get sf() { return this.subscribeForm.controls; }

    subscribeemail(){
        this.buttonLoading = true;
        this._footerservice.subscribeemail({type:'email', value: this.subscribeForm.get('subscribe_email').value}).subscribe((data) => {
            this.buttonLoading = false;
            if(data['status'] == "success"){
                this.subscribeForm.reset();
                this._toastr.success('Your email has been subscribed successfully.');
            }else if(data['status'] == "error"){
                this._toastr.error(data['message']);
            }else if(data['status'] == 'validation'){
                this._toastr.validation(data['errors'])
            }
        },(error) => {
          this.buttonLoading = false;
        });
    }

    gotoseller(){
        this._signupservice.logout();
        this._router.navigate(['/seller/login']);
    }

    gotologout(){
        this._signupservice.logout();
        this._router.navigate(['/home']);
    }

    gotohome(){
        this.hideall();
        this.redirectTo('/home');
    }

    marketpulse(){
        this.hideall();
    }

    hideall(){
        this.morestatus = false;
        this.catstatus = false;
        this.walletstatus = false;
        this.ordertstatus = false;
        this.profilestatus = false;
        this.invoicestatus = false;
        this.wishliststatus = false;
        this.categorystatus = false;
        this.reportstatus = false;
        this.locstatus = false;
    }

    
morestatus: boolean = false;
catstatus: boolean = false;
walletstatus: boolean = false;
ordertstatus: boolean = false;
profilestatus: boolean = false;
invoicestatus: boolean = false;
wishliststatus: boolean = false;
categorystatus: boolean = false;
reportstatus: boolean = false;
locstatus:boolean = false;
moreEvent(slug=''){
    if(this.user_token){
        // if(this.morestatus){
            this.hideall();
        // }
        // else{
            this.morestatus = true;
        // }
        if(slug){
            this.redirectTo(slug);
        }
    }else{
        this.hideall();
        this.SignIn.openSigninModal('home');
    }
    
}
walletEvent(){
    this.walletstatus = !this.walletstatus;       
}
swcatgkEvent(slug=''){
    // if(this.catstatus){
    //     this.hideall();
    // }else{
        this.catstatus = true;
    // }
    if(slug){
        this.redirectTo('/category/' + slug);
    }    
}

assistance(slug = ''){
    this.hideall();
    if(slug){
        this.redirectTo(slug);
    }
}

redirectTo(uri: string) {
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
        this._router.navigate([uri]));
}

orderEvent(){
	this.ordertstatus = !this.ordertstatus;       
}
profileEvent(){
	this.profilestatus = !this.profilestatus;       
}

wishlistEvent(){
	this.wishliststatus = !this.wishliststatus;       
}
invoiceEvent(){
    this.invoicestatus = !this.invoicestatus;       
}
categoryEvent(){
	this.categorystatus = !this.categorystatus;       
}
reportEvent(){
	this.reportstatus = !this.reportstatus;       
}
locationevent(){
	this.locstatus = !this.locstatus;
	}
}
