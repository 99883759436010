import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

import { SocialAuthService } from 'angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class SignupService {

    registration_unique_id:any;
    registration_unique_value:any;
    registration_type:string;
    registration_secret:string;
    first_name:string;
    last_name:string;

    constructor(private _http: HttpClient, private socialAuthService: SocialAuthService,) { }

        currentToken: string = localStorage.getItem('loggedInUserToken');
        currentUserType:string = localStorage.getItem('loggedInUserType');
        currentUserStatus:string = localStorage.getItem('loggedInUserStatus');
        currentUserRegStatus:string = localStorage.getItem('loggedInUserRegStatus');
        currentSellerType:string = localStorage.getItem('loggedInSellerType');
        currentLoggedinUserDetails:any = localStorage.getItem('loggedInUserDetails');
        buyerAssistance:any = JSON.parse(localStorage.getItem('buyerAssistance'));

        tokenChanged = new EventEmitter<string>();
        userTypeChanged = new EventEmitter<string>();
        userStatusChanged = new EventEmitter<string>();
        userRegStatusChanged = new EventEmitter<string>();
        sellerTypeChanged = new EventEmitter<string>();
        userLoggedinUserDetails = new EventEmitter<string>();
        buyerAssistanceChanged = new EventEmitter<string>();

        updatetoken(t, k){
            localStorage.setItem('loggedInUserToken', t);
            this.currentToken = t;
            this.tokenChanged.emit(this.currentToken);

            localStorage.setItem('loggedInUserType', k);
            this.currentUserType = k;
            this.userTypeChanged.emit(this.currentUserType);
        }

        updateloggedinuserdetails(data){
          localStorage.setItem('loggedInUserDetails', data);
          this.currentLoggedinUserDetails = data;
          this.userLoggedinUserDetails.emit(this.currentLoggedinUserDetails);
      }

        updatestatus(status){
            localStorage.setItem('loggedInUserStatus', status);
            this.currentUserStatus = status;
            this.userStatusChanged.emit(this.currentUserStatus);
        }

        updateassistance(data){
          localStorage.setItem('buyerAssistance', JSON.stringify(data));
          this.buyerAssistance = data;
          this.buyerAssistanceChanged.emit(this.buyerAssistance);
      }

        updateregstatus(status){
            localStorage.setItem('loggedInUserRegStatus', status);
            this.currentUserRegStatus = status;
            this.userRegStatusChanged.emit(this.currentUserRegStatus);
        }

        updatesellertype(status){
          localStorage.setItem('loggedInSellerType', status);
          this.currentSellerType = status;
          this.sellerTypeChanged.emit(this.currentSellerType);
      }

        logout(){
            localStorage.clear();
            this.currentToken = '';
            this.tokenChanged.emit(this.currentToken);
            this.currentUserType = '';
            this.userTypeChanged.emit(this.currentUserType);
            this.currentUserStatus = '0';
            this.userStatusChanged.emit(this.currentUserStatus);
            this.currentLoggedinUserDetails = {};
            this.userLoggedinUserDetails.emit(this.currentLoggedinUserDetails);
            this.socialAuthService.signOut();
        }

        gettoken() {
            return this.currentToken;
        }

        getusertype() {
            return this.currentUserType;
        }

        getuserstatus() {
            return this.currentUserStatus;
        }

        getbuyerassistance() {
          return this.buyerAssistance;
        }

        getuserdetails() {
          return this.currentLoggedinUserDetails;
        }

        getuserregstatus() {
            return this.currentUserRegStatus;
        }

        getsellertype() {
          return this.currentSellerType;
        }

  postverify(data) {
    return this._http.post(environment.api_url+'auth/verify',data);
  }

  otpgenerate(data) {
    return this._http.post(environment.api_url+'sma/otp/generate',data);
  }

  verifyotp(data) {
    return this._http.post(environment.api_url+'verify/otp',data);
  }

  forgotverifyotp(data) {
    return this._http.post(environment.api_url+'forgotpassword/verify/otp',data);
  }

  setpassword(data) {
    return this._http.post(environment.api_url+'set/password',data);
  }

  resetpassword(data) {
    return this._http.post(environment.api_url+'resetpassword',data);
  }

  verifypassword(data) {
    return this._http.post(environment.api_url+'verify/password',data);
  }

    login(data) {
        return this._http.post(environment.api_url+'sma/login',data);
    }

    forgotpassword(data) {
        return this._http.post(environment.api_url+'forgotpassword',data);
    }

    sociallogin(data) {
        return this._http.post(environment.api_url+'sociallogin',data);
    }
  
}
