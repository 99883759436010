import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {

    constructor(private _http: HttpClient) { }
    public viewsellerlisting:boolean = false;

    getproductdetails(data) {
        return this._http.post(environment.api_url+'sma/product/view',data);
    }

    getproductlisting(data) {
        return this._http.post(environment.api_url+'sma/product/listing',data);
    }

    addtocart(data) {
        return this._http.post(environment.api_url+'addtocart',data);
    }

    getbiddetails(data) {
        return this._http.post(environment.api_url+'product/seller/bids/details',data);
    }

    savebiddetails(data) {
        return this._http.post(environment.api_url+'product/bids/save',data);
    }

    wishlist(data) {
        return this._http.post(environment.api_url+'sma/buyer/wishlist/add',data);
    }

    moreproducts(data) {
        return this._http.post(environment.api_url+'sma/product/view/bybrand',data);
    }

    getlogisticscharges(data) {
        return this._http.post(environment.api_url + 'admin/logistic/pricing', data);
    }

    getreviews(data) {
        return this._http.post(environment.api_url + 'sma/product/rating/list', data);
    }

}
