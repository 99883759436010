<app-header-one [sticky]="true"></app-header-one>

<section class="page-section pt-20">
    <div class="container">
        <!--- HEAD  --->
        <div class="row align-items-center mb-4 pinfo-head">
            <div class="col-sm-6 col-7 pr-0">
                <nav class="breadcrumb breadcrumb-dui mb-0">
                    <ol class="breadcrumb p-0 bg-transparent  mb-0">
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:void(0);" [routerLink]="['/home']">Home</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="txtseconday" href="javascript:;" [routerLink]="['/buyer/bids-offers']">Bids and
                                Offers</a>
                        </li>
                        <li class="breadcrumb-item active">Bids Market Place</li>
                        <li id="thankyoumodal" (click)="openthankyou(content3)" style="display: none;"></li>
                    </ol>
                </nav>
            </div>
        </div>
        <!--- /HEAD  --->

        <div class="row mb-40">
            <div class="col-md-7">
                <div class="bid-order-info mb-10 d-flex align-items-center">
                    <div class="order-img">
                        <img [src]="product_detail.image" />
                    </div>
                    <div class="pd-seller-cntnt">
                        <h5 class="mb-0 fs18">{{ product_detail.name }}</h5>
                    </div>
                </div>
                <a href="javascript:;" [routerLink]="['/product/details/'+product_detail.unique_id]"
                    class="link-alt fs16">View Product</a>
            </div>
        </div>

        <div class="d-flex align-items-center mt-0 mb-20" *ngIf="last_bid_record.bid_amount">
            <img src="/assets/images/icons/np_tick.png" class="mr-2"> Your bid of INR {{ last_bid_record.bid_amount }}
            for {{ last_bid_record.product_name }} has been placed. <a href="javascript:;"
                class="link-alt fs14 ml-2">View Bid History</a>
        </div>

        <div class="row mb-40">
            <div class="col-md-4">
                <div class="bid-order-info mb-10 d-flex align-items-center">
                    <div class="ng-autocomplete">
                        <ng-autocomplete [data]="result" [searchKeyword]="keyword" (selected)='selectEvent($event)'
                            (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)'
                            [itemTemplate]="itemTemplate" placeholder="Search Category">
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item>
                            <a href="javascript:void(0)">
                                <div class="d-flex">
                                    <span [innerHTML]="item.name"></span>
                                </div>
                            </a>
                        </ng-template>
                        <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="bid-order-info mb-10 d-flex align-items-center">

                    <div class="ng-autocomplete">
                        <ng-autocomplete [data]="presult" [searchKeyword]="keyword" (selected)='pselectEvent($event)'
                            (inputChanged)='ponChangeSearch($event)' (inputFocused)='ponFocused($event)'
                            [itemTemplate]="pitemTemplate" placeholder="Search Product">
                        </ng-autocomplete>
                        <ng-template #pitemTemplate let-item>
                            <a href="javascript:void(0)">
                                <div class="d-flex">
                                    <span [innerHTML]="item.name"></span>
                                </div>
                            </a>
                        </ng-template>
                        <div class="search-icon"><img src="assets/images/svg/search_logo.svg"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="notifications-container theme-tab">
            <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title" #tabset="ngbTabset">
                <ngb-tab id="allBids">
                    <ng-template ngbTabTitle>All Bids ({{ bid_records.length }})</ng-template>
                    <ng-template ngbTabContent>
                        <div class="card cardui carduipro p-4 mb-0">
                            <div class="tab-content">
                                <div class="table-responsive">
                                    <table class="table custom-table bids-offers-table xs">
                                        <thead>
                                            <tr>
                                                <th colspan="3" class="text-center buyer"><b>Buyer</b></th>
                                                <th colspan="8" class="text-center"><b>Seller</b></th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th class="buyer">Bid Id</th>
                                                <th class="buyer">Order<br>Qty</th>
                                                <th class="buyer">Best Bid Price</th>
                                                <th>Listed<br>Price</th>
                                                <th>Min Order<br>Qty</th>
                                                <th>Total<br>Listed Qty</th>
                                                <th>Supply<br>Location</th>
                                                <th>Dispatched<br>(in Days)</th>
                                                <th width="15%">Seller Name</th>
                                                <th>Last Traded<br>Price</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <!--Empty State-->
                                            <tr *ngIf="!bid_records.length">
                                                <td colspan="11">
                                                    <div class="row pt-40 pb-40">
                                                        <div class="col-md-12 text-center">
                                                            <div class="mb-20"><img
                                                                    src="/assets/images/empty-state.png"></div>
                                                            <div class="fs16">There are no records to display!</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <!--End Empty State-->
                                            <tr *ngFor="let record of bid_records; let i = index;"
                                                [ngClass]="{'buyer-mybid': record.is_buyer == true}">
                                                <td>{{ record.bid_unique_id }}<br><span
                                                        class="badge-d-outline odbdge fs10" *ngIf="record.is_buyer">Rank
                                                        {{ record.rank }}</span></td>
                                                <td><b>{{ record.order_qty }}</b> <a href="javascript:;" class="ml-2"
                                                        (click)="openCounterOffer(content2, record, 'quantity')"
                                                        *ngIf="record.is_buyer && !record.counter_offer"><img
                                                            src="/assets/images/icons/edit.png"></a></td>
                                                <td><b>{{ record.counter_offer?record.counter_amount:record.bid_price }}</b> <a href="javascript:;" class="ml-2"
                                                        (click)="openCounterOffer(content2, record, 'price')"
                                                        *ngIf="record.is_buyer && !record.counter_offer"><img
                                                            src="/assets/images/icons/edit.png"></a></td>
                                                <td>{{ record.listed_price }}</td>
                                                <td>{{ record.min_qty }}</td>
                                                <td>{{ record.listed_qty }}</td>
                                                <td>{{ record.supply_location }}</td>
                                                <td>{{ record.dispached_in }}</td>
                                                <td>
                                                    <div class="order-info">
                                                        <div class="order-img">
                                                            <img [src]="record.seller_logo" />
                                                        </div>
                                                        <div class="pd-seller-cntnt">
                                                            <div class="txtdefault">
                                                                <a href="javascript:;" class="order-link">{{
                                                                    record.seller_name }}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="cursor" (click)="open(content, record.bid_id)"
                                                        *ngIf="record.last_traded_price != 'NA'">{{
                                                        record.last_traded_price }} <i class="arrow-down-line"></i>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex align-items-center" *ngIf="!record.is_buyer">
                                                        <a class="btn-d-outline d-inline-block place-bid"
                                                            href="javascript:void(0);"
                                                            [routerLink]="['/deal/'+record.sp_id+'/bulk']">
                                                            View Listing Details
                                                        </a>
                                                    </div>

                                                    <div class="d-flex align-items-center" *ngIf="record.can_place_bid">
                                                        <a class="btn-d-outline d-inline-block place-bid" (click)="openplaceabid(placeabid, record)" href="javascript:void(0);">
                                                            Place a Bid
                                                        </a>
                                                    </div>

                                                    <div class="d-flex align-items-center" *ngIf="record.is_buyer">
                                                        <a class="btn-d-outline d-inline-block place-bid mr-3"
                                                            [routerLink]="['/deal/'+record.sp_id+'/bulk']"
                                                            href="javascript:void(0);">
                                                            View Listing Details
                                                        </a>
                                                        <a class="btn-d-outline d-inline-block place-bid mr-3"
                                                            (click)="deletebid(record)" href="javascript:void(0);">
                                                            Cancel
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ngb-tab>
                <ngb-tab id="myBids">
                    <ng-template ngbTabTitle>My Bids</ng-template>
                    <ng-template ngbTabContent>
                        <div class="card cardui carduipro p-4 mb-20"
                            *ngFor="let record of mybid_records; let i = index;">
                            <div class="tab-content">
                                <div class="row d-flex align-items-center justify-content-between">
                                    <div class="col-12 col-md-6">
                                        <span class="fs20 f-700 mr-4">Bid ID : {{ record.bid_unique_id }}</span>
                                        <span class="status-badge danger"
                                            *ngIf="record.status == 'Declined'">Declined</span>
                                        <span class="status-badge success"
                                            *ngIf="record.status == 'Accepted'">Accepted</span> <span
                                            class="ml-3 text-active f-600" *ngIf="record.status == 'Accepted'">You saved
                                            {{ record.saved_amount }}</span>
                                        <span class="status-badge pending"
                                            *ngIf="record.status == 'Pending'">Pending</span>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="d-flex align-items-center justify-content-end text-muted md-block">
                                            <!-- <a href="javascript:;" class="link-alt fs14 mr-2"
                                                *ngIf="!record.watchlist">Add to Watchlist</a> -->
                                            <span class="mr-2"
                                                *ngIf="!record.watchlist && record.status != 'Accepted'">&nbsp;|&nbsp;</span>
                                            <a href="javascript:;" *ngIf="record.status != 'Accepted'" (click)="deletebid(record)"><img
                                                    src="/assets/images/icons/delete-icon.svg"></a>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="row mb-0">
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="fs15 f-700 mb-20">Product Info:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Order Qty:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Bidding Price:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Listed Price:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Minimum Order Qty.:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Total Listed Qty.:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Supply Location:</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Dispatched(in Days):</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark f-600">Seller Name:</div>
                                            </div>
                                            <div class="col text-center" *ngIf="record.status == 'Accepted'">
                                                <div class="text-muted f-600">Total Amount:</div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-4">
                                                <div class="d-flex align-items-center">
                                                    <div class="mr-3"><img [src]="record.product_image" class="mx-w-80"></div>
                                                    <h5 class="mb-0 fs14 wt700">{{ record.product_name }}</h5>
                                                </div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.order_qty }} <a href="javascript:;"
                                                        class="ml-1" *ngIf="record.status != 'Accepted' && !record.counter_offer"
                                                        (click)="openCounterOffer(content2, record, 'quantity')"><img
                                                            src="/assets/images/icons/edit.png"></a></div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.counter_offer?record.counter_amount:record.bid_price }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.listed_price }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.min_qty }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.listed_qty }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.supply_location }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.dispached_in }}</div>
                                            </div>
                                            <div class="col text-center">
                                                <div class="text-dark">{{ record.seller_name }}</div>
                                            </div>
                                            <div class="col text-center" *ngIf="record.status == 'Accepted'">
                                                <div class="text-dark">{{ record.total_amount }}</div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <hr>
                                        <div class="mt-20 mb-10 text-right">
                                            <a href="javascript:void(0);"
                                                class="btn px-4 buybtn fs14 place-bid radius10 mr-2"
                                                *ngIf="record.status != 'Accepted' && !record.counter_offer"
                                                (click)="openCounterOffer(content2, record, 'price')">Increase Bid
                                                Price</a>
                                            <a href="javascript:void(0);"
                                                class="btn px-4 buybtn fs14 place-bid radius10 mr-2"
                                                *ngIf="record.status != 'Accepted' && record.counter_offer"
                                                (click)="open(contentacceptcounter, record)">View Counter Offer</a>
                                            <a href="javascript:;" *ngIf="record.status != 'Accepted'"
                                                class="mr-2 btn-d-outline fs14 place-bid d-inline-block"
                                                [routerLink]="['/deal/'+record.sp_id+'/bulk']">Buy it Now</a>

                                            <a href="javascript:void(0);" [routerLink]="['/buyer/buyer-view-order-details/'+record.meta_id]"
                                                class="btn px-4 buybtn fs14 place-bid radius10 mr-2"
                                                *ngIf="record.status == 'Accepted'">View Order</a>

                                            <!-- <a href="javascript:;" class="link-alt fs14"
                                                *ngIf="record.status == 'Accepted'">Cancel Order</a> -->
                                        
                                        <span *ngIf="record.status != 'Accepted'"><a
                                                href="javascript:;" class="link-alt fs14"
                                                [routerLink]="['/buyer/bids-offers/'+record.product_id]">View Other
                                                Bids</a></span>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="card cardui carduipro p-4 mb-0">
                            <div class="tab-content">
                                <div class="table-responsive">
                                    <table class="table custom-table bids-offers-table xs">
                                        <thead>
                                            <tr>
                                                <th colspan="3" class="text-center buyer"><b>Buyer</b></th>
                                                <th colspan="8" class="text-center"><b>Seller</b></th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <th class="buyer">Bid Id</th>
                                                <th class="buyer">Order<br>Qty</th>
                                                <th class="buyer">Best Bid Price</th>
                                                <th>Listed<br>Price</th>
                                                <th>Min Order<br>Qty</th>
                                                <th>Total<br>Listed Qty</th>
                                                <th>Supply<br>Location</th>
                                                <th>Dispatched<br>(in Days)</th>
                                                <th width="15%">Seller Name</th>
                                                <th>Last Traded<br>Price</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngIf="!bid_records.length">
                                                <td colspan="11">
                                                    <div class="row pt-40 pb-40">
                                                        <div class="col-md-12 text-center">
                                                            <div class="mb-20"><img
                                                                    src="/assets/images/empty-state.png"></div>
                                                            <div class="fs16">There are no records to display!</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr *ngFor="let record of mybid_records; let i = index;"
                                                [ngClass]="{'buyer-mybid': record.is_buyer == true}">
                                                <td>{{ record.bid_unique_id }}<br><span
                                                        class="badge-d-outline odbdge fs10" *ngIf="record.is_buyer">Rank
                                                        {{ record.rank }}</span></td>
                                                <td>{{ record.order_qty }} <a href="javascript:;" class="ml-2"
                                                        (click)="openCounterOffer(content2, record, 'quantity')"
                                                        *ngIf="record.is_buyer && !record.counter_offer"><img
                                                            src="/assets/images/icons/edit.png"></a></td>
                                                <td>{{ record.counter_offer?record.counter_amount:record.bid_price }} <a href="javascript:;" class="ml-2"
                                                        (click)="openCounterOffer(content2, record, 'price')"
                                                        *ngIf="record.is_buyer && !record.counter_offer"><img
                                                            src="/assets/images/icons/edit.png"></a></td>
                                                <td>{{ record.listed_price }}</td>
                                                <td>{{ record.min_qty }}</td>
                                                <td>{{ record.listed_qty }}</td>
                                                <td>{{ record.supply_location }}</td>
                                                <td>{{ record.dispached_in }}</td>
                                                <td>
                                                    <div class="order-info">
                                                        <div class="order-img">
                                                            <img [src]="record.seller_logo" />
                                                        </div>
                                                        <div class="pd-seller-cntnt">
                                                            <div class="txtdefault">
                                                                <a href="javascript:;" class="order-link">{{
                                                                    record.seller_name }}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="cursor" (click)="open(content, record.bid_id)"
                                                        *ngIf="record.last_traded_price != 'NA'">{{
                                                        record.last_traded_price }} <i class="arrow-down-line"></i>
                                                    </div>
                                                </td>
                                                <td>
                                                    <a href="javascript:void(0);"
                                                        class="btn px-4 buybtn fs14 place-bid radius10 mr-2"
                                                        *ngIf="record.status != 'Accepted' && record.counter_offer && record.is_buyer"
                                                        (click)="viewcounteroffer(contentacceptcounter, record)">View
                                                        Counter
                                                        Offer</a>
                                                    <div class="d-flex align-items-center" *ngIf="!record.is_buyer">
                                                        <a class="btn-d-outline d-inline-block place-bid"
                                                            [routerLink]="['/deal/'+record.sp_id+'/bulk']"
                                                            href="javascript:void(0);">
                                                            View Listing Details
                                                        </a>
                                                    </div>
                                                    <div class="d-flex align-items-center" *ngIf="record.is_buyer">
                                                        <a class="btn-d-outline d-inline-block place-bid mr-3"
                                                            [routerLink]="['/deal/'+record.sp_id+'/bulk']"
                                                            href="javascript:void(0);">
                                                            View Listing Details
                                                        </a>
                                                        <a class="btn-d-outline d-inline-block place-bid mr-3"
                                                            (click)="deletebid(record)" href="javascript:void(0);">
                                                            Cancel
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div> -->
                    </ng-template>
                </ngb-tab>
            </ngb-tabset>
        </div>
    </div>
</section>

<app-footer-one></app-footer-one>

<!-- Last 10 Traded Price MODAL -->
<ng-template #content let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>
    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault text-center wt700 fs22 mb-20">Last 10 Traded Price</h4>
                </div>
                <div class="accept-bid">
                    <table class="table custom-table xs mb-0">
                        <thead>
                            <tr>
                                <th>Sr. No.</th>
                                <th>Bid Time</th>
                                <th>Order Quantity</th>
                                <th>Last Traded price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let record of last_traded_records; let k = index;">
                                <td>{{ k+1 }}</td>
                                <td>{{ record.bid_time }}</td>
                                <td>{{ record.order_qty }}</td>
                                <td>{{ record.traded_price }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /Last 10 Traded Price MODAL -->

<!-- Edit Bid Price MODAL -->
<ng-template #content2 let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>

    <div class="modal-body pb-3">
        <div class="row align-items-center mb-20">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault text-center wt700 fs22 mb-20">{{ bid_edit_type == 'price' ? 'Edit Bid Price' :
                        'Edit Order Quantity' }}</h4>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div>
                            <b>Current Minimum Bid</b><br>
                            <p class="f-700 color-orange fs16">{{ selected_bid.current_min_bid }} <a href="javascript:;"
                                    *ngIf="bid_edit_type == 'quantity'" (click)="changebidedit('price')"><img
                                        src="/assets/images/icons/edit.png"></a></p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div>
                            <b>Total Listed Quantity</b><br>
                            <p class="f-700 fs16">{{ selected_bid.listed_qty }} <a href="javascript:;"
                                    *ngIf="bid_edit_type == 'price'" (click)="changebidedit('quantity')"><img
                                        src="/assets/images/icons/edit.png"></a></p>
                        </div>
                    </div>
                </div>

                <!--Edit Bid Price-->
                <div class="row mt-30" *ngIf="bid_edit_type == 'price'">
                    <div class="col-md-10">
                        <div class="profileipt">
                            <div class="form-container mt-0 mb-0 p-eml-rt">
                                <div class="floating-label mb-10">
                                    <input type="text" class="form-control" placeholder="" [(ngModel)]="updated_price">
                                    <button class="btn fs16 btn-o-default" type="button" [disabled]="!updated_price"
                                        (click)="updatebid('price')">
                                        <span>Place a Bid</span>
                                    </button>
                                    <span class="highlight"></span>
                                    <label>Enter the New Bid Price</label>
                                </div>
                            </div>
                        </div>
                        <p class="mb-0 fs12">Please Enter {{ selected_bid.lowest_bid_amt }} or more</p>
                    </div>
                </div>

                <!--Edit Bid Quantity-->
                <div class="row mt-30" *ngIf="bid_edit_type == 'quantity'">
                    <div class="col-md-10">
                        <div class="profileipt">
                            <div class="form-container mt-0 mb-0 p-eml-rt">
                                <div class="floating-label mb-10">
                                    <input type="text" class="form-control" placeholder=""
                                        [(ngModel)]="updated_quantity">
                                    <button class="btn fs16 btn-o-default" type="button" [disabled]="!updated_quantity"
                                        (click)="updatebid('quantity')">
                                        <span>Save Changes</span>
                                    </button>
                                    <span class="highlight"></span>
                                    <label>Enter the New Order Quantity</label>
                                </div>
                            </div>
                        </div>
                        <p class="mb-0 fs12">Please Enter {{ selected_bid.min_qty }} or more</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="text-left fs12">
                    By Placing a bid, you are agreeing to buy this item if your bid is accepted and will be required to
                    pay withing 4 days. The seller has 2 hours to respond.

                    <div><a href="javascript:;" class="link-alt fs14" (click)="showhistory()">{{ bid_view_history ?
                            'Hide Bid History' : 'View Bid History' }}</a></div>

                    <div class="accept-bid" *ngIf="bid_view_history">
                        <table class="table custom-table xs mb-0">
                            <thead>
                                <tr>
                                    <th>Bid Status</th>
                                    <th>Bid Time</th>
                                    <th>Order Quantity</th>
                                    <th>Bidding Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let history of bid_history_records; let k = index;">
                                    <td>{{ history.status }}</td>
                                    <td>{{ history.bid_time }}</td>
                                    <td>{{ history.order_qty }}</td>
                                    <td>{{ history.bidding_amount }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /Edit Bid Price MODAL -->

<!-- ACCEPT COUNTER OFFER MODAL -->
<ng-template #contentacceptcounter let-modal>
    <div class="text-right pt-3 pr-3 od-mdl-tl">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
    </div>
    <div class="modal-body pb-3">
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead">
                    <h4 class="txtdefault text-center wt700 fs22 mb-20">Accept Counter Offer</h4>
                </div>

                <div class="accept-bid">
                    <table class="table custom-table xs mb-0">
                        <thead>
                            <tr>
                                <th>Bid Id</th>
                                <th>Order Qty</th>
                                <th>Bid Price</th>
                                <th>Counter Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{{ selected_bid.bid_unique_id }}</td>
                                <td>{{ selected_bid.order_qty }}</td>
                                <td>{{ selected_bid.bid_price }}</td>
                                <td>{{ selected_bid.counter_amount }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="text-left mt-30">
                    <div class="text-muted mb-10">Are you sure you want to accept this Counter Offer?</div>
                    <div class="d-flex align-items-center">
                        <a href="javascript:void(0);" class="btn-d-outline-success ripple d-inline-block place-bid2"
                            (click)="acceptcounteroffer()">
                            Accept Offer
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row align-items-center mb-30">
            <div class="col-md-12">
                <div class="rtrqsthead text-center">
                    <h4 class="txtdefault wt700 fs16 mb-10">Net Payable Amount</h4>
                </div>

                <div class="inrcard p-3 radius10 position-relative">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">Net Goods Value:</div>
                                <div class="col-md-6 card-price-info text-right">{{ selected_bid.breakdown.without_gst
                                    }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">GST:</div>
                                <div class="col-md-6 card-price-info text-right">{{ selected_bid.breakdown.gst_amount }}
                                </div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">Convenience Charges:</div>
                                <div class="col-md-6 card-price-info text-right">{{ selected_bid.breakdown.convience_fee
                                    }}</div>
                            </div>
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info">Logistics Charges:</div>
                                <div class="col-md-6 card-price-info text-right">{{ selected_bid.breakdown.logistics_fee
                                    }}</div>
                            </div>
                            <hr class="mt-1 mb-1">
                            <div class="row d-flex justify-content-between">
                                <div class="col-md-6 card-price-info f-700">Total Order Value:</div>
                                <div class="col-md-6 card-price-info text-right f-700">{{
                                    selected_bid.breakdown.net_realization }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- /ACCEPT BID MODAL -->

<!-- Thank you -->
<ng-template #content3 let-modal>
    <div class="modal-body mdlcmnui pt-4 mt-3">
        <h4 class="txtdefault text-center wt700 fs22 mb-0">Thank you for placing the Bid!</h4>
        <div class="modal-subtitle text-center pt-10 pb-20">We’ve sent the Bid Price to {{ last_bid_record.seller_name
            }}</div>
        <div class="bid-order-info">
            <div class="order-img">
                <img src="assets/images/icons/info.png" />
            </div>
            <div class="pd-seller-cntnt">
                <div class="fs12">Please allow 2 hours for a response from the seller.</div>
                <div class="text-muted fs12">- Don’t want to wait? You can still buy the product now.</div>
            </div>
        </div>

        <div class="row mt-10">
            <div class="col-md-6">
                <div>
                    <b>Current Minimum Bid</b><br>
                    <p class="f-700 color-orange fs16">{{ last_bid_record.current_min_bid }}</p>
                </div>
            </div>
            <div class="col-md-6">
                <div>
                    <b>Shipping</b><br>
                    <p class="f-700 fs16">{{ last_bid_record.shipping_cost }}</p>
                </div>
            </div>
        </div>

        <div class="mt-0 mb-30"><a href="javascript:;" class="link-alt fs14" (click)="gotodeal()">Buy Now as {{
                last_bid_record.listed_price }}</a></div>

        <div class="mt-30">Changed your mind? <a href="javascript:;" class="link-alt fs12">You can retract your
                offer</a></div>
        <div class="mt-0"><a href="javascript:;" class="link-alt fs12">Feedback on Bids & Offers</a></div>
        <!-- <div class="row mt-30">
			<div class="col-md-12">
				<div class="text-left fs12">

					<div><a href="javascript:;" class="link-alt fs14">View Bid History</a></div>

					<div class="accept-bid" style="display: none;">
						<table class="table custom-table xs mb-0">
							<thead>
								<tr>
									<th>Bid Status</th>
									<th>Bid Time</th>
									<th>Order Quantity</th>
									<th>Bidding Price</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Declined</td>
									<td>27/08/2021, 12:54 pm</td>
									<td>100</td>
									<td>7,999</td>
								</tr>
								<tr>
									<td>Declined</td>
									<td>27/08/2021, 12:54 pm</td>
									<td>100</td>
									<td>7,999</td>
								</tr>
								<tr>
									<td>Declined</td>
									<td>27/08/2021, 12:54 pm</td>
									<td>100</td>
									<td>7,999</td>
								</tr>
							</tbody>
						</table>	
					</div>
				</div>
			</div>
		</div> -->
    </div>
</ng-template>
<!-- Thank you -->

<!-- PLACE BID MODAL -->
<ng-template #placeabid let-modal>
    <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-0">
        <div class="modal-scrollbar">
            <div class="pd-seller mb-3 mt-2">
                <div class="pd-seller-avt">
                    <img [src]="selected_bid.logo?selected_bid.logo:'assets/images/products/seller.jpg'" />
                </div>
                <div class="pd-seller-cntnt">
                    <div class="txtdefault mb-2 mdl-pd-sellername">
                        <span class="seller-name-tk">{{ selected_bid.seller_name}} <i placement="bottom" ngbTooltip="You are placing your bid against this Seller"> <img src="/assets/images/icons/info.png"></i></span>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-md-7">
                    <div class="mdl-crd">
                        <div class="row mb-3">
                            <p class="col-md-7 mb-0 fs14 txtseconday">
                                Min Order Qty:
                            </p>
                            <label class="col-md-5 mb-0 text-right fs14 txtdefault">
                                {{ selected_bid.min_order_qty }}
                            </label>
                        </div>
                        <div class="row mb-3">
                            <p class="col-md-7 fs14 mb-0  txtseconday">
                                Price/Unit:
                            </p>
                            <label class="col-md-5 fs14 mb-0 text-right txtdefault">
                                {{ selected_bid.price }}
                            </label>
                        </div>
                        <div class="row">
                            <p class="col-md-7 fs14 mb-0  txtseconday">
                                Supply Location:
                            </p>
                            <label class="col-md-5 fs14 mb-0 text-right txtdefault">
                                {{ selected_bid.supply_location }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="pl-md-2 vallbid">
                        <label class="f-600 pr-3 pt-2">Highest Bid by the Buyer</label>
                        <h4 class="f-700 fs20 txtactive">{{ selected_bid.current_min_bid }}</h4>
                        <a class="mr-3 fs16 txtactive warm-link" href="javascript:void(0);"
                            [routerLink]="['/buyer/bids-offers/'+selected_product_id]" target="_blank">View all {{
                            selected_bid.current_bid_count?selected_bid.current_bid_count:'' }} Bids</a>
                        <span class="d-inline-block fs14 txtseconday"><img class="mr-1" src='assets/images/svg/eye.svg'> {{
                            selected_bid.watching_bid }} Buyers watching this Bid</span>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="input-group plcbidinpt mb-0">
                        <div class="row">
                            <div class="col-md-6">
                                <input type="number" class="form-control radius10 txtseconday"
                                    placeholder="Enter the Bid Quantity" [(ngModel)]="bid_quantity"
                                    min="{{ selected_bid.min_order_qty }}">
                                <p class="fs12 txtdefault mb-1">Please Enter {{ selected_bid.min_order_qty}} or more</p>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control radius10 txtseconday"
                                    placeholder="Enter the Bid Amount" [(ngModel)]="selected_bid.bid_amount">
                                <p class="fs12 txtdefault mb-1">Please Enter {{ selected_bid.lowest_bid_amt}} or more</p>
                            </div>
                        </div>
                    </div>
                    <!-- <a class="mr-3 fs16 txtactive warm-link" href="javascript:void(0);">View Bid Breakdown</a> -->
                </div>
            </div>
            <hr>
            <h4 class="txtdefault fs16 wt700 mb-3">Select Logistics</h4>
            <div class="row">
                <div class="col-md-6">
                    <div class="ord-adrs-artcl">
                        <p class="txtdefault fs14 lnht24 mb-0 cssinptchk">
                            <span class="css-check">
                                <input class="css-checkbx" name="logistics_type" type="radio"
                                    [checked]="selected_delivery == 'standard'" (click)="changedelivery('standard')" />
                                <input class="css-checkbx" name="logistics_type" type="radio" />
                                <label class="css-label">&nbsp;</label>
                            </span>
                            <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="21.334" height="15.778"
                                viewBox="0 0 21.334 15.778">
                                <path id="np_delivery_4410921_000000"
                                    d="M18.4,30.9a2.778,2.778,0,0,1-2.688-2.083H11.368a2.778,2.778,0,0,1-5.375,0H3.819a.694.694,0,0,1-.694-.694V16.319a.694.694,0,0,1,.694-.694H17.014a.694.694,0,0,1,.694.694V19.1h2.778a.7.7,0,0,1,.542.264l2.778,3.472a.7.7,0,0,1,.153.431v4.861a.694.694,0,0,1-.694.694H21.091A2.778,2.778,0,0,1,18.4,30.9Zm-1.389-2.778a1.389,1.389,0,1,0,.465-1.035,1.389,1.389,0,0,0-.465,1.035ZM8.681,26.737a1.39,1.39,0,1,0,.982.407,1.388,1.388,0,0,0-.982-.407Zm12.41.694H22.57V23.507l-2.417-3.021H17.709v4.952a2.718,2.718,0,0,1,.694-.09,2.778,2.778,0,0,1,2.688,2.083Zm-9.723,0h4.347a2.692,2.692,0,0,1,.6-1.146V17.014H4.514V27.431H5.993a2.778,2.778,0,0,1,5.375,0Z"
                                    transform="translate(-2.875 -15.375)" fill="#1f1d44" stroke="#fcfcfc"
                                    stroke-width="0.5" />
                            </svg> Standard Delivery
                        </p>
                        <p class="txtseconday fs12 dlnht20 mb-2">
                            Dispatched by {{ selected_bid.standard_delivery_date }}</p>
                        <a class="fs14 add-whislist-link" href="javascript:void(0);" (click)="openlogistics(logisticsCharges)"><span>View Details</span></a>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="ord-adrs-artcl">
                        <p class="txtdefault fs14 lnht24 mb-0 cssinptchk">
                            <span class="css-check">
                                <input class="css-checkbx" name="logistics_type" type="radio"
                                [checked]="selected_delivery == 'express'" (click)="changedelivery('express')" />
                                <label class="css-label">&nbsp;</label>
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.398" height="13.82"
                            viewBox="0 0 20.398 13.82">
                            <path id="np_delivery_2964601_000000"
                                d="M24.071,23.787a2.357,2.357,0,0,0-2.121-1.311H18.581V19.941a.429.429,0,0,0-.429-.429H7.691a.426.426,0,0,0-.3.125.43.43,0,0,0,.3.734H17.723V30.655H13.306a2.246,2.246,0,0,0-4.41,0H7.464a.43.43,0,1,0,0,.86H8.9a2.246,2.246,0,0,0,4.41,0h5.821a2.246,2.246,0,0,0,4.41,0h1.432a.431.431,0,0,0,.429-.43V26.541a.435.435,0,0,0-.045-.192ZM11.1,32.472a1.388,1.388,0,1,1,1.388-1.388A1.388,1.388,0,0,1,11.1,32.472ZM21.95,23.335a1.5,1.5,0,0,1,1.353.837l1.235,2.471v.3H18.581v-3.6Zm-.618,9.137a1.388,1.388,0,1,1,1.387-1.388,1.388,1.388,0,0,1-1.387,1.388Zm2.205-1.818h0a2.246,2.246,0,0,0-4.41,0h-.545V27.8h5.956v2.858Zm-16.9-7.866h6.149a.43.43,0,1,1,0,.86H6.639a.43.43,0,1,1,0-.86ZM5,26.257a.429.429,0,0,1,.429-.43h5.323a.43.43,0,1,1,0,.86H5.429A.429.429,0,0,1,5,26.257Z"
                                transform="translate(-5 -19.511)" fill="#1f1d44" />
                        </svg> Express Delivery
                        </p>
                        <!-- <p class="txtdefault fs14 lnht24 mb-0">Shipping charges: {{ detail.standard_delivery_charge }}</p> -->
                        <p class="txtseconday fs12 dlnht20 mb-2">
                            Dispatched by {{ selected_bid.express_delivery_date }}</p>
                        <a class="fs14 add-whislist-link" href="javascript:void(0);"><span (click)="openlogistics(logisticsChargesexpress)">View Details</span></a>
                    </div>
                </div>
            </div>
            <hr>
            <h4 class="txtdefault fs16 wt700 mb-3">Select Shipping Address</h4>
            <div class="row mb-10">
                <div class="col-md-12">
                    <div class="ord-adrs-artcl" *ngFor="let addr of selected_bid.buyer_shipping_address; let i = index;">
                        <p class="txtdefault fs14 lnht24 mb-0 cssinptchk">
                            <span class="css-check">
                                <input class="css-checkbx" name="supply_location" type="radio" [checked]="addr.selected" (click)="changesupplylocation(i)"/>
                                <label class="css-label">&nbsp;</label>
                            </span>
                            {{ addr.address }}
                        </p>
                    </div>
                </div>
            </div>
            <hr>
            <h4 class="txtdefault fs16 wt700 mb-3">Billing Address</h4>
            <div class="mb-3"><a (click)="changebillingaddress()" href="javascript:void(0);"
                class="link-alt fs13">Request to Change Address
            </a></div>
            <div class="row mb-10">
                <div class="col-md-12">
                    <div class="ord-adrs-artcl">
                        <p class="txtdefault fs14 lnht24 mb-0 cssinptchk">
                            {{ selected_bid.billing_address }}
                        </p>
                    </div>
                </div>
            </div>
            <hr>
            <h4 class="txtdefault fs16 wt700 mb-3">Insurance <i placement="top"
                ngbTooltip="Insurance is optional and Please read the Terms & Conditions carefully before buying.">
                <img src="/assets/images/icons/info.png">
            </i></h4>
            <div class="row d-flex align-items-center">
                <div class="col-md-7">
                    <div class="text-muted">By adding Insurance, I agree with insurance and accept all
                        the <a href="javascript:;" class="text-orange" (click)="openinsurance(contentinsurance)">terms & conditions</a></div>
                </div>
                <div class="col-md-5">
                    <div class="text-right">
                        <a href="javascript:;" class="mr-2 btn-d-outline fs14 place-bid d-inline-block" 
                        (click)="openinsurance(contentinsurance)">{{ !enable_insurance? 'Add insurance':'Remove insurance' }}</a>
                    </div>
                </div>
            </div>
        </div>
        <!--end modal-scrollbar-->
    </div>

    <div class="nobd">
        <div class="row d-flex justify-content-center mt-3">
            <div class="col-md-12">
                <div class="text-center">
                    <button class="buybtn d-inline-block px-4 radius10"
                        [disabled]="!selected_bid.bid_amount" type="button" (click)="placebid()">Place Bid</button>
                </div>
            </div>
        </div>
        <hr>
        <p class="fs12 text-center">
            Please note that transaction under bids and offer uses Equibiz wallet as a payment method. Request buyer partners to maintain adequate funds in their wallet account before placing a bid for seamless transaction experience.
        </p>
    </div>
</ng-template>
<!-- /PLACE BID MODAL -->

<!-- LOGISTIC CHARGES MODAL -->
<ng-template #logisticsChargesexpress let-modal>
    <div class="modal-header text-center nobd pt-4 mt-1 pb-1 d-block">
        <h4 class="modal-title mx-auto f-700 fs20 txtdefault">Express Delivery (Inclusive of GST%)</h4>
        <div class="fs12 f-600 mb-2">Recommended for the long distance movement of bulk consignment of products like mobile phones, tablets,etc. 
         Under this mode, logistic partner will preferably move the consignment by air and you are expected to receive stock in 3 working days.</div>
        <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">
        <div class="modal-scrollbar">
            <div class="notifications-container theme-tab">
                <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
                    <ngb-tab>
                        <ng-template ngbTabContent>
                            <div class="tab-content">
                                <table class="table table-bordered xs">
                                    <thead>
                                        <tr>
                                            <th>Weight Bracket</th>
                                            <th>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of express_records;">
                                            <td>{{ record.weight }}</td>
                                            <td>{{ record.total }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </div>

</ng-template>
<!-- /LOGISTIC CHARGES MODAL -->

<!-- LOGISTIC CHARGES MODAL -->
<ng-template #logisticsCharges let-modal>
    <div class="modal-header text-center nobd pt-4 mt-1 pb-1 d-block">
        <h4 class="modal-title mx-auto f-700 fs20 txtdefault">Standard Delivery (Inclusive GST%)</h4>
        <div class="fs12 f-600 mb-2">Under this delivery mode you are expected to receive stock in 6 working days.</div>
        <button type="button" class="close closemodal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-0">

        <div class="modal-scrollbar">
            <div class="notifications-container theme-tab">
                <ngb-tabset [justify]="'left'" [type]="'pills'" class="tabs tab-title">
                    <ngb-tab>
                        <ng-template ngbTabTitle>Rate for within City</ng-template>
                        <ng-template ngbTabContent>
                            <div class="tab-content">
                                <table class="table table-bordered xs">
                                    <thead>
                                        <tr>
                                            <th>Weight Bracket</th>
                                            <th>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of standard_city_records;">
                                            <td>{{ record.weight }}</td>
                                            <td>{{ record.total }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                    <ngb-tab>
                        <ng-template ngbTabTitle>Rate for Rest of India</ng-template>
                        <ng-template ngbTabContent>
                            <div class="tab-content">
                                <table class="table table-custom xs">
                                    <thead>
                                        <tr>
                                            <th>Weight Bracket</th>
                                            <th>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let record of standard_outsidecity_records;">
                                            <td>{{ record.weight }}</td>
                                            <td>{{ record.total }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </ng-template>
                    </ngb-tab>
                </ngb-tabset>
            </div>
        </div>
    </div>

</ng-template>
<!-- /SHIPPING ADDRESS MODAL -->

<!-- Insurance Details MODAL -->
<ng-template #contentinsurance let-modal>
    <div class="modal-header nobd text-center pt-4 pb-0">
        <h4 class="modal-title fs22 txtdefault f-700 mx-auto pt-3">Insurance Terms & Conditions</h4>
    </div>
    <div class="modal-body">
        <div class="terms-conditions">
            <h5><b>Tata AIG General Insurance Company Limited</b></h5>
            <div class="fs18 f-700 mb-10">MARINE CARGO OPEN POLICY</div>
            <div><b>VOYAGE: </b></div>
            <p>From anywhere in India to anywhere in India.</p>
            <div><b>INSURING CLAUSES: </b></div>
            <p>Inland Transit (Rail/Road/Air) Clause - A 2010<br>
            Strikes Riots and Civil Commotion Clause - 2010<br>
            Limitation of Liability Clause<br>
            Termination of Transit Clause (Terrorism) JC 2009/056 (01.01.09)<br>
            Institute Radioactive Contamination, Chemical, Biological, Bio-Chemical, Electromagnetic Weapons Exclusion Clause CL.370 (10.11.03)<br>
            Institute Cyber Attack Exclusion Clause CL.380 (10.11.03)<br>
            Institute Standard Conditions for Cargo Contracts 1/4/82<br>
            Important Notice Clause</p>
            <div><b>WARRANTIES, CONDITIONS & EXCLUSIONS:</b></div>
            <p>Institute Replacement clause: In the event of loss or damage to any part or parts of an insured machine caused by a peril covered by the Policy, the sum recoverable shall not exceed the cost of replacement or repair of such part or parts plus charges for forwarding and refitting, if incurred, but excluding duty unless the full duty is included in the amount insured, in which case loss, if any, sustained by payment of additional duty shall also be recoverable.</p>
            <p>ISSUING OF CERTIFICATES CONDITION: The Assured will be authorized to issue Certificate of Insurance through e-marine which is Web based tool. As per Statutory Provision, premium adequacy of sum insured has to be ensured at any point of time. (not applicable for Project policies). This condition is applicable for those voyages where type of declaration opted as Marine certificate under the policy.</p>
            <p>CANCELLATION CLAUSE CONDITION: This contract may be cancelled by either the Company or the Assured giving 30 day&rsquo;s notice in writing to take effect from the midnight of date of notice but risks covered by Institute War Clauses may be cancelled at seven day&rsquo;s notice and risks covered by the Institute Strikes Clauses may be cancelled at seven day&rsquo;s notice, or at forty-eight hour&rsquo;s notice in respect of shipments to or from the United States of America. Notice shall commence from midnight of the day when it is issued but cancellation shall not apply to any risk which have attached in accordance with the cover granted hereunder before the cancellation becomes effective.</p>
            <p>Excluding coverage in respect of Institute War Clauses (Cargo) and Institute Strikes Clauses (Cargo) where Tata AIG risk commences from Bahrain, Kuwait, Oman, Qatar, Saudi Arabia and United Arab Emirates and/or where Tata AIG risk terminates at Bahrain, Kuwait, Oman, Qatar, Saudi Arabia and United Arab Emirates. The Reinstatement of Institute War Clauses (Cargo) and Institute Strikes Clauses (Cargo) shall be subject to an additional premium at the rate of 0.05% on the shipment value, to be paid/remitted to the Tata AIG prior to the commencement of the transit, failing which Tata AIG shall be absolved of all liabilities</p>
            <p>VERIFICATION OF RECORD CONDITION: The Company shall have the privilege at any time during business hours to inspect the records of the Assured in respect of the shipment or sending or risk falling within the terms of this contract.</p>
            <p>COMMUNICABLE DISEASE EXCLUSION LMA 5394 (amended) This exclusion applies to all original risks (including individual declarations made under facilities). 1. Notwithstanding any provision to the contrary within this insurance agreement, this insurance agreement excludes any loss, damage, liability, claim, cost or expense of whatsoever nature, directly or indirectly caused by, contributed to by, resulting from, a rising out of, or in connection with a Communicable Disease or the fear or threat (whether actual or perceived) of a Communicable Disease regardless of any other cause or event contributing concurrently or in any other sequence thereto. 2. As used herein, a Communicable Disease means any disease which can be transmitted by means of any substance or agent from any organism to another organism where:</p>
            <p>2.1. the substance or agent includes, but is not limited to, a virus, bacterium, parasite or other organism or any variation thereof, whether deemed living or not, and</p>
            <p>2.2. the method of transmission, whether direct or indirect, includes but is not limited to, airborne transmission, bodily fluid transmission, transmission from or to any surface or object, solid, liquid or gas or between organisms, and</p>
            <p>2.3. the disease, substance or agent can cause or threaten damage to human health or human welfare or can cause or threaten damage to, deterioration of, loss of value of, marketability of or loss of use of property. Notwithstanding the foregoing, losses resulting directly or indirectly from, or caused by, contributed to by, resulting from, arising out of, or in connection with any otherwise covered peril under the Policy and not otherwise excluded under this insurance agreement shall be covered.</p>
            <p>Notwithstanding anything to the contrary stated in this policy below conditions shall be applicable for Return/ Used / Second Hand/ Scrap/Rejected items: (a) All pre-existing losses or damages prior to commencement of transit under the policy are specifically excluded from the scope of cover. (b) Coverage shall be subject to Institute Cargo Clause &apos;B&apos; + War &amp; Strikes / Inland Transit Clause &lsquo;B&rsquo; + Strike Riots Civil Commotion + Non-Delivery of the consignment on the conveyance, as applicable (c) Basis of Valuation applicable shall be: Depreciated Market Value. (d) Replacement (second-hand) Machinery Clause : In the event of claim for loss or damage to any part or parts of the Insured Interest in consequence of a peril covered by this insurance, the amount recoverable here under shall not exceed such proportion of the cost of replacement of the parts lost or damaged as the insured value bears to the value of new machinery, plus additional charges for forwarding and refitting the new parts if incurred. Provided always that in no case shall the liability of Underwriters exceed the insured value of the complete machine. (d) In case mode of conveyance under the policy also cover transits through air then, such type of cargoes will be covered for loss or damage where proximate cause is due to Fire, Explosion, Lightning, Collision with or by the carrying vehicle/aircraft, Overturning of the carrying vehicle/aircraft, Derailment or accidents of like nature to the carrying railway wagon/ vehicle/aircraft, Strike Riots Civil Commotion and Non Delivery of the consignment on the conveyance Excluding loss and damage to the software from the scope of the policy.</p>
            <div><b>JOINT EXCESS LOSS CYBER LOSSES CLAUSE (JX2020-007) </b></div>
            <p>1. Notwithstanding any other term of this Contract save for Clause 2 below, in no case shall this Contract cover loss, damage, liability, or expense directly or indirectly caused by or contributed to by or arising from the use or operation of an Information Technology Device as a means for inflicting harm.</p>
            <p>2. Where this clause is endorsed on policies covering risks of war, civil war, revolution, rebellion, insurrection, or civil strife arising therefrom, or any hostile act by or against a belligerent power, or terrorism or any person acting from a political motive, Clause 1 shall not exclude losses which would otherwise be covered arising from the use of any Information Technology Device in the launch and/or guidance system and/or firing mechanism of any weapon or missile.</p>
            <p>3.Where the use or operation of an Information Technology Device was not used as a means of inflicting harm, any ensuing loss, damage, liability or expense shall be excluded unless a physical peril named in the Information Technology Hazards Clause within JELC CL432 (16/10/2017) was also a significant cause of a loss. In such case this Contract shall cover the loss, damage, liability or expense in accordance with but only to the extent provided for in that Clause, whether that Clause forms part of this Contract or not.</p>
            <p>4. For the purposes of this clause, an Information Technology Device shall mean any computer system, hardware, software, programme, code, data, process, virus, information repository, microchip, integrated circuit or similar device in or connected with computer equipment or non-computer equipment, whether the property of a direct insured or not.</p>
            <p>In case transit is through post, courier or registered post parcel no claim for loss /damage will be admitted if proved to be due to incorrect/ambiguous insufficient address on the package or having been unaccepted by the addressee.</p>
            <p>ADDITIONAL CONDITIONS APPLICABLE FOR TAIL-END RISKS OF CIF/CIP IMPORTS or where the overseas leg is not required to be insured by the Importer: Notwithstanding anything to the contrary stated in this policy below conditions shall be applicable: (a) All pre-existing damages prior to commencement of the transit under the policy are specifically excluded. (b) Coverage shall be subject to Inland Transit Clause &lsquo;B&rsquo; + SRCC + Non-delivery of the carrying conveyance + Act of God (AOG) perils. (c) In case mode of conveyance under the policy also cover transits through air then subject matter insured will be covered for Fire, Explosion, Lightning, Collision with or by the carrying vehicle/aircraft, Overturning of the carrying vehicle/aircraft, Derailment or accidents of like nature to the carrying railway wagon/ vehicle/aircraft, Strike Riots Civil Commotion, Non Delivery of the consignment on the conveyance and Act of God (AOG) perils Notwithstanding to the contrary, Duration Clause (6.1.6) under the Inland Transit Clause (2010) is deleted and replaced with &quot;in respect of transit by Air only until expiry of 30 days after unloading the subject- matter insured from the aircraft at the final place of discharge&quot;</p>
            <p>In case claim experience declared by the client at the time of quoting or gets deteriorated post quoting, the same should be declared to the Insurer, failing which Insurer shall be absolved of all liabilities ab-initio Excluding mechanical, electrical, electronic, electro-mechanical and internal derangement losses from scope of cover of the policy unless the loss or damage is caused due to Institute Cargo Clauses - B (2009) perils or Inland Transit Clause - B (2010) perils, as applicable. In case mode of conveyance under the policy also cover transits through air then, such losses will be covered where proximate cause of loss is due to : Fire, Explosion, Lightning, Collision with or by the carrying vehicle/aircraft, Overturning of the carrying vehicle/aircraft, Derailment or accidents of like nature to the carrying railway wagon/ vehicle/aircraft Excluding damage to labels unless the goods themselves are damaged at the same time. Excluding bulk cargo, break-bulk cargo and road tanker movements from the scope of the policy. Warranted that if the weight of the cargo exceeds than Registration Laden weight/ Licensed Carrying Capacity of the vehicle, as mentioned in the Registration Certificate of the vehicle, then any loss or damage attributable to overloading is not covered under the policy, unless at the time of loading, the assured or its employees were not privy to such overloading where such loading is taking place after the attachment of risk as per the Duration Clause of the corresponding Institute Cargo Clauses (2009) and /or Inland Transit Clauses (2010) as applicable . Warranted that the Goods are transported in Closed Wagons/Trucks or Trucks to be covered with Tarpaulin or any other water proof Material. However, any loss due to Ingress of Rain Water/Moisture in Cargo because of Improper Precaution / Faulty Tarpaulin / Material used by transporter for which client is not Privy is Considered to be covered.</p>
            <p>Excluding Shortages from Seal intact containers and/or sound packing and/or unexplained shortages Where any item insured under this policy consists of articles in a pair or set, the Company&rsquo;s liability shall not exceed the value of any particular part or parts which may be lost or damaged without reference to any special value which such articles may have as part of such pair or set and in any event not more than a proportionate part of the insured value of the pair or set of Valuation stated in the Policy.(not applicable for Project policies)</p>
            <p>Excluding Denting, Bending, Scratching, Chipping, Damage to Paint &amp; Polish work unless caused by Inland Transit Clause (B)/ Institute Cargo Clause (B) perils</p>
            <p>Excluding Rusting, oxidization, discoloration, unless caused by Inland Transit Clause (B)/ Institute Cargo Clause (B) perils</p>
            <div><b>EXCESS</b></div>
            <p>DOMESTIC : 1 % of consignment value or INR 10,000 whichever is higher for each and every claim.</p>
        </div>
    </div>
    <div class="modal-footer  text-center nobd mx-auto pb-4 mb-3">
        <button type="button" class="btn-d-outline d-inline-block cancel-outline mr-3"
            (click)="modal.close('Close click')">Close</button>
        <button type="button" class="btn buybtn fs16 radius10" *ngIf="!enable_insurance" (click)="enableinsurance('yes', contentinsurance)">{{
            !enable_insurance? 'Agree and Apply':'Remove insurance' }}</button>
    </div>
</ng-template>
<!-- /Insurance Details MODAL -->