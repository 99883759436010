import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class PersonalDetailsService {

    constructor(private _http: HttpClient) { }

    getdetails(data) {
        return this._http.post(environment.api_url + 'sma/seller/details', data);
    }

    getshippingdetails(data) {
        return this._http.post(environment.api_url + 'sma/shipping/details', data);
    }

    uploadprofileimage(data) {
        return this._http.post(environment.api_url + 'seller/updateprofileimage', data);
    }

    verifyemail(data) {
        return this._http.post(environment.api_url + 'seller/verifyprofilebyotp', data);
    }

    verifyemailotp(data) {
        return this._http.post(environment.api_url + 'seller/verifyprofile', data);
    }

    changepassword(data) {
        return this._http.post(environment.api_url + 'seller/changepassword', data);
    }

    changevalue(data) {
        return this._http.post(environment.api_url + 'seller/changeprofilebyotp', data);
    }

    changeprofile(data) {
        return this._http.post(environment.api_url + 'seller/changeprofile', data);
    }

    saveauthorizedpersons(data) {
        return this._http.post(environment.api_url + 'seller/authorizedperson/add', data);
    }

    saveshippingform(data) {
        return this._http.post(environment.api_url + 'seller/hublocation/add', data);
    }

    addshippingaddress(data) {
        return this._http.post(environment.api_url + 'sma/shipping/address', data);
    }

    updateshippingform(data) {
        return this._http.post(environment.api_url + 'seller/hublocation/update', data);
    }

    deleteshippingform(data) {
        return this._http.post(environment.api_url + 'seller/hublocation/delete', data);
    }

    updatebrandlogo(data) {
        return this._http.post(environment.api_url + 'seller/updatebrandlogo', data);
    }

    getstate(data) {
        return this._http.post(environment.api_url + 'master/state/list', data);
    }

    getcity(data) {
        return this._http.post(environment.api_url + 'master/city/list', data);
    }

    buyerregisteredaddresschange(data) {
        return this._http.post(environment.api_url + 'buyer/regaddress/request', data);
    }

    sellerregisteredaddresschange(data) {
        return this._http.post(environment.api_url + 'seller/regaddress/request', data);
    }


}