<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="">
    <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row d-flex justify-content-between">
             <div class="col-md-3 d-flex align-items-center">
                <a [routerLink]="['/home']"><img src="assets/images/spice-logo.png"></a>
             </div>
             <div class="col-md-9">
             	<div class="text-right right-header">
             		<ul class="header-dropdown">
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/how-it-works']">Home</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/about-us']">About Us</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/services']">Services</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/partners']" class="active">Partners</a></li>
					</ul>
             	</div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="page-section pt-50 bg-white">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="seller-banner-container" style="background-image: url('assets/images/partners.jpg');">
					<div class="row">
						<div class="col-md-5 text-left">
							<h3 class="hs-line-6 font-alt mb-20">
								Building Trust Across
								Partners and Brands.
							</h3>
							<div class="section-text-xs text-left mb-30">
								Equibiz aims to collaborate with various brands, making their products available on its platform and giving partners the opportunity to grow and expand their business.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<!-- <section class="page-section bg-image-orange">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12 text-center">
				<h2 class="hs-line-7 font-alt mb-10">Brand Partners</h2>
			</div>
		</div>
		<div class="row d-flex justify-content-center mb-30">
			<div class="col-md-6 text-center">
				<p>Equibiz aims to collaborate with various brands, making their products available on its platform and giving partners the opportunity to grow and expand their business.</p>
			</div>
		</div>

		<div class="row">
			<div class="col-md-3">
				<div class="card-white text-center mb-30">
					<img src="assets/images/xiaomi.png">
				</div>
			</div>
			<div class="col-md-3">
				<div class="card-white text-center mb-30">
					<img src="assets/images/xiaomi.png">
				</div>
			</div>
			<div class="col-md-3">
				<div class="card-white text-center mb-30">
					<img src="assets/images/xiaomi.png">
				</div>
			</div>
			<div class="col-md-3">
				<div class="card-white text-center mb-30">
					<img src="assets/images/xiaomi.png">
				</div>
			</div>
			<div class="col-md-3">
				<div class="card-white text-center mb-20">
					<img src="assets/images/xiaomi.png">
				</div>
			</div>
			<div class="col-md-3">
				<div class="card-white text-center mb-20">
					<img src="assets/images/xiaomi.png">
				</div>
			</div>
			<div class="col-md-3">
				<div class="card-white text-center mb-20">
					<img src="assets/images/xiaomi.png">
				</div>
			</div>
			<div class="col-md-3">
				<div class="card-white text-center mb-20">
					<img src="assets/images/xiaomi.png">
				</div>
			</div>
		</div>
	</div>
</section> -->

<section class="page-section bg-light-blue">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-10">Business Partners</h2>
			</div>
		</div>

		<div class="row mt-40">
			<div class="col-md-4">
				<div class="card-alt mb-20">
					<svg id="briefcase" xmlns="http://www.w3.org/2000/svg" width="43.008" height="35.84" viewBox="0 0 43.008 35.84">
						<defs>
						    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
						      <stop offset="0" stop-color="#7fbbff"/>
						      <stop offset="1" stop-color="#0278ff"/>
						    </linearGradient>
					  	</defs>
					  <path id="Combined_Shape" data-name="Combined Shape" d="M4.928,35.84A4.934,4.934,0,0,1,0,30.911v-12.1H10.752v1.649a1.344,1.344,0,1,0,2.687,0V18.815H29.568v1.649a1.344,1.344,0,0,0,2.688,0V18.815H43.008v12.1A4.934,4.934,0,0,1,38.08,35.84ZM29.567,16.129H13.44v-2.24a1.344,1.344,0,1,0-2.687,0v2.24H0V10.3A4.935,4.935,0,0,1,4.928,5.376h9.408V3.584A3.587,3.587,0,0,1,17.92,0h7.168a3.587,3.587,0,0,1,3.584,3.584V5.376H38.08A4.935,4.935,0,0,1,43.008,10.3v5.823H32.256v-2.24a1.363,1.363,0,0,0-1.344-1.345,1.346,1.346,0,0,0-1.345,1.345v2.24h0ZM25.088,5.376V3.584H17.92V5.376Z" transform="translate(0 0)" fill="url(#linear-gradient)"/>
					</svg>
					<h4>Retailers</h4>
					<p>Brand and wholesaler gain access to trusted retailer base across the country to offer products in the wholesale</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt mb-20">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="46" viewBox="0 0 32 46">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7fbbff"/>
					      <stop offset="1" stop-color="#0278ff"/>
					    </linearGradient>
					  </defs>
					  <path id="Shape" d="M0,30.218a29.731,29.731,0,0,1,2.384-11.1A52.181,52.181,0,0,1,7.547,9.622,66.028,66.028,0,0,1,15.035.4,1.378,1.378,0,0,1,16.523.1a1.348,1.348,0,0,1,.843,1.245v11.3a3.024,3.024,0,0,0,6.048,0,1.35,1.35,0,0,1,1-1.3,1.373,1.373,0,0,1,1.536.6C29.965,18.5,32,24.647,32,30.218A15.911,15.911,0,0,1,16,46,15.911,15.911,0,0,1,0,30.218Z" transform="translate(0 0)" fill="url(#linear-gradient)"/>
					</svg>

					<h4>Wholesaler/Distributor/Stockiest</h4>
					<p>Platform provides opportunity to buy and sell products both in bulk and wholesale to these large B2B players</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt mb-20">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="37.945" height="35.929" viewBox="0 0 37.945 35.929">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7fbbff"/>
					      <stop offset="1" stop-color="#0278ff"/>
					    </linearGradient>
					  </defs>
					  <g id="Layer_2" data-name="Layer 2" transform="translate(-1 -1.804)">
					    <path id="Path_9671" data-name="Path 9671" d="M36.681,14.5a4.958,4.958,0,0,1-3.908-6.7,2.327,2.327,0,0,0-.7-2.618,18.656,18.656,0,0,0-5.565-3.225,2.34,2.34,0,0,0-2.643.734,4.945,4.945,0,0,1-3.9,1.9,4.945,4.945,0,0,1-3.9-1.9,2.34,2.34,0,0,0-2.643-.734A18.745,18.745,0,0,0,8.247,4.86a2.353,2.353,0,0,0-.734,2.694A4.933,4.933,0,0,1,3.4,14.333a2.34,2.34,0,0,0-2.049,1.885A17.885,17.885,0,0,0,1,19.759a18.112,18.112,0,0,0,.24,2.972A2.34,2.34,0,0,0,3.3,24.692a4.933,4.933,0,0,1,4.022,6.969,2.3,2.3,0,0,0,.645,2.757,18.8,18.8,0,0,0,5.515,3.175,2.53,2.53,0,0,0,.8.139,2.327,2.327,0,0,0,1.9-.987,4.895,4.895,0,0,1,4.047-2.125,4.958,4.958,0,0,1,3.972,2,2.327,2.327,0,0,0,2.732.772,18.972,18.972,0,0,0,5.059-3.023,2.34,2.34,0,0,0,.683-2.669,4.933,4.933,0,0,1,3.959-6.817,2.34,2.34,0,0,0,1.986-1.923,18.34,18.34,0,0,0,.329-3.2,18.16,18.16,0,0,0-.317-3.381A2.315,2.315,0,0,0,36.681,14.5ZM26.3,19.759a6.324,6.324,0,1,1-6.324-6.324A6.324,6.324,0,0,1,26.3,19.759Z" fill="url(#linear-gradient)"/>
					  </g>
					</svg>
					<h4>Importers</h4>
					<p>Importers/Overseas manufacturers can leverage existing Equibiz retailer & large buyer network for the sale of products across India</p>
				</div>
			</div>
		</div>

		<div class="row d-flex justify-content-center">
			<div class="col-md-4">
				<div class="card-alt mb-20">
					<svg id="bitcoin" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="34.32" height="45.055" viewBox="0 0 34.32 45.055">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#0479ff"/>
					      <stop offset="1" stop-color="#7ebbff"/>
					    </linearGradient>
					  </defs>
					  <path id="Path" d="M33.418,5.348,17.578.068a1.323,1.323,0,0,0-.835,0L.9,5.348A1.319,1.319,0,0,0,0,6.6V21.758A24.454,24.454,0,0,0,16.742,44.987a1.31,1.31,0,0,0,.835,0A24.454,24.454,0,0,0,34.32,21.758V6.6A1.319,1.319,0,0,0,33.418,5.348Z" fill="url(#linear-gradient)"/>
					  <path id="Combined_Shape" data-name="Combined Shape" d="M0,14.25A2.25,2.25,0,1,1,2.25,16.5,2.253,2.253,0,0,1,0,14.25Zm0-6v-6a2.25,2.25,0,0,1,4.5,0v6a2.25,2.25,0,0,1-4.5,0Z" transform="translate(15 14)" fill="#e9f3ff"/>
					</svg>
					<h4>Brand</h4>
					<p>Brand can directly access to the large B2B buyer base and offer range of products in the bulk and wholesale</p>
				</div>
			</div>
		</div>
	</div>
</section>
<app-footer-one></app-footer-one>