import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class HomeService {

    constructor(private _http: HttpClient) { }
    getcategories(data) {
        return this._http.post(environment.api_url+'home/categories',data);
    }

    recentorders(data) {
        return this._http.post(environment.api_url+'buyer/recentorders',data);
    }

    searchcategory(data){
        return this._http.post(environment.api_url+'category/autocomplete',data);
    }

    getcmsdata(data){
        return this._http.post(environment.api_url+'home/cms/data',data);
    }

    getcategoryproducts(data){
        return this._http.post(environment.api_url+'home/cms/categories',data);
    }

    getfavcategories(data){
        return this._http.post(environment.api_url+'sma/home/favoure/categories',data);
    }

    getfavouriteproducts(data){
        return this._http.post(environment.api_url+'home/favoure/products',data);
    }

}
