<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="">
    <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row d-flex justify-content-between">
             <div class="col-md-3 d-flex align-items-center">
                <a [routerLink]="['/home']"><img src="assets/image/spice-logo.png"></a>
                <span class="separator"></span>
                <a href="javascript:;">Help Center</a>
             </div>
             <div class="col-md-6">
                <div class="input-group-custom">
                   <input class="form-control py-2 border-right-0 border" type="text" placeholder="Search Products, Brands and Categories" id="example-search-input">
                   <div class="input-group-append-custom">
                      <img src="assets/images/svg/search_logo.svg">
                   </div>
                </div>
             </div>
             <div class="col-md-3">
             	<div class="text-right right-header">
             		<ul class="header-dropdown">
					  <li class="onhover-dropdown mobile-account menu-item topbar-menu">
						Assistance
						<ul class="onhover-show-div w-3">
							<li><div class="header-title">Assistance</div></li>
							<li><a herf="javascript:;">Delivery</a></li>
							<li><a herf="javascript:;">Product & Stock</a></li>
							<li><a herf="javascript:;">Collection</a></li>
							<li><a href="javascript:;">Data Privacy</a></li>
							<li><a href="javascript:;">Order Cancellation</a></li>
							<li><a href="javascript:;">Payment & Promo</a></li>
							<li><a href="javascript:;">Returns & Refunds</a></li>
							<li><a href="javascript:;">Equibiz Account</a></li>
							<li><a href="javascript:;" class="btn btn-custom">Help Center</a></li>
						</ul>
					  </li>
					  <li class="menu-item"><a href="javascript:;">Go back to Equibiz</a></li>
					</ul>
             	</div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="small-section bg-gray-lighter pt-10 pb-80">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<!-- BREADCRUMB -->
				<nav class="mb-2 pt-2 pb-2" style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
				  <ol class="breadcrumb p-0 bg-transparent">
					<li class="breadcrumb-item"><a href="javascript:void(0);" class="txtseconday">Equibiz Help Center</a></li>
					<li class="breadcrumb-item"><a [routerLink]="['/buyer/assistance']" class="txtseconday">Assistance Topics</a></li>
					<li class="breadcrumb-item active" aria-current="page">Intro to Equibiz</li>
				  </ol>
				</nav>
				<!-- /BREADCRUMB -->
			</div>
		</div>

		<div class="row">
			<div class="col-md-9">
				<div class="assistance-box">
					<div class="title-container">
						<a href="javascript:;" class="go-back"><i class="fa fa-angle-left"></i></a>
						<h2 class="section-title text-left font-alt mb-0 mt-0">A guide about using Equibiz efficiently</h2>
					</div>

					<div class="inner-content mt-30" id="parentDiv" scrollSpy [spiedTags]="['DIV']" (sectionChange)="onSectionChange($event)">
						<!-- style="height:150px;overflow-y: scroll;" -->
						<div class="item" id="scroll1">
							<h4>What is Equibiz?</h4>
							<p>Equibiz is a <a href="javascript:;">B2B application</a> connecting buyers and sellers on one platform – Sellers will offer their products and interested buyers can purchase them.</p>
						</div>

						<div class="row d-flex justify-content-center mt-60 mb-60">
			                <div class="col-md-10">
			                    <div class="box-image">
			                    	<div class="box-video-image">
			                    		<img src="assets/images/video-thumb.jpg">
			                    	</div>
		                            <a id="play-video" class="open-link playicon video-play-button" href="javascript:;" (click)="open(content)">
		                              <span></span>
		                            </a>
			                    </div>
			                </div>
			            </div>

			            <div class="item" id="scroll2">
				            <h4>Who is eligible for Buying the products?</h4>
							<p>Buyers would be the Exporter/Importer, Authorized Retailer, Authorized Distributor, Wholesaler Retail Trade, E Commerce Trader, or any <a href="javascript:;">other business entity</a> who will be purchasing the products.</p>
						</div>

						<div class="item" id="scroll3">
							<h4>Who is eligible for Selling the products?</h4>
							<p>Sellers would be the Brand, OEM/Manufacturer, Exporter/Importer, Authorized Retailer, Authorized Distributor, Wholesaler, Retail Trade, E Commerce Trader, or any other seller who will be selling their products.</p>
						</div>

						<div class="item" id="scroll4">
							<h4>What is Equibiz?</h4>
							<p>Equibiz is a B2B application connecting buyers and sellers on one platform – Sellers will offer their products and interested buyers can purchase them.</p>
						</div>

						<div class="item" id="scroll5">
							<h4>Who is eligible for Buying the products?</h4>
							<p>Buyers would be the Exporter/Importer, Authorized Retailer, Authorized Distributor, Wholesaler Retail Trade, E Commerce Trader, or any other business entity who will be purchasing the products.</p>
						</div>

						<div class="item" id="scroll6">
							<h4>Who is eligible for Selling the products?</h4>
							<p>Sellers would be the Brand, OEM/Manufacturer, Exporter/Importer, Authorized Retailer, Authorized Distributor, Wholesaler, Retail Trade, E Commerce Trader, or any other seller who will be selling their products.</p>
						</div>
					</div>

					<!-- <h1>Current Section : [{{currentSection}}]</h1> -->

					<hr class="mb-30">

					<div class="row d-flex justify-content-center article-helpful">
						<div class="col-md-12">
							<h5 class="font-alt mb-20">Was this article helpful?</h5>

							<div class="row d-flex justify-content-center mt-20 mb-20">
								<div class="col-md-6">
									<div class="text-center">
										<button class="btn-d-outline d-inline-block ripple yes-or-no">Yes</button>
										<button class="btn-d-outline d-inline-block ripple yes-or-no">No</button>
									</div>
								</div>
							</div>

							<h6>127 out of 137 found this helpful</h6>
						</div>
					</div>
				</div>

				<div class="related-articles">
					<h2 class="section-title text-left font-alt mb-20 mt-0">Related articles</h2>
					<ul>
						<li><a href="javascript:;">A guide about using Equibiz efficiently and about the promotion</a></li>
						<li><a href="javascript:;">Benefits of using Equibiz as a Buyer</a></li>
						<li><a href="javascript:;">Benefits of using Equibiz as a Seller</a></li>
					</ul>
				</div>
			</div>

			<div class="col-md-3">
				<div class="on-this-page">
					<h2 class="section-title fs16 text-left font-alt mb-20 mt-0">On this page</h2>
					<ul id="list-example">
						<li (click)="scrollTo('scroll1')" [ngClass]="{'active': currentSection==='scroll1'}">What is Equibiz?</li>
						<li (click)="scrollTo('scroll2')" [ngClass]="{'active': currentSection==='scroll2'}">Who is eligible for Buying the products?</li>
						<li (click)="scrollTo('scroll3')" [ngClass]="{'active': currentSection==='scroll3'}">Who is eligible for Selling the products?</li>
						<li (click)="scrollTo('scroll4')" [ngClass]="{'active': currentSection==='scroll4'}">What is Equibiz?</li>
						<li (click)="scrollTo('scroll5')" [ngClass]="{'active': currentSection==='scroll5'}">Who is eligible for Buying the products?</li>
						<li (click)="scrollTo('scroll6')" [ngClass]="{'active': currentSection==='scroll6'}">Who is eligible for Selling the products?</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>

<ng-template #content let-modal>
	<div class="modal-content popup-container">
		<div class="modal-body">
	        <video width="100%" height="450" controls>
			  	<source src="https://equibiz.in/assets/video/video.mp4" type="video/mp4">
			  	Your browser does not support the video tag.
			</video>
	    </div>
	</div>
</ng-template>

<app-footer-one></app-footer-one>