import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Product } from "../../../classes/product";
import { SignupService } from './signup-modal.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { EncrDecrService } from '../../../../helpers/EncrDecrService';
import { ToasterService } from '../../../../helpers/toastr.service';

import {
  SocialAuthService,
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialUser,
} from 'angularx-social-login';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss']
})
export class SignupModalComponent implements OnInit, OnDestroy  {
  phoneForm: FormGroup;
  emailForm: FormGroup;
  otpForm: FormGroup;
  resetOtpForm: FormGroup;
  passwordForm: FormGroup;
  setPasswordForm: FormGroup;
  resetPasswordForm: FormGroup;
  @Input() product: Product;
  @Input() functionName;

  @ViewChild("signIn", { static: false }) SignIn: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  public showLoginModal:boolean = false;
  public showLoginOTPModal:boolean = false;
  public showLoginPasswordModal:boolean = false;
  public showResetPasswordModal:boolean = false;
  public showPersonalDetailsModal:boolean = false;
  public showLoginwithEmailModal:boolean = false;
  public verifyEmailModal:boolean = false;
  public loginWithPasswordModal:boolean = false;
  public forgotPasswordModal:boolean = false;
  public emailSentModal:boolean = false;
  public forgotOTPSentModal:boolean = false;
  public userType:string;
  public ResetPasswordToken:string;
  public setPasswordToken:string;
  public forgotPasswordPhone:number;
  public forgotPasswordEmail:string;
  public buttonLoading:boolean = false;
  public signupType:string = 'mobile';

  //Input Fields
  public phoneNumber:number;
  public emailAddress:string;
  public otp:string;
  public otp1:number;
  public otp2:number;
  public otp3:number;
  public otp4:number;
  public password:string;
  public confirm_password:string;
  public login_password:string;
  public secret:any;
  public user_exist:boolean = false;

  public resetOtp:string;
  public resetOtp1:number;
  public resetOtp2:number;
  public resetOtp3:number;
  public resetOtp4:number;


    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private modalService: NgbModal,
        private _signupservice: SignupService,
        private fb: FormBuilder,
        public router: Router,
        private _EncrDecr: EncrDecrService,
        private socialAuthService: SocialAuthService,
        private toastr: ToasterService
    ) { 
    }

    ngOnInit(): void {
        this.socialAuthService.authState.subscribe((user) => {
            let userData = {
                email: this._EncrDecr.set(user.email),
                firstName: this._EncrDecr.set(user.firstName),
                lastName: this._EncrDecr.set(user.lastName),
                provider: this._EncrDecr.set(user.provider),
            };
            this._signupservice.first_name = user.firstName;
            this._signupservice.last_name = user.lastName;
            this._signupservice.registration_unique_id = user.email;
            this.sociallogin(userData);
        });

        //Phone Form
        this.phoneForm = new FormGroup({
            phoneNumber: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(10), Validators.minLength(10)])
        });

        //Email Form
        this.emailForm = new FormGroup({
            emailAddress: new FormControl(null, [Validators.required, Validators.email])
        });

        //OTP Form
        this.otpForm = new FormGroup({
            otp1: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)]),
            otp2: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)]),
            otp3: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)]),
            otp4: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)])
        });

        //Reset OTP Form
        this.resetOtpForm = new FormGroup({
            resetOtp1: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)]),
            resetOtp2: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)]),
            resetOtp3: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)]),
            resetOtp4: new FormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+'), Validators.maxLength(1), Validators.minLength(1)])
        });

        //Set Password Form
        this.setPasswordForm = new FormGroup({
            password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(8)]),
            confirm_password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(8)])
        });

        //Reset Password Form
        this.resetPasswordForm = new FormGroup({
            password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(8)]),
            confirm_password: new FormControl(null, [Validators.required, Validators.maxLength(20), Validators.minLength(8)])
        });

        //Password Form
        this.passwordForm = new FormGroup({
            login_password: new FormControl(null, [Validators.required, Validators.minLength(8)])
        });

    }


    
    

    get s() { return this.phoneForm.controls; }
    get e() { return this.emailForm.controls; }
    get o() { return this.otpForm.controls; }
    get ro() { return this.resetOtpForm.controls; }
    get sp() { return this.setPasswordForm.controls; }
    get rsp() { return this.resetPasswordForm.controls; }
    get p() { return this.passwordForm.controls; }


    
    hideall() {
        this.showLoginModal = false;
        this.showLoginOTPModal = false;
        this.showLoginPasswordModal = false;
        this.showResetPasswordModal = false;
        this.showPersonalDetailsModal = false;
        this.showLoginwithEmailModal = false;
        this.verifyEmailModal = false;
        this.loginWithPasswordModal = false;
        this.forgotPasswordModal = false;
        this.emailSentModal = false;
        this.forgotOTPSentModal = false;
    }

    loginWithPhone() {
        this.hideall();
        this.showLoginModal = true;
        this.signupType = 'mobile';
    }

    accountOTP(emailOtp = 'no') {
        this.buttonLoading = true;
        let unique_id = this.signupType == 'email'?this.emailAddress:this.phoneNumber;
        unique_id = this._EncrDecr.set(unique_id);
        let signupType = this._EncrDecr.set(this.signupType);
        let email_otp = this._EncrDecr.set(emailOtp);
        this._signupservice.otpgenerate({unique_id: unique_id, type: signupType, email_otp: email_otp}).subscribe((data) => {
            this.buttonLoading = false;
            if(data['status'] == "success"){
                this.secret = data['data']['secret'];
                this.user_exist = data['data']['user_exist'];
                this.hideall();
                this.showLoginOTPModal = true;
            }else if(data['status'] == "error"){
                this.toastr.error(data['message']);
            }else if(data['status'] == 'validation'){
                this.toastr.validation(data['errors'])
            }
        },(error) => {
            this.buttonLoading = false;
        });
    }

    onDigitInput(event: any) {
        let element;
        if (event.code !== 'Backspace')
            element = event.srcElement.nextElementSibling;
        if (event.code === 'Backspace')
            element = event.srcElement.previousElementSibling;
        if (element == null)
            return;
        else
        element.focus();
    }

    loginwithEmail() {
        this.hideall();
        this.showLoginwithEmailModal = true;
        this.signupType = 'email';
    }

    getotp(){
        this.otp = this.otp1+""+this.otp2+""+this.otp3+""+this.otp4;
    }

    getresetotp(){
        this.resetOtp = this.resetOtp1+""+this.resetOtp2+""+this.resetOtp3+""+this.resetOtp4;
    }

    createpassword() {
        this.getotp();
        if(this.otpForm.valid) {
            if(this.user_exist){
                let unique_id = this.signupType == "mobile"?this.phoneNumber:this.emailAddress;
                this.login('otp', unique_id, this.otp);
            }else{
                this.buttonLoading = true;
                let otp = this._EncrDecr.set(this.otp);
                let type = this._EncrDecr.set(this.signupType);
                this._signupservice.verifyotp({secret: this.secret, otp: otp, type: type}).subscribe((data) => {
                    this.buttonLoading = false;
                    if(data['status'] == "success"){
                        this.hideall();
                        this.showLoginPasswordModal = true;
                    }else if(data['status'] == "error"){
                        this.toastr.error(data['message']);
                    }else if(data['status'] == 'validation'){
                        this.toastr.validation(data['errors'])
                    }
                },(error) => {
                  this.buttonLoading = false;
                });
            }
            
        }
    }

    sociallogin(data){
        data.notification_token = localStorage.getItem('firebase-token');
        this._signupservice.sociallogin(data).subscribe((data) => {
            if(data['status'] == "success"){
                this.signupType = 'email';
                if(data['data']['user_exist'] == false){
                    this.secret = data['data']['secret'];
                    this._signupservice.registration_secret = data['data']['secret'];
                    this.openmodal();
                    this.hideall();
                    this.showLoginPasswordModal = true;
                }else{
                    //Generate Firebase Token


                    
                    this.modalService.dismissAll();
                    this._signupservice.updatetoken(data['data']['token'], data['data']['usertype']);
                    this._signupservice.updatestatus(data['data']['adminverified']);
                    this._signupservice.updateregstatus(data['data']['reg_status']);
                    var userType = data['data']['usertype'];
                    if(data['data']['reg_status'] == 'no'){
                        if(userType == 1){
                            this.router.navigate(['/registration/buyer/details']);
                        }else if(userType == 2){
                            this.router.navigate(['/registration/seller/details']);
                        }
                    }else{
                        if(data['data']['usertype'] == 2){
                            this.router.navigate(['/sellerdashboard']);
                        }else{
                            let currentUrl = this.router.url;
                            this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
                                this.router.navigate([currentUrl]);
                            });
                        }
                    }
                }
            }else if(data['status'] == "error"){
                this.toastr.error(data['message']);
            }else if(data['status'] == 'validation'){
                this.toastr.validation(data['errors'])
            }
        },(error) => {
          this.buttonLoading = false;
        });
    }

    login(loginType, unique_id, unique_value) {
        let data = {
            type: this._EncrDecr.set(this.signupType),
            otporpass: this._EncrDecr.set(loginType),
            secret: this.secret,
            unique_id: this._EncrDecr.set(unique_id),
            unique_value: this._EncrDecr.set(unique_value),
            notification_token: localStorage.getItem('firebase-token'),
            req_from: this._EncrDecr.set("login")
        };
        this.buttonLoading = true;
        this._signupservice.login(data).subscribe((data) => {
            this.buttonLoading = false;
            if(data['status'] == "success"){

                //Generate Firebase Token


                this.modalService.dismissAll();
                this._signupservice.updatestatus(data['data']['adminverified']);
                this._signupservice.updateregstatus(data['data']['reg_status']);
                this._signupservice.updatetoken(data['data']['token'], data['data']['usertype']);
                var userType = data['data']['usertype'];
                setTimeout( () => {
                let currentUrl = this.router.url;
                this.router.navigate([currentUrl]);
                },1000);
                // if(data['data']['reg_status'] == 'no'){
                //     if(userType == 1){
                        // this.router.navigate(['/registration/buyer/details']);
                //     }
                //     else if(userType == 2){
                //         this.router.navigate(['/registration/seller/details']);
                //     }
                // }
                // else{
                //     if(data['data']['usertype'] == 2){
                //         if(data['data']['adminverified'] == 'no'){
                //             this.router.navigate(['/seller/pending']);
                //             return false;
                //         }else if(data['data']['adminverified'] == 'yes'){
                //             this.router.navigate(['/seller/dashboard']);
                //         }
                        
                //     }else{
                //         setTimeout( () => {
                //             let currentUrl = this.router.url;
                //             this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                //             this.router.onSameUrlNavigation = 'reload';
                //             this.router.navigate([currentUrl]);
                //         }, 1000);
                //     }
                // }
                
            }else if(data['status'] == "error"){
                this.toastr.error(data['message']);
            }else if(data['status'] == 'validation'){
                this.toastr.validation(data['errors'])
            }
        },(error) => {
            this.buttonLoading = false;
        });
    }

    setpassword() {
        if(this.setPasswordForm.valid) {
            let secret = this.setPasswordToken?this.setPasswordToken:this.secret;
            let password = this._EncrDecr.set(this.password);
            let confirm_password = this._EncrDecr.set(this.confirm_password);
            let signupType = this._EncrDecr.set(this.signupType);
            this.buttonLoading = true;
            this._signupservice.setpassword({secret: secret, password: password, confirm_password: confirm_password, type: signupType}).subscribe((data) => {
                this.buttonLoading = false;
                if(data['status'] == "success"){
                    this._signupservice.registration_secret = data['data']['secret'];
                    this._signupservice.registration_unique_id = data['data']['secret_value'];
                    this._signupservice.registration_type = this.signupType;
                    this._signupservice.registration_unique_value = this.password;
                    this.router.navigate(['/registration']);
                }else if(data['status'] == "error"){
                    this.toastr.error(data['message']);
                }else if(data['status'] == 'validation'){
                    this.toastr.validation(data['errors'])
                }
            },(error) => {
              this.buttonLoading = false;
            });
        }
    }

    forgotverifyotp(){
        this.getresetotp();
        if(this.resetOtpForm.valid) {
            let forgotPasswordPhone = this._EncrDecr.set(this.forgotPasswordPhone);
            let resetOtp = this._EncrDecr.set(this.resetOtp);
            this.buttonLoading = true;
            this._signupservice.forgotverifyotp({mobile: forgotPasswordPhone, otp: resetOtp}).subscribe((data) => {
                this.buttonLoading = false;
                if(data['status'] == "success"){
                    this.signupType = 'mobile';
                    this.resetPasswordModal();
                }else if(data['status'] == "error"){
                    this.toastr.error(data['message']);
                }else if(data['status'] == 'validation'){
                    this.toastr.validation(data['errors'])
                }
            },(error) => {
              this.buttonLoading = false;
            });
        }
    }

    resetpassword() {
        if(this.resetPasswordForm.valid) {
            let reset_token = this.signupType == 'email'?this.ResetPasswordToken:this.resetOtp;
            let unique_id = this.signupType == 'email'? this.forgotPasswordEmail: this.forgotPasswordPhone;
            let data = {
              reset_token: this._EncrDecr.set(reset_token),
              password: this._EncrDecr.set(this.password),
              confirm_password: this._EncrDecr.set(this.confirm_password),
              type: this._EncrDecr.set(this.signupType),
              unique_id: this._EncrDecr.set(unique_id)
            };
            this.buttonLoading = true;
            this._signupservice.resetpassword(data).subscribe((data) => {
                this.buttonLoading = false;
                if(data['status'] == "success"){
                    this.modalService.dismissAll();
                    this._signupservice.updatetoken(data['data']['token'], data['data']['usertype']);
                    this._signupservice.updatestatus(data['data']['adminverified']);
                    this._signupservice.updateregstatus(data['data']['reg_status']);
                    var userType = data['data']['usertype'];
                    if(data['data']['reg_status'] == 'no'){
                        if(userType == 1){
                            this.router.navigate(['/registration/buyer/details']);
                        }else if(userType == 2){
                            this.router.navigate(['/registration/seller/details']);
                        }
                    }else{
                        if(data['data']['usertype'] == 2){
                            this.router.navigate(['/sellerdashboard']);
                        }else{
                            this.router.navigate(['/home']);
                        }
                    }
                }else if(data['status'] == "error"){
                    this.toastr.error(data['message']);
                }else if(data['status'] == 'validation'){
                    this.toastr.validation(data['errors'])
                }
            },(error) => {
              this.buttonLoading = false;
            });
        }
    }

    verifypassword() {
        if(this.passwordForm.valid) {
            let unique_id = this.signupType == "mobile"?this.phoneNumber:this.emailAddress;
            if(this.user_exist && unique_id){
                this.login("pass", unique_id, this.login_password);
            }
        }else{
          //alert('hiiidfas');
        }
    }

    resetPasswordModal() {
        this.hideall();
        this.showResetPasswordModal = true;
    }

    personalDetails() {
        this.hideall();
        this.showPersonalDetailsModal = true;
    }

    loginwithPassword() {
        this.hideall();
        this.loginWithPasswordModal = true;
    }

    forgotPassword() {
        this.hideall();
        this.forgotPasswordModal = true;
    }

    gotofirstpage() {
        if(this.signupType == "email"){
            this.loginwithEmail();
        }else{
            this.loginWithPhone();
        }
    }

    verifyemail() {
        if(this.emailForm.valid) {
            let unique_id = this._EncrDecr.set(this.emailAddress)
            let signupType = this._EncrDecr.set(this.signupType);
            let email_otp = this._EncrDecr.set('no');
            this.buttonLoading = true;
            this._signupservice.otpgenerate({unique_id: unique_id, type: signupType, email_otp: email_otp}).subscribe((data) => {
                this.buttonLoading = false;
                if(data['status'] == "success"){
                    this.secret = data['data']['secret']
                    this.user_exist = data['data']['user_exist'];
                    if(!this.user_exist){
                        this.hideall();
                        this.verifyEmailModal = true;
                    }else{
                        this.loginwithPassword();
                    }
                }else if(data['status'] == "error"){
                    this.toastr.error(data['message']);
                }else if(data['status'] == 'validation'){
                    this.toastr.validation(data['errors'])
                }
            },(error) => {
              this.buttonLoading = false;
            });
        }
    }

    forgotemailsent() {
        let forgotPasswordEmail = this._EncrDecr.set(this.forgotPasswordEmail);
        let type = this._EncrDecr.set('email');
        this.buttonLoading = true;
        this._signupservice.forgotpassword({unique_id: forgotPasswordEmail, type: type}).subscribe((data) => {
            this.buttonLoading = false;
            if(data['status'] == "success"){
                this.hideall();
                this.emailSentModal = true;
            }else if(data['status'] == "error"){
                this.toastr.error(data['message']);
            }else if(data['status'] == 'validation'){
                this.toastr.validation(data['errors'])
            }
        },(error) => {
          this.buttonLoading = false;
        });
     }

    forgototpsent() {
        let forgotPasswordPhone = this._EncrDecr.set(this.forgotPasswordPhone);
        let type = this._EncrDecr.set('mobile');
        this.buttonLoading = true;
        this._signupservice.forgotpassword({unique_id: forgotPasswordPhone, type: type}).subscribe((data) => {
            this.buttonLoading = false;
            if(data['status'] == "success"){
                this.hideall();
                this.forgotOTPSentModal = true;
            }else if(data['status'] == "error"){
                this.toastr.error(data['message']);
            }else if(data['status'] == 'validation'){
                this.toastr.validation(data['errors'])
            }
        },(error) => {
          this.buttonLoading = false;
        });
    }

    openSigninModal(type=null, userType=null, token=null) {
        if(type == 'personal'){
            this.userType = userType;
            this.personalDetails();
        }else if(type == 'home'){
            this.loginWithPhone();
        }else if(type == 'reset'){
            this.resetPasswordModal();
            this.ResetPasswordToken = token;
            this.signupType = 'email';
        }else if(type == 'set'){
            this.hideall();
            this.showLoginPasswordModal = true;
            this.setPasswordToken = token;
            this.signupType = 'email';
        }
        this.modalOpen = true;
        if (isPlatformBrowser(this.platformId)) { // For SSR 
            this.modalService.open(this.SignIn, { 
                size: 'md',
                ariaLabelledBy: 'signup-modal',
                centered: true,
                windowClass: 'SignIn' 
            }).result.then((result) => {
                `Result ${result}`
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    public openmodal(){
        this.modalOpen = true;
        if (isPlatformBrowser(this.platformId)) { // For SSR 
            this.modalService.open(this.SignIn, { 
                size: 'md',
                ariaLabelledBy: 'signup-modal',
                centered: true,
                windowClass: 'SignIn' 
            }).result.then((result) => {
                `Result ${result}`
            }, (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    ngOnDestroy() {
        if(this.modalOpen){
             this.modalService.dismissAll();
        }
    }

    loginWithGoogle(){
        this.ngOnDestroy();
        this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    }

    loginWithFacebook(){
        this.ngOnDestroy();
        this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    }

}
