// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    instagram_token: 'INSTA_TOKEN',
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',    
    // api_url: 'https://8f8f-2409-40f4-33-2702-4551-7a57-ec-e168.ngrok-free.app/api/v1/',
    // cashfree_sandbox: 'https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.sandbox.js',
    // api_url: 'https://demo.equibiz.in/api/v1/',
    // cashfree_sandbox: 'https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.sandbox.js',
    api_url: 'https://equibiz.in/api/v1/',
    cashfree_sandbox: 'https://sdk.cashfree.com/js/ui/1.0.26/dropinClient.prod.js',
    keyforencdec: '123456$#@$^@1ERF',
    firebase: {
        apiKey: "AIzaSyCtIgWC1wjL9yDavSsIHq39zh8Ck8KC1Uw",
        authDomain: "equibiz-37a6f.firebaseapp.com",
        projectId: "equibiz-37a6f",
        storageBucket: "equibiz-37a6f.appspot.com",
        messagingSenderId: "1058224171167",
        appId: "1:1058224171167:web:22d4b44551052f6b131aaa",
        measurementId: "G-Z7QH6B3H3N",
        vapidKey: "BC4ymtuRTt0721tu1aXtHb6xvEFQXHD3xtibQx9gQNTCLRn0FQ5c9RfvYdZMGVGIZTkgIqSJU7yFvsCupqrGqdg"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.