import { Injectable,EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class BuyerAddMoneyService {

    constructor(private _http: HttpClient) { }

    placeorder(data){
        return this._http.post(environment.api_url+'buyer/cf/addtowallet/createorder',data);
    }

    getuserdetails(data) {
        return this._http.post(environment.api_url+'sma/userbasicinfo',data);
    }

    submitcard(data){
        return this._http.post(environment.api_url+'buyer/cf/addtowallet/card/pay',data);
    }

    savedcards(data){
        return this._http.post(environment.api_url+'buyer/cards',data);
    }

}
