import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToasterService } from 'src/app/helpers/toastr.service';
import { DealService } from './deal.service';
import { isNumber, toInteger, padNumber } from 'src/app/helpers/util';
import { PaginationService } from 'src/app/helpers/pagination.service';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { PersonalDetailsService } from 'src/app/seller/personal-details/personal-details.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
    selector: 'app-deal',
    templateUrl: './deal.component.html',
    styleUrls: ['./deal.component.scss']
})
export class DealComponent implements OnInit {
    addShippingForm: FormGroup
    public combo_products_list:any = [];
    public review_records: any = [];
    public current_page: number = 1;
    public offset: number = 0;
    public page_per_view: number = 20;
    public review_pagination: any = {};

    public page_per_view_options = [
        {
            value: 20,
            name: 20
        },
        {
            value: 40,
            name: 40
        }
    ];
    public filter_by_rating:any = [];
    public sort_by_rating:string = 'date_desc';
    public star_count:number;
    public rating_total_records:number;

    closeResult = '';
    public sp_id: string;
    public market_place: string;
    public detail: any = {};
    public slides = [];
    public productListing: any = [];
    public selected_delivery: string = 'standard';
    public total_order_value = 0;
    public total_order_quantity = 0;
    public buynow_order_quantity: number;
    public chunked: any = [];

    editor_config: AngularEditorConfig = {
        editable: false,
        spellcheck: true,
        // height: '15rem',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        outline: false,
        translate: 'yes',
        enableToolbar: false,
        showToolbar: false,
        placeholder: 'Enter text here...',
        defaultParagraphSeparator: '',
        defaultFontName: '',
        defaultFontSize: '',
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        customClasses: [
            {
                name: 'quote',
                class: 'quote',
            },
            {
                name: 'redText',
                class: 'redText'
            },
            {
                name: 'titleText',
                class: 'titleText',
                tag: 'h1',
            },
        ],
        // uploadUrl: 'v1/image',
        // upload: (file: File) => { },
        // uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            [],
            []
        ]
    };

    public selected_address_text: any = {};
    public selected_address: any;
    public selected_address_type: string = 'default';
    public modalType: string = 'shipping';

    public selected_billing_address_text: any = {};
    public express_records: any = [];
    public standard_city_records: any = [];
    public standard_outsidecity_records: any = [];
    stateArray: any = [];
    cityArray: any = [];
    updateShippingForm: FormGroup
    public sma_shipping_details: any = {};
    public sma_shipping_id: any = '';
    public sma_shipping_name: any = '';


    constructor(
        private modalService: NgbModal,
        private _dealservice: DealService,
        private _toastr: ToasterService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _pagination: PaginationService,
        private fb: FormBuilder,
        private _personaldetailsservice: PersonalDetailsService,
    ) { }

    ngOnInit(): void {
        this.sp_id = this._route.snapshot.paramMap.get('seller_product_id');
        this.market_place = this._route.snapshot.paramMap.get('market_place');
        this.detail.shipping_address = [];
        this.detail.return_summary = '';
        this.getproductdetail();
        this.getproductsbyseller();
        setTimeout(() => {
            this.getlogisticscharges();
            this.getreviews();
        }, 3000);

        this.addShippingForm = this.fb.group({
            address: this.fb.control(null, [Validators.required]),
            state: this.fb.control(null, [Validators.required]),
            city: this.fb.control(null, [Validators.required]),
            pincode: this.fb.control(null, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
            address_name: this.fb.control(null)
        });

        this.updateShippingForm = this.fb.group({
            address: this.fb.control(null, [Validators.required]),
            state: this.fb.control(null, [Validators.required]),
            city: this.fb.control(null, [Validators.required]),
            pincode: this.fb.control(null, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]),
            address_name: this.fb.control(null)
        });
        this.getstate();
        this.getshippingdetails();

    }

    getupdatecity() {
        var data = {};
        data = { state: this.sma_shipping_details.state };
        this._personaldetailsservice.getcity(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.cityArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    addaddr(type, modalType, model) {
        if(model == 'edit') {
            this.getupdatecity();
            this.addShippingForm.patchValue({
                'address' : this.sma_shipping_details.address,
                'state' : this.sma_shipping_details.state,
                'city' : this.sma_shipping_details.city,
                'pincode' : this.sma_shipping_details.pincode,
                'address_name' : this.sma_shipping_details.address_name
            });
            this.sma_shipping_id = this.sma_shipping_details._id;
            this.sma_shipping_name = this.sma_shipping_details.address;
        }
        else {
            this.addShippingForm.patchValue({
                'address' : '',
                'state' : '',
                'city' : '',
                'pincode' : '',
                'address_name' : ''
            });
            this.sma_shipping_id = '';
            this.sma_shipping_name = '';
        }
        this.modalType = modalType;
        this.adrsmdl(type);
    }
    adrsmdl(newadrs) {
        this.modalService.open(newadrs, { windowClass: 'lgmodal mblbtm' });
    }

    getshippingdetails() {
        this._personaldetailsservice.getshippingdetails(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.sma_shipping_details = data['records'][0];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    saveshippingform() {
        var data = {
            id: this.sma_shipping_id ? this.sma_shipping_id : '',
            type: this.modalType,
            address: this.addShippingForm.get('address').value,
            state: this.addShippingForm.get('state').value,
            city: this.addShippingForm.get('city').value,
            pincode: this.addShippingForm.get('pincode').value,
            address_name: this.addShippingForm.get('address_name').value,
        };
        this._personaldetailsservice.addshippingaddress(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.modalService.dismissAll();
                this.sma_shipping_id = '';
                this._toastr.success('New shipping address has been added successfully.');
                this.getshippingdetails();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getstate() {
        this._personaldetailsservice.getstate(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.stateArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    getcity(type='add') {
        var data = {};
        if(type == 'update'){
            data = { state: this.updateShippingForm.get('state').value };
        }else if(type == 'add'){
            data = { state: this.addShippingForm.get('state').value };
        }
        
        this._personaldetailsservice.getcity(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.cityArray = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    ratingclassstatus: boolean = false;
    ratingtglclass() {
        this.ratingclassstatus = !this.ratingclassstatus;
    }

    changedelivery(type) {
        this.selected_delivery = type;
    }

    getproductdetail(searchFilters = null, selectedSlug = null) {
        this._dealservice.getproductdetail({ sp_id: this.sp_id, market_place: this.market_place, filters: searchFilters, selectedSlug }).subscribe((data) => {
            if (data['status'] == "success") {
                this.detail = data['record'];
                this.chunked = [];
                let size = 2;
                for (let i = 0; i < this.detail.technical_feature.length; i += size) {
                    this.chunked.push(this.detail.technical_feature.slice(i, i + size))
                }
                this.sp_id = this.detail.sp_id;
                this.selected_address_text = this.detail.default_shipping_address.address;
                this.selected_address = this.detail.default_shipping_address.id;
                this.selected_billing_address_text = this.detail.billing_address;
                this.slides = [];
                if (this.detail.front_image) {
                    this.slides.push({ 'img': this.detail.front_image });
                }
                if (this.detail.back_image) {
                    this.slides.push({ 'img': this.detail.back_image });
                }
                if (this.detail.top_image) {
                    this.slides.push({ 'img': this.detail.top_image });
                }
                if (this.detail.side_image) {
                    this.slides.push({ 'img': this.detail.side_image });
                }
                if (this.detail.sales_image) {
                    this.slides.push({ 'img': this.detail.sales_image });
                }
                this.total_order_quantity = this.detail.min_qty;
                this.gettotalordervalue();
                this.getcomboproducts();
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    reviewsorting(){
        this.offset = 0;
        this.current_page = 1;
        this.getreviews();
    }

    getreviews() {
        let data = { 
            limit: this.page_per_view, 
            offset: this.offset, 
            current_page: this.current_page, 
            unique_id: this.detail.uniqueId, 
            rating: this.filter_by_rating,
            sorting: this.sort_by_rating, 
            product_id: this.detail.product_id, 
            seller_id: this.detail.s_id 
        };
        this._dealservice.getreviews(data).subscribe((data) => {
            if (data['status'] == "success") {
                this.review_records = data['records'];
                let total_records = data['total_records'];
                this.rating_total_records = data['total_records'];
                this.star_count = data['star_count'];
                this.review_pagination = this._pagination.getPager(total_records, this.current_page, this.page_per_view);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    setpage(page) {
        this.current_page = page;
        this.offset = (page - 1) * this.page_per_view;
        this.getreviews();
    }

    setpageperview() {
        this.current_page = 1;
        this.getreviews();
    }

    updatereviewfilters(val){
        if(this.filter_by_rating.indexOf(val) !== -1){
            var index = this.filter_by_rating.indexOf(val);
            this.filter_by_rating.splice(index, 1);
        } else{
            this.filter_by_rating.push(val);
        }
        this.reviewsorting();
    }

    public selected_variants;
    getselectedvariant(variant, meta) {
        for (let i = 0; i < meta.meta.length; i++) {
            if (variant.slug == meta.meta[i].slug) {
                meta.meta[i].selected = true;
            } else {
                meta.meta[i].selected = false;
            }
        }
        this.selected_variants = [];
        for (let i = 0; i < this.detail.product_variant.length; i++) {
            var selected_variant = this.detail.product_variant[i].meta.filter((product) => product.selected == true);
            if (selected_variant) {
                this.selected_variants.push(selected_variant[0].slug);
            }
        }
        this.getproductdetail(this.selected_variants, variant);
    }

    changebuynowquantity() {
        if (isNumber(this.buynow_order_quantity)) {
            this.buynow_order_quantity = toInteger(this.buynow_order_quantity);
        }  
        // else {
        //     this.buynow_order_quantity = 0';
        // }
    }

    addtocart(type) {
        var quantity;
        if (type == 'buynow') {
            if (!this.buynow_order_quantity && !this.detail.child) {
                this._toastr.error('Please enter quantity.');
                return false;
            }
            quantity = this.detail.child == false?this.buynow_order_quantity:0;
        } else {
            if (!this.total_order_quantity && !this.detail.child) {
                this._toastr.error('Please enter quantity.');
                return false;
            }
            quantity = this.detail.child == false?this.total_order_quantity:0;
        }
        var shipping_address = this.selected_address;
        if (!this.selected_delivery) {
            this._toastr.error('Please select logistic type.');
            return false;
        }

        var combo_products:any = [];
        var combo_quantity;
        if(!this.detail.child){
            for(var i =0; i< this.combo_products_list.length; i++){
                var selected = this.combo_products_list[i].selected;
                var selected_quantity = this.combo_products_list[i].selected_qty;
                if(selected && selected_quantity > 0 ){
                    var combo_id = this.combo_products_list[i].sp_id;
                    combo_products.push({sp_id: combo_id, quantity: selected_quantity });
                    combo_quantity = parseInt(combo_quantity) + parseInt(selected_quantity);
                }
            }
    
            var has_combo = combo_products.length > 0 ?true: false;
            var total_quantity = parseInt(quantity) + parseInt(combo_quantity);
            if (total_quantity < this.detail.min_qty) {
                this._toastr.error('Please enter quantity higher than minimum quantity '+this.detail.min_qty);
                return false;
            }
        }
        
        var child_products:any = [];
        let child_quantity:any = 0;
        if(this.detail.child){
            let child_records = this.detail.child_records;
            for(var i =0; i< child_records.length; i++){
                var selected = child_records[i].selected;
                var child_name = child_records[i].name;
                var selected_quantity = child_records[i].selected_qty;
                if(selected && selected_quantity > 0 ){
                    var child_id = child_records[i].id;
                    child_products.push({child_id: child_id, quantity: selected_quantity, name: child_name  });
                    child_quantity = parseInt(child_quantity) + parseInt(selected_quantity);
                }
            }
            var has_child = child_products.length > 0 ?true: false;
            var total_quantity = parseInt(quantity) + parseInt(child_quantity);
            if (total_quantity < this.detail.min_qty) {
                this._toastr.error('Please enter quantity higher than minimum quantity '+this.detail.min_qty);
                return false;
            }
        }

        let data = {
            sp_id: this.sp_id,
            market_place: this.market_place,
            quantity: quantity,
            shipping_address: shipping_address,
            delivery_type: this.selected_delivery,
            has_combo:has_combo,
            combo_products: combo_products,
            has_child: has_child,
            child_products: child_products
        };

        this._dealservice.addtocart(data).subscribe((data) => {
            if (data['status'] == "success") {
                if (data['exist'] == true) {
                    this.modalService.dismissAll();
                    this._toastr.error(data['msg']);
                    this._router.navigate(['/cart']);
                } else {
                    if (type == 'buynow') {
                        this._toastr.success('Product added in cart.');
                        this._router.navigate(['/cart']);
                    } else {
                        this._toastr.success('Product added in cart.');
                    }
                }
            } else if (data['status'] == "already") {
                this.modalService.dismissAll();
                this._router.navigate(['/cart']);
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    changeaddtocartquantity() {
        if (isNumber(this.total_order_quantity)) {
            this.total_order_quantity = toInteger(this.total_order_quantity);
        } else if (this.total_order_quantity < this.detail.min_qty) {
            this.total_order_quantity = this.detail.min_qty;
        } else {
            this.total_order_quantity = this.detail.min_qty;
        }
    }

    changeaddress(type, index) {
        this.selected_address_type = type;
        this.detail.shipping_address.forEach(function (value) {
            value.selected = false;
        });
        if (type == 'other') {
            this.selected_address_text = this.detail.shipping_address[index].address;
            this.detail.shipping_address[index].selected = true;
            this.detail.default_shipping_address_selected = false;
            this.selected_address = this.detail.shipping_address[index].id;
        } else {
            this.selected_address = index;
            this.detail.default_shipping_address_selected = true;
            this.selected_address_text = this.detail.default_shipping_address.address;
        }

    }

    gettotalordervalue() {
        this.total_order_value = this.total_order_quantity * this.detail.sale_price;
    }

    getproductsbyseller() {
        this._dealservice.getproductsbyseller({ sp_id: this.sp_id }).subscribe((data) => {
            if (data['status'] == "success") {
                this.productListing = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    showcomperr: boolean = false;
    showcompmsg() {
        this.showcomperr = true;
    }
    imagesSlider = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        asNavFor: ".thumbs",
    };
    thumbnailsSlider = {
        slidesToShow: 5,
        slidesToScroll: 1,
        dots: true,
        focusOnSelect: true,
        asNavFor: ".feedback",
        //prevArrow:'.client-thumbnails .prev-arrow',
        // nextArrow:'.client-thumbnails .next-arrow',
    };

    getlogisticscharges() {
        this._dealservice.getlogisticscharges(null).subscribe((data) => {
            if (data['status'] == "success") {
                this.express_records = data['expressrecord'];
                this.standard_city_records = data['standardwithincity'];
                this.standard_outsidecity_records = data['standardoutsidecity'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    open(content) {
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title', size: 'lg', centered: true, windowClass: 'modal-ui'
        }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }






    ratingst: boolean = false;

    ratingsort() {
        this.ratingst = !this.ratingst;
    }
    public val = null;
    config = {
        labelField: 'label',
        valueField: 'value',

    };

    data = [
        {
            label: 'Option 1',
            value: 'Option 1'
        },
        {
            label: 'Option 2',
            value: 'Option 2'
        },
        {
            label: 'Option 3',
            value: 'Option 3'
        }
    ]

    getcomboproducts() {
        this._dealservice.getcomboproducts({sp_id: this.sp_id, market_place: this.market_place}).subscribe((data) => {
            if (data['status'] == "success") {
                this.combo_products_list = data['records'];
            } else if (data['status'] == "error") {
                this._toastr.error(data['message']);
            }
        });
    }

    addcombo(rec){
        if(rec.selected_qty <= 0){
            this._toastr.error('Please enter minimum 1 quantity');
            return false;
        }
        rec.selected = true;
    }

    addchild(rec){
        if(rec.selected_qty < rec.min_qty){
            this._toastr.error('Please enter minimum '+rec.min_qty+' quantity');
            return false;
        }
        rec.selected = true;
    }

}
