<a class="mbl-loc-placeholder showintmbl" (click)="locationevent()" href="javascript:void(0);"></a>
<!--footer section -->
<footer [class]="class">
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row">
		        <div class="col-md-4">
		            <div class="mb-20">
		               <a href="javascript:;" (click)="gotohome()"><img src="assets/images/spice-logo.png"></a>
		            </div> 
		            <div class="alt-service-item">
		               <div class="alt-service-icon">
		                  <img src="assets/images/svg/location_blue.svg">
		               </div>
		               <div class="alt-services-desc">
		                  Office No: 206 A, Gambhir Industrial Estate,<br>Off. Aarey Road, Opp. Paramount Print,<br>Goregaon (East), Mumbai - 400063
		               </div>
		            </div>

		            <div class="alt-service-item">
		               <div class="alt-service-icon">
		                  <img src="assets/images/svg/email_blue.svg">
		               </div>
		               <div class="alt-services-desc">
		                  <a href="mailto:support@equibiz.in">support@equibiz.in</a>
		               </div>
		            </div>

		            <div class="alt-service-item">
		               <div class="alt-service-icon">
		                  <img src="assets/images/svg/phone_call.svg">
		               </div>
		               <div class="alt-services-desc">
		                  <a href="tel:18002102194">18002102194</a>
		               </div>
		            </div>
		        </div>

		        <div class="col-md-2">
		            <div class="widget-title">About Equibiz</div>
		            <div class="footer-links">
		               <ul>
		               		<li><a href="javascript:;" [routerLink]="['/how-it-works']">How it Works</a></li>
		                  	<li><a href="javascript:;" [routerLink]="['/about-us']">About Us</a></li>
		                  	<li><a href="javascript:;" [routerLink]="['/services']">Services</a></li>
		                  	<!-- <li><a href="javascript:;" [routerLink]="['/partners']">Partners</a></li> -->
		                  	<li><a href="javascript:;" [routerLink]="['/contact-us']">Contact Us</a></li>
		                  	<!-- <li *ngIf="!user_token"><a href="javascript:;" (click)="SignIn.openSigninModal('home')">Bids & Offers</a></li> -->
							<!-- <li *ngIf="user_token"><a href="javascript:;" [routerLink]="['/buyer/bids-offers']">Bids & Offers</a></li> -->
		                  	<!-- <li><a href="javascript:;" (click)="gotoseller()">Sell on Equibiz</a></li> -->
		               </ul>
		            </div>
		        </div>

		        <div class="col-md-3">
		            <div class="widget-title">Policy</div>
		            <div class="footer-links">
		               <ul>
		                  <li><a target="_blank" [routerLink]="['/terms-of-use']">Terms of Use</a></li>
		                  <li><a target="_blank" [routerLink]="['/privacy-policy']">Privacy Policy</a></li>
						  <li><a target="_blank" [routerLink]="['/return-policy']">Return Shipments Policy</a></li>
		                  <li><a target="_blank" [routerLink]="['/infringement-policy']">IP/Reports Infringement Policy</a></li>
		                  <li><a target="_blank" [routerLink]="['/anti-counterfeiting-policy']">Anti-Counterfeiting Policy</a></li>
		                  <li><a target="_blank" [routerLink]="['/logistics-policy']">Logistics Policy</a></li>
		               </ul>
		            </div>
		        </div>

		        <div class="col-md-3">
		            <div class="text-end">
		               <div class="widget-title">New to Equibiz?</div>
		               <div class="widget-text">
		                  Subscribe to our newsletter to get updates on our latest offers!
		               </div>
					   <form [formGroup]="subscribeForm" class="mb-30">
							<div class="subscribe-form mt-20 d-flex">
								<input placeholder='Enter email address' class='form-control' formControlName="subscribe_email"/>
								<span class="help-block" *ngIf="sf.subscribe_email.invalid && sf.subscribe_email.touched">Enter valid email address</span>
								<button class='btn email-send' [disabled]="buttonLoading || subscribeForm.invalid" (click)="subscribeemail()">
									<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="buttonLoading"></span>
	                                &nbsp;Send
								</button>
							</div>
						</form>

						<div class="widget-title">Follow Us On</div>
						<div class="social-media">
		                    <ul>
		                        <li><a href="https://www.linkedin.com/company/76971256/admin/" target="_blank"><img src='assets/images/svg/linkedin.svg'></a></li>
		                        <li><a href="https://www.facebook.com/trade.equibiz" target="_blank"><img src='assets/images/svg/fb_logo.svg'></a></li>
		                        <li><a href="https://www.instagram.com/equibiz_in/" target="_blank"><img src='assets/images/svg/instagram.svg'></a></li>
		                        <li><a href="https://wa.me/message/CBQLAPALVEPEI1" target="_blank"><img src='assets/images/whatsapp.png'></a></li>
		                    </ul>
		                </div>
		            </div>
		        </div>
		    </div>
		</div>
	</section>
	<div class="footer-bottom light-layout">
      	<div class="container">
         	<div class="footer-divider"></div>
         	<div class="row align-items-center justify-content-between">
	            <div class="col-md-6">
	               <div class="copyright-text">
	                  Equibiz © Digixpress Trade Private Limited. 2022
	               </div>
	            </div>

	            <div class="col-md-6 text-right mbl-center">
	               <div class="payment-options">
	                  <ul>
	                     <li><img src='assets/images/png/eb-wallet.png'></li>
	                     <li><img src='assets/images/png/visa.png'></li>
	                     <li><img src='assets/images/png/rupay.png'></li>
	                  </ul>
	               </div>
	            </div>
         	</div>
      	</div>
   </div>
</footer>
<!--footer section end -->
<!-- footer mobile -->
<div class="mb-footer text-center"> 
	<div class="row">
		<div class="col-3">
			<a href="javascript:;" (click)="gotohome()" class="d-inline-block w-100">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="21.3" height="19.967" viewBox="0 0 21.3 19.967">
					  <g id="browser" transform="translate(7 5)">
						<path id="Path_321" data-name="Path 321" d="M56.058,204.611H41.914A1.916,1.916,0,0,1,40,202.7V192.629a.666.666,0,0,1,1.331,0V202.7a.583.583,0,0,0,.582.582H56.058a.583.583,0,0,0,.582-.582V192.629a.666.666,0,0,1,1.331,0V202.7A1.916,1.916,0,0,1,56.058,204.611Z" transform="translate(-45.336 -189.643)" fill="#1f1d44"/>
						<path id="Path_322" data-name="Path 322" d="M20.635,26.332a.663.663,0,0,1-.471-.195l-8.278-8.278a1.749,1.749,0,0,0-2.471,0L1.137,26.137A.666.666,0,0,1,.2,25.2l8.278-8.278a3.082,3.082,0,0,1,4.354,0L21.105,25.2a.666.666,0,0,1-.471,1.136Z" transform="translate(-7 -21.017)" fill="#1f1d44"/>
						<path id="Path_323" data-name="Path 323" d="M181.991,296.636h-5.325a.666.666,0,0,1-.666-.666v-5.907a2.082,2.082,0,0,1,2.08-2.08h2.5a2.082,2.082,0,0,1,2.08,2.08v5.907A.666.666,0,0,1,181.991,296.636Zm-4.659-1.331h3.994v-5.242a.75.75,0,0,0-.749-.749h-2.5a.75.75,0,0,0-.749.749Z" transform="translate(-175.678 -281.669)" fill="#1f1d44"/>
					  </g>
					</svg>
				</span>			
				<p class="txtdefault fs12">Home</p>
			</a>
		</div>
		<div class="col-3">
			<a href="javascript:void(0);" (click)="swcatgkEvent()" class="d-inline-block w-100">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="18.629" height="18.629" viewBox="0 0 18.629 18.629">
					  <g id="Group_13684" data-name="Group 13684" transform="translate(-1211.686 -2122.87)">
						<g id="Group_13582" data-name="Group 13582" transform="translate(1211.686 2133.115)">
						  <path id="Path_2335" data-name="Path 2335" d="M8.3,43.383H4.08A2.08,2.08,0,0,1,2,41.3V37.08A2.08,2.08,0,0,1,4.08,35H9.762a.623.623,0,0,1,.621.621V41.3a2.08,2.08,0,0,1-2.08,2.08ZM4.08,36.242a.835.835,0,0,0-.838.838V41.3a.835.835,0,0,0,.838.838H8.3a.835.835,0,0,0,.838-.838V36.242Z" transform="translate(-2 -35)" fill="#1f1d44"/>
						</g>
						<g id="Group_13583" data-name="Group 13583" transform="translate(1221.931 2122.87)">
						  <path id="Path_2336" data-name="Path 2336" d="M41.3,10.383H35.621A.623.623,0,0,1,35,9.762V4.08A2.08,2.08,0,0,1,37.08,2H41.3a2.08,2.08,0,0,1,2.08,2.08V8.3a2.08,2.08,0,0,1-2.08,2.08ZM36.242,9.141H41.3a.835.835,0,0,0,.838-.838V4.08a.835.835,0,0,0-.838-.838H37.08a.835.835,0,0,0-.838.838Z" transform="translate(-35 -2)" fill="#1f1d44"/>
						</g>
						<g id="Group_13584" data-name="Group 13584" transform="translate(1211.686 2122.87)">
						  <path id="Path_2337" data-name="Path 2337" d="M9.762,10.383H4.08A2.08,2.08,0,0,1,2,8.3V4.08A2.08,2.08,0,0,1,4.08,2H8.3a2.08,2.08,0,0,1,2.08,2.08V9.762a.623.623,0,0,1-.621.621ZM4.08,3.242a.835.835,0,0,0-.838.838V8.3a.835.835,0,0,0,.838.838H9.141V4.08A.835.835,0,0,0,8.3,3.242Z" transform="translate(-2 -2)" fill="#1f1d44"/>
						</g>
						<g id="Rectangle_1420" data-name="Rectangle 1420" transform="translate(1221.934 2133.115)" fill="none" stroke="#1f1d44" stroke-width="1.3">
						  <path d="M.168,0H4.19A4.19,4.19,0,0,1,8.38,4.19v0A4.19,4.19,0,0,1,4.19,8.38h0A4.19,4.19,0,0,1,0,4.19V.168A.168.168,0,0,1,.168,0Z" stroke="none"/>
						  <path d="M1.016.65H4.19A3.54,3.54,0,0,1,7.73,4.19v0A3.54,3.54,0,0,1,4.19,7.73h0A3.54,3.54,0,0,1,.65,4.19V1.016A.366.366,0,0,1,1.016.65Z" fill="none"/>
						</g>
					  </g>
					</svg>

				</span>			
				<p class="txtdefault fs12">Categories</p>
			</a>
		</div>
		<!-- <div class="col-3">
			<a href="javascript:void(0);" class="d-inline-block w-100" [routerLink]="['/equibiz-pay-later']">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20.294" height="20.294" viewBox="0 0 20.294 20.294">
					  <g id="market" transform="translate(0.1 0.1)">
						<g id="Group_13954" data-name="Group 13954">
						  <g id="Group_13953" data-name="Group 13953">
							<path id="Path_2384" data-name="Path 2384" d="M19.7,19.309H17.975V9.791h1.078a.392.392,0,0,0,.278-.67L10.325.115a.392.392,0,0,0-.555,0L6.846,3.039a.392.392,0,1,0,.555.555L10.047.948l8.059,8.059H16.547L10.325,2.784a.392.392,0,0,0-.555,0L3.547,9.006H1.988L5.046,5.949a.392.392,0,1,0-.555-.555L.763,9.121a.392.392,0,0,0,.278.67H2.119v9.518H.392a.392.392,0,0,0,0,.785H19.7a.392.392,0,1,0,0-.785ZM17.19,17.425H14.4a.392.392,0,0,0,0,.785H17.19v1.1H2.9V16.371l1.518-1.518,1.319,1.319a.392.392,0,0,0,.555,0l2.882-2.882,2.359,2.359a.393.393,0,0,0,.555,0L15.7,12.043v.124a.392.392,0,1,0,.785,0V11.132a.392.392,0,0,0-.392-.392H15.057a.392.392,0,0,0,0,.785h.051l-3.293,3.293L9.456,12.458a.392.392,0,0,0-.555,0L6.019,15.34,4.7,14.021a.392.392,0,0,0-.555,0L2.9,15.261V9.791H3.71a.393.393,0,0,0,.278-.115l6.06-6.06,6.06,6.06a.393.393,0,0,0,.278.115h.805Z" fill="#1f1d44" stroke="#1f1d44" stroke-width="0.2"/>
						  </g>
						</g>
						<g id="Group_13956" data-name="Group 13956" transform="translate(5.593 4.062)">
						  <g id="Group_13955" data-name="Group 13955">
							<path id="Path_2385" data-name="Path 2385" d="M143.17,103.615a.392.392,0,1,0,.115.277A.4.4,0,0,0,143.17,103.615Z" transform="translate(-142.5 -103.5)" fill="#1f1d44"/>
						  </g>
						</g>
						<g id="Group_13958" data-name="Group 13958" transform="translate(12.627 17.425)">
						  <g id="Group_13957" data-name="Group 13957">
							<path id="Path_2386" data-name="Path 2386" d="M322.4,444.115a.392.392,0,1,0,.115.277A.4.4,0,0,0,322.4,444.115Z" transform="translate(-321.73 -444)" fill="#1f1d44"/>
						  </g>
						</g>
					  </g>
					</svg>

				</span>			
				<p class="txtdefault fs12">Pay Later</p>
			</a>
		</div> -->
		<div class="col-3">
			<a href="javascript:void(0);" class="d-inline-block w-100" (click)="moreEvent()">
				<span>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="5.88" viewBox="0 0 20 5.88">
					  <g id="Group_13963" data-name="Group 13963" transform="translate(-5816 -994)">
						<g id="Group_189" data-name="Group 189" transform="translate(5830.121 994)">
						  <g id="Group_188" data-name="Group 188">
							<path id="Path_327" data-name="Path 327" d="M364.424,180.742a2.94,2.94,0,1,0,2.94,2.94A2.943,2.943,0,0,0,364.424,180.742Zm0,4.434a1.494,1.494,0,1,1,1.494-1.494A1.5,1.5,0,0,1,364.424,185.176Z" transform="translate(-361.484 -180.742)" fill="#1f1d44"/>
						  </g>
						</g>
						<g id="Group_191" data-name="Group 191" transform="translate(5823.061 994)">
						  <g id="Group_190" data-name="Group 190">
							<path id="Path_328" data-name="Path 328" d="M183.682,180.742a2.94,2.94,0,1,0,2.94,2.94A2.943,2.943,0,0,0,183.682,180.742Zm0,4.434a1.494,1.494,0,1,1,1.494-1.494A1.5,1.5,0,0,1,183.682,185.176Z" transform="translate(-180.742 -180.742)" fill="#1f1d44"/>
						  </g>
						</g>
						<g id="Group_13962" data-name="Group 13962" transform="translate(5816 994)">
						  <g id="Group_190-2" data-name="Group 190">
							<path id="Path_328-2" data-name="Path 328" d="M183.682,180.742a2.94,2.94,0,1,0,2.94,2.94A2.943,2.943,0,0,0,183.682,180.742Zm0,4.434a1.494,1.494,0,1,1,1.494-1.494A1.5,1.5,0,0,1,183.682,185.176Z" transform="translate(-180.742 -180.742)" fill="#1f1d44"/>
						  </g>
						</g>
					  </g>
					</svg>
				</span>			
				<p class="txtdefault fs12">More</p>
			</a>
		</div>
	</div>
</div>
<!-- /footer mobile -->
<!-- LOCATION -->
<div class="moresidebr" [ngClass]="locstatus ? 'showcatg' : ''">  
	<h4 class="txtdefault fs26 mb-3 px-3 pt-4 pb-2 mb-0"> <a (click)="locationevent()" href="javascript:void(0);" ><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Group_14366" data-name="Group 14366" transform="translate(-140 -468)">
			<path id="back_3_" data-name="back (3)" d="M19.288,10.1H3.973L8.583,5.487A.871.871,0,1,0,7.351,4.255l-6.1,6.1a.871.871,0,0,0,0,1.231l6.1,6.1a.871.871,0,1,0,1.231-1.231L3.973,11.838H19.288a.871.871,0,0,0,0-1.742Z" transform="translate(141.42 469.033)" fill="#1f1d44"/>
			<g id="Rectangle_1447" data-name="Rectangle 1447" transform="translate(140 468)" fill="none" stroke="#707070" stroke-width="1" opacity="0">
			  <rect width="24" height="24" stroke="none"/>
			  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
			</g>
		  </g>
		</svg>
		</a> Location
	</h4>	  
	<div class="p-3">
		<div class="mbl-loc">
		   <input class="form-control py-2 border" type="text" placeholder="Search here" id="example-search-input">
		   <img src="assets/images/svg/search_logo.svg">
		</div>
	</div>
</div>
<!-- /LOCATION -->
<!-- MORE SIDEBAR -->
<div class="moresidebr" *ngIf="user_token" [ngClass]="morestatus ? 'showcatg' : ''">  
	<h4 class="fs18 f-700 mb-3 p-3 mb-0"> <a href="javascript:void(0);" (click)="moreEvent()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Group_14366" data-name="Group 14366" transform="translate(-140 -468)">
			<path id="back_3_" data-name="back (3)" d="M19.288,10.1H3.973L8.583,5.487A.871.871,0,1,0,7.351,4.255l-6.1,6.1a.871.871,0,0,0,0,1.231l6.1,6.1a.871.871,0,1,0,1.231-1.231L3.973,11.838H19.288a.871.871,0,0,0,0-1.742Z" transform="translate(141.42 469.033)" fill="#1f1d44"/>
			<g id="Rectangle_1447" data-name="Rectangle 1447" transform="translate(140 468)" fill="none" stroke="#707070" stroke-width="1" opacity="0">
			  <rect width="24" height="24" stroke="none"/>
			  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
			</g>
		  </g>
		</svg>
		</a> More</h4>
	<div class="more-avt-wrp mb-3">
		<div class="d-table">
			<div class="d-table-cell">
				<div class="more-avt">
					<img [src]='user_details.profile_image'>
				</div>
			</div>
			<div class="d-table-cell pl-2">
				<h4 class="fs16 txtdefault mt-0">Hi, {{ user_details.name }}</h4>
				<p class="txtdefault mb-0">{{ user_details.wallet_amount }}</p>
			</div>
		</div>
	</div>

	<ul class="nav">
		<li class="nav-item">
			<div class="nav-link" [routerLink]="['/buyer/dashboard']">EB Dashboard</div>
		</li>
	</ul>
	<div class="accordionarrow moreacrd">
		<ngb-accordion  [closeOthers]="true" #moreacc="ngbAccordion" activeIds="ngb-panel-0">
			<ngb-panel>
				<ng-template ngbPanelHeader>
				  <div class="d-flex align-items-center justify-content-between">
					<button  class="btn btn-link alt noarrow" [routerLink]="['/buyer/dashboard']">
						<svg xmlns="http://www.w3.org/2000/svg" width="20.737" height="13.226" viewBox="0 0 20.737 13.226">
							<path id="np_dashboard_637107_000000" d="M22.77,24.09a8.87,8.87,0,0,0-9.141,8.8c.114,3.2,1.371,3.428,4.113,3.428H28.254c2.743,0,3.657-.457,3.657-3.428A9.164,9.164,0,0,0,22.77,24.09Zm1.713,6.171s-.394.737-.736,1.422-.427.853-.634,1.32a1.232,1.232,0,0,1,0,1.257,1.117,1.117,0,0,1-1.6.457,1.179,1.179,0,0,1-.571-1.6c.229-.343.571-.8,1.143-.685l1.371-2.743c.114-.229.343-.685.914-.343a.542.542,0,0,1,.114.914Zm5.6,2.628h-.571c-.229,0-.457-.343-.571-.571a5.953,5.953,0,0,0-6.17-5.141A5.948,5.948,0,0,0,16.6,32.432a.612.612,0,0,1-.571.571h-.571a.64.64,0,0,1-.571-.685,7.526,7.526,0,0,1,7.884-6.856,7.57,7.57,0,0,1,7.77,6.856c.114.228-.115.57-.458.57Z" transform="translate(-13.126 -23.59)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.8"/>
						</svg>
						Dashboard 
					</button>					
				  </div>
				</ng-template>
				
			</ngb-panel>
			<ngb-panel>
				<ng-template ngbPanelHeader>
				  <div class="d-flex align-items-center justify-content-between">
					
					<button ngbPanelToggle class="btn btn-link alt">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="12.973" height="15" viewBox="0 0 12.973 15">
						  <defs>
							<linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
							  <stop offset="0" stop-color="#79b7ff"/>
							  <stop offset="1" stop-color="#07f"/>
							</linearGradient>
						  </defs>
						  <path id="np_profile_1594246_000000" d="M46.973,46.595a.406.406,0,0,1-.4.405H34.4a.407.407,0,0,1-.4-.405,6.486,6.486,0,0,1,12.973,0Zm-6.486-7.3a3.649,3.649,0,1,1,3.649-3.649A3.65,3.65,0,0,1,40.486,39.3Z" transform="translate(-34 -32)" fill-rule="evenodd" fill="url(#linear-gradient)"/>
						</svg>
					Account Profile
					</button>
				  </div>
				</ng-template>
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">
					  <li class="nav-item">
						<a href="javascript:;" class="nav-link active fs14 textsecondary" [routerLink]="['/buyer/personal-details']">Personal Details</a>
					  </li>
					  <!-- <li class="nav-item">
						<a href="javascript:;" class="nav-link fs14 textsecondary" [routerLink]="['/buyer/business-details']">Business Details</a>
					  </li>
					  <li class="nav-item ">
						<a href="javascript:;" class="nav-link fs14 textsecondary" [routerLink]="['/buyer/bank-details']">Bank Details</a>
					  </li>				  -->
					</ul>  
				</ng-template>
			</ngb-panel>
			<!-- <ngb-panel>
				<ng-template ngbPanelHeader>
				  <div class="d-flex align-items-center justify-content-between">
					<button  class="btn btn-link alt noarrow" [routerLink]="['/buyer/buyer-buy-now-pay-later']">
						<svg xmlns="http://www.w3.org/2000/svg" width="20.737" height="13.226" viewBox="0 0 20.737 13.226">
							<path id="np_dashboard_637107_000000" d="M22.77,24.09a8.87,8.87,0,0,0-9.141,8.8c.114,3.2,1.371,3.428,4.113,3.428H28.254c2.743,0,3.657-.457,3.657-3.428A9.164,9.164,0,0,0,22.77,24.09Zm1.713,6.171s-.394.737-.736,1.422-.427.853-.634,1.32a1.232,1.232,0,0,1,0,1.257,1.117,1.117,0,0,1-1.6.457,1.179,1.179,0,0,1-.571-1.6c.229-.343.571-.8,1.143-.685l1.371-2.743c.114-.229.343-.685.914-.343a.542.542,0,0,1,.114.914Zm5.6,2.628h-.571c-.229,0-.457-.343-.571-.571a5.953,5.953,0,0,0-6.17-5.141A5.948,5.948,0,0,0,16.6,32.432a.612.612,0,0,1-.571.571h-.571a.64.64,0,0,1-.571-.685,7.526,7.526,0,0,1,7.884-6.856,7.57,7.57,0,0,1,7.77,6.856c.114.228-.115.57-.458.57Z" transform="translate(-13.126 -23.59)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.8"/>
						</svg>
						Equibiz Pay Later 
					</button>					
				  </div>
				</ng-template>
			</ngb-panel> -->
			<ngb-panel>
				<ng-template ngbPanelHeader>
				  <div class="d-flex align-items-center justify-content-between">
					<button ngbPanelToggle class="btn btn-link alt">
						<svg xmlns="http://www.w3.org/2000/svg" width="15.473" height="15.522" viewBox="0 0 15.473 15.522">
						  <g id="np_wallet_1594672_000000" transform="translate(-2.7 -2.5)" opacity="0.8">
							<path id="Path_2329" data-name="Path 2329" d="M12.977,13.137v-1.8a1.855,1.855,0,0,1,1.846-1.846h2.418V8.17A1.346,1.346,0,0,0,15.9,6.83V6.062a1.75,1.75,0,0,0-1.748-1.748H12.161L10.853,2.5,8.321,4.314H4.432A1.729,1.729,0,0,0,2.7,5.965V16.437a1.586,1.586,0,0,0,1.585,1.585H15.657a1.586,1.586,0,0,0,1.585-1.585V14.983H14.823a1.844,1.844,0,0,1-1.846-1.846Zm1.176-7.843a.767.767,0,0,1,.768.768V6.83h-.964L12.863,5.294ZM12.748,6.83H6.49l4.134-2.974ZM4.432,5.294H6.965L4.824,6.83H4.432a.768.768,0,1,1,0-1.536Z" fill="#5e6a7c"/>
							<path id="Path_2330" data-name="Path 2330" d="M74,49.6H71.042A1.137,1.137,0,0,0,69.9,50.746v1.8a1.137,1.137,0,0,0,1.144,1.144H74a.4.4,0,0,0,.392-.392v-3.3A.387.387,0,0,0,74,49.6Zm-2.337,2.892a.842.842,0,1,1,0-1.683.841.841,0,0,1,.834.849A.817.817,0,0,1,71.663,52.494Z" transform="translate(-56.219 -39.406)" fill="#5e6a7c"/>
						  </g>
						</svg>
					Wallet & Payments
					</button>
				  </div>
				</ng-template>
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">
					  <!-- <li class="nav-item">
						<a href="javascript:;" [routerLink]="['/buyer/buyer-wallet']" class="nav-link active fs14 textsecondary">My Wallet</a>
					  </li>
					  <li class="nav-item">
						<a href="javascript:;" [routerLink]="['/buyer/buyer-manage-payment-methods']" class="nav-link fs14 textsecondary">Manage Payment Methods</a>
					  </li> -->
					  <li class="nav-item ">
						<a href="javascript:;" [routerLink]="['/buyer/buyer-transaction-history']" class="nav-link fs14 textsecondary">Transaction History</a>
					  </li>				 
					</ul>  
				</ng-template>
			</ngb-panel>
			<ngb-panel>
				<ng-template ngbPanelHeader>
				  <div class="d-flex align-items-center justify-content-between">
					<button ngbPanelToggle class="btn btn-link alt ">
						<svg xmlns="http://www.w3.org/2000/svg" width="15.613" height="16.784" viewBox="0 0 15.613 16.784">
						  <path id="np_box_848942_000000" d="M17.806,7,20.38,8.031l-6.849,3.08-3-1.2Zm4.4,1.763,2.873,1.147-7.276,2.909-2.744-1.1Zm3.4,1.781V20.856L18.2,23.784V13.507ZM10,10.543l3.123,1.25v3.013l1.561.585V12.416l2.732,1.092V23.784L10,20.856Z" transform="translate(-10 -7)" fill="#5e6a7c" opacity="0.8"/>
						</svg>	All Orders 
					</button>
				  </div>
				</ng-template>
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">
						<!-- <li class="nav-item ">
							<a href="javascript:;" [routerLink]="['/buyer/bids-offers']" class="nav-link fs14 textsecondary" href="javascript:void(0);">Bids & Offers</a>
						</li>
						<li class="nav-item ">
							<a href="javascript:;" [routerLink]="['/buyer/my-bids']" class="nav-link fs14 textsecondary" href="javascript:void(0);">My Bids</a>
						</li> -->
					  <li class="nav-item ">
						<a href="javascript:;" [routerLink]="['/buyer/buyer-track-orders']" class="nav-link fs14 textsecondary" href="javascript:void(0);">Track My Orders</a>
					  </li>	
					  <li class="nav-item ">
						<a href="javascript:;" [routerLink]="['/buyer/order-history']" class="nav-link fs14 textsecondary" href="javascript:void(0);">Order History</a>
					  </li>		 
					</ul> 
				</ng-template>
			</ngb-panel>
		
			<ngb-panel>
				<ng-template ngbPanelHeader>
				  <div class="d-flex align-items-center justify-content-between">
					<button ngbPanelToggle class="btn btn-link alt">
						<svg xmlns="http://www.w3.org/2000/svg" width="15.973" height="15.973" viewBox="0 0 15.973 15.973">
							<path data-name="Path 8691" d="M61.318 23.332a7.986 7.986 0 1 0 7.982 7.986 7.985 7.985 0 0 0-7.982-7.986zm4.09 8.482-3.524 3.663a.8.8 0 0 1-1.129 0l-3.524-3.663a2.395 2.395 0 1 1 3.386-3.388l.7.7.7-.7a2.4 2.4 0 1 1 3.388 3.388z" transform="translate(-53.332 -23.332)" style="fill:#5e6a7c;opacity:.8"/>
						</svg>
					My Wishlist 
					</button>
				  </div>
				</ng-template>
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">
					  <li class="nav-item">
						<a class="nav-link  fs14 textsecondary" [routerLink]="['/buyer/buyer-wishlist']" href="javascript:void(0);">Wishlist</a>
					  </li>			 
					</ul> 
				</ng-template>
			</ngb-panel>
			<ngb-panel>
				<ng-template ngbPanelHeader>
				  <div class="d-flex align-items-center justify-content-between">
					<button ngbPanelToggle class="btn btn-link alt">
						<svg xmlns="http://www.w3.org/2000/svg" width="15.318" height="15.318" viewBox="0 0 15.318 15.318">
							<path d="M27.818 22.712V25.9a1.915 1.915 0 0 1-1.918 1.918h-3.188A1.915 1.915 0 0 1 20.8 25.9v-3.188a1.915 1.915 0 0 1 1.912-1.912H25.9a1.915 1.915 0 0 1 1.918 1.912zM17.606 12.5h-3.191a1.915 1.915 0 0 0-1.915 1.915v3.191a1.915 1.915 0 0 0 1.915 1.915h3.191a1.915 1.915 0 0 0 1.915-1.915v-3.191a1.915 1.915 0 0 0-1.915-1.915zm6.7 7.021a3.516 3.516 0 1 0-2.48-1.03 3.51 3.51 0 0 0 2.48 1.03zm-6.7 1.277h-3.191a1.915 1.915 0 0 0-1.915 1.914V25.9a1.915 1.915 0 0 0 1.915 1.915h3.191a1.915 1.915 0 0 0 1.915-1.915v-3.188a1.915 1.915 0 0 0-1.915-1.912z" transform="translate(-12.5 -12.5)" style="fill:#5e6a7c;opacity:.8"/>
						</svg>
					Select Product Categories
					</button>
				  </div>
				</ng-template>
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">
					  <li class="nav-item">
						<a class="nav-link fs14 textsecondary" [routerLink]="['/buyer/manage-categories']">Manage Categories</a>
					  </li>		 
					</ul> 
				</ng-template>
			</ngb-panel>
			<ngb-panel>
				<ng-template ngbPanelHeader>
				  <div class="d-flex align-items-center justify-content-between">
					<button ngbPanelToggle class="btn btn-link alt">
						<svg xmlns="http://www.w3.org/2000/svg" width="17.526" height="17.526" viewBox="0 0 17.526 17.526">
						  <path id="np_tax_3953297_000000" d="M18.946,9.135V6.59l3.122,3.122H19.523A.577.577,0,0,1,18.946,9.135Zm3.463,1.731v9.811a1.732,1.732,0,0,1-1.731,1.731H14.329A5.771,5.771,0,0,0,9.712,12.136V7.981a1.732,1.732,0,0,1,1.731-1.731h6.348V9.135a1.732,1.732,0,0,0,1.731,1.731Zm-5.771-.577a.576.576,0,0,0-.577-.577H12.6a.577.577,0,0,0,0,1.154h3.463a.576.576,0,0,0,.577-.577ZM20.1,18.946a.576.576,0,0,0-.577-.577H18.369a.577.577,0,0,0,0,1.154h1.154a.576.576,0,0,0,.577-.577Zm0-2.886a.576.576,0,0,0-.577-.577H18.369a.577.577,0,1,0,0,1.154h1.154a.576.576,0,0,0,.577-.577Zm0-2.886a.576.576,0,0,0-.577-.577H17.215a.577.577,0,0,0,0,1.154h2.308a.576.576,0,0,0,.577-.577Zm-4.617,4.617a4.618,4.618,0,1,1-1.353-3.264A4.615,4.615,0,0,1,15.483,17.792ZM9.135,16.638a.577.577,0,1,0,.577-.577.577.577,0,0,0-.577.577ZM12.6,18.946a.576.576,0,1,0-.169.408.577.577,0,0,0,.169-.408Zm-.167-2.718a.577.577,0,0,0-.82,0L9.3,18.536a.579.579,0,1,0,.82.82l2.308-2.308a.577.577,0,0,0,0-.82Z" transform="translate(-5.75 -5.383)" fill="#5e6a7c" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" opacity="0.8"/>
						</svg> Deal Invoices and Documents
					</button>
				  </div>
				</ng-template>
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">
					  <li class="nav-item">
						<a href="javascript:;" class="nav-link  fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/buyer/invoice']">Order Invoices</a>
					  </li>
					  <li class="nav-item">
						<a href="javascript:;" class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/buyer/charges-invoice']">Charges Invoices</a>
					  </li>
					  <li class="nav-item ">
						<a href="javascript:;" class="nav-link fs14 textsecondary" href="javascript:void(0);" [routerLink]="['/buyer/transportation-documents']">Transportation Docs</a>
					  </li>				 
					</ul>  
				</ng-template>
			</ngb-panel>
			<ngb-panel>
				<ng-template ngbPanelHeader>
				  <div class="d-flex align-items-center justify-content-between">
					<button ngbPanelToggle class="btn btn-link alt">
						<svg xmlns="http://www.w3.org/2000/svg" width="14.875" height="17" viewBox="0 0 14.875 17">
						  <g id="np_report_3888336_000000" transform="translate(-6.25)" opacity="0.8">
							<path id="Path_2331" data-name="Path 2331" d="M34.375.531A.531.531,0,0,1,34.906,0h4.25a.531.531,0,0,1,.531.531V2.656a.531.531,0,0,1-.531.531h-4.25a.531.531,0,0,1-.531-.531Z" transform="translate(-23.344)" fill="#5e6a7c"/>
							<path id="Path_2332" data-name="Path 2332" d="M8.906,6.25H9.969V8.729a.727.727,0,0,0,.744.709h5.95a.727.727,0,0,0,.744-.709V6.25h1.063a2.656,2.656,0,0,1,2.656,2.656V19.531a2.656,2.656,0,0,1-2.656,2.656H8.906A2.656,2.656,0,0,1,6.25,19.531V8.906A2.656,2.656,0,0,1,8.906,6.25ZM10.5,18.469a.531.531,0,0,0,1.063,0V12.094a.531.531,0,0,0-1.063,0ZM13.688,19a.531.531,0,0,1-.531-.531v-4.25a.531.531,0,0,1,1.063,0v4.25A.531.531,0,0,1,13.688,19Zm2.656,0a.531.531,0,0,1-.531-.531V16.344a.531.531,0,0,1,1.062,0v2.125A.531.531,0,0,1,16.344,19Z" transform="translate(0 -5.188)" fill="#5e6a7c" fill-rule="evenodd"/>
						  </g>
						</svg>
						Business Reports
					</button>
				  </div>
				</ng-template>
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">
					  <li class="nav-item">
						<a href="javascript:;" class="nav-link  fs14 textsecondary" href="javascript:void(0);">Tax Report</a>
					  </li>
					  <li class="nav-item">
						<a href="javascript:;" class="nav-link fs14 textsecondary" href="javascript:void(0);">Purchase Report</a>
					  </li>
					  <li class="nav-item ">
						<a href="javascript:;" class="nav-link fs14 textsecondary" href="javascript:void(0);">Payment Report</a>
					  </li>	
					  <li class="nav-item ">
						<a href="javascript:;"  class="nav-link fs14 textsecondary" href="javascript:void(0);">Order Report</a>
					  </li>				 
					</ul>  
				</ng-template>
			</ngb-panel>
			<ngb-panel>
				<ng-template ngbPanelHeader>
				  <div class="d-flex align-items-center justify-content-between">
					<button  class="btn btn-link alt noarrow" (click)="gotologout()">
						<svg xmlns="http://www.w3.org/2000/svg" width="15.231" height="20.444" viewBox="0 0 15.231 20.444">
							<g id="np_log-out_738609_000000" opacity="0.8">
							<path id="Path_2333" data-name="Path 2333" d="M12.929.319q2.968.749,5.938,1.487c.174.043.18.147.18.287q.006,3.19.016,6.38.008,4.62.017,9.239c0,.185-.041.276-.225.34q-3.349,1.154-6.691,2.326c-.061.021-.123.035-.213.061-.008-.149-.02-.28-.021-.409q-.031-3.31-.062-6.619-.057-5.619-.117-11.238C11.744,1.447,11.73.722,11.719,0h.039c.388.108.778.224,1.171.323Zm-.353,10.475a.4.4,0,0,0,.64-.043.832.832,0,0,0,.1-.863.507.507,0,0,0-.493-.348.976.976,0,0,0-.245.147.862.862,0,0,0,0,1.107Z" transform="translate(-3.849 0.004)" fill="#5e6a7c"/>
							<path id="Path_2334" data-name="Path 2334" d="M54.971,7.508c-.173,0-.235.042-.233.232.009,1.213,0,2.426,0,3.64v.41c-.311,0-.626,0-.932-.009-.03,0-.078-.123-.078-.189-.006-.753,0-1.506,0-2.26V6.693c0-.12,0-.214.169-.212,1.18.006,2.361,0,3.542,0h0c.033,0,.067,0,.1.01V7.512H57.3c-.774,0-1.551,0-2.331,0Z" transform="translate(-50.661 -5.155)" fill="#5e6a7c"/>
							<path id="Path_2335" data-name="Path 2335" d="M53.136,67.577c.263.011.533,0,.805,0v3.631H56.9V72.26c-.078,0-.119.01-.176.01-1.192,0-2.384,0-3.575,0-.176,0-.217-.055-.216-.225q.008-2.129,0-4.257c0-.166.047-.22.207-.216Z" transform="translate(-49.863 -53.76)" fill="#5e6a7c"/>
							<path id="Path_2336" data-name="Path 2336" d="M57.468,39.009a.637.637,0,0,0,.272.394q.889.679,1.772,1.366a.468.468,0,0,0,.73-.156h0a.67.67,0,0,0,.05-.233c.008-.15,0-.3,0-.462a3.187,3.187,0,0,1,1.963.668,3.368,3.368,0,0,1,1.191,1.736,3.364,3.364,0,0,0-3.155-4.347V37.5h0a.477.477,0,0,0-.171-.378.461.461,0,0,0-.6,0c-.23.176-.459.354-.688.53-.363.28-.724.562-1.089.839a.627.627,0,0,0-.272.394v.12Z" transform="translate(-57.468 -29.444)" fill="#5e6a7c"/>
							</g>
						</svg>
						Logout 
					</button>					
				  </div>
				</ng-template>
				
			</ngb-panel>
			
		</ngb-accordion>
	</div>
</div>
<!-- /MORE SIDEBAR -->
<!-- MY WALLET -->
<div class="moresidebr" *ngIf="user_token && user_details && user_details.name" [ngClass]="walletstatus ? 'showcatg' : ''">
	<h4 class="fs18 f-700 mb-3 p-3 mb-0">
		<a href="javascript:void(0);" (click)="walletEvent()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Group_14366" data-name="Group 14366" transform="translate(-140 -468)">
			<path id="back_3_" data-name="back (3)" d="M19.288,10.1H3.973L8.583,5.487A.871.871,0,1,0,7.351,4.255l-6.1,6.1a.871.871,0,0,0,0,1.231l6.1,6.1a.871.871,0,1,0,1.231-1.231L3.973,11.838H19.288a.871.871,0,0,0,0-1.742Z" transform="translate(141.42 469.033)" fill="#1f1d44"/>
			<g id="Rectangle_1447" data-name="Rectangle 1447" transform="translate(140 468)" fill="none" stroke="#707070" stroke-width="1" opacity="0">
			  <rect width="24" height="24" stroke="none"/>
			  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
			</g>
		  </g>
		</svg>
		</a> 
	Wallet & Payment</h4>
	<div class="more-avt-wrp mb-3">
		<div class="d-table">
			<div class="d-table-cell">
				<img src='assets/images/svg/wallet.svg' />				
			</div>
			<div class="d-table-cell pl-2">
				<h4 class="fs14 txtsecondary mt-0">My Wallet</h4>
				<p class="txtdefault weight700 fs24 mb-0">{{ user_details.wallet_amount }}</p>
			</div>
		</div>
		<i class="arrowright"></i>	
	</div>
	<div class="accordionarrow moreacrd morewallet">
		<ngb-accordion #moreacc="ngbAccordion" activeIds="wlt-panel">
			<ngb-panel id="wlt-panel" title="EB Dashboard">
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">						  
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" (click)="moreEvent('/buyer/buyer-manage-payment-methods')">Manage Payment Methods</a>
						</li>
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" (click)="moreEvent('/buyer/buyer-transaction-history')">Transaction History</a>
						</li>			  
					</ul>	  
				</ng-template>
			</ngb-panel>
			
			
		</ngb-accordion>
	</div>
</div>
<!-- /MY WALLET -->
<!-- MY ORDER -->
<div class="moresidebr" [ngClass]="ordertstatus ? 'showcatg' : ''">
	<h4 class="fs18 f-700 mb-3 p-3 mb-0">
		<a href="javascript:void(0);" (click)="orderEvent()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Group_14366" data-name="Group 14366" transform="translate(-140 -468)">
			<path id="back_3_" data-name="back (3)" d="M19.288,10.1H3.973L8.583,5.487A.871.871,0,1,0,7.351,4.255l-6.1,6.1a.871.871,0,0,0,0,1.231l6.1,6.1a.871.871,0,1,0,1.231-1.231L3.973,11.838H19.288a.871.871,0,0,0,0-1.742Z" transform="translate(141.42 469.033)" fill="#1f1d44"/>
			<g id="Rectangle_1447" data-name="Rectangle 1447" transform="translate(140 468)" fill="none" stroke="#707070" stroke-width="1" opacity="0">
			  <rect width="24" height="24" stroke="none"/>
			  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
			</g>
		  </g>
		</svg>
		</a> 
	All Orders</h4>
	
	<div class="accordionarrow moreacrd morewallet">
		<ngb-accordion #moreacc="ngbAccordion" activeIds="wlt-panel">
			<ngb-panel id="wlt-panel" title="EB Dashboard">
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">						  
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" (click)="moreEvent('/buyer/buyer-track-orders')">Track My Orders</a>
						</li>
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" (click)="moreEvent('/buyer/order-history')">Order History</a>
						</li>			  
					</ul>	  
				</ng-template>
			</ngb-panel>
			
			
		</ngb-accordion>
	</div>
</div>
<!-- /MY ORDER -->
<!-- MY POFILE -->
<div class="moresidebr" [ngClass]="profilestatus ? 'showcatg' : ''">
	<h4 class="fs18 f-700 mb-3 p-3 mb-0">
		<a href="javascript:void(0);" (click)="profileEvent()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Group_14366" data-name="Group 14366" transform="translate(-140 -468)">
			<path id="back_3_" data-name="back (3)" d="M19.288,10.1H3.973L8.583,5.487A.871.871,0,1,0,7.351,4.255l-6.1,6.1a.871.871,0,0,0,0,1.231l6.1,6.1a.871.871,0,1,0,1.231-1.231L3.973,11.838H19.288a.871.871,0,0,0,0-1.742Z" transform="translate(141.42 469.033)" fill="#1f1d44"/>
			<g id="Rectangle_1447" data-name="Rectangle 1447" transform="translate(140 468)" fill="none" stroke="#707070" stroke-width="1" opacity="0">
			  <rect width="24" height="24" stroke="none"/>
			  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
			</g>
		  </g>
		</svg>
		</a> 
	My Profile</h4>
	
	<div class="accordionarrow moreacrd morewallet">
		<ngb-accordion #moreacc="ngbAccordion" activeIds="wlt-panel">
			<ngb-panel id="wlt-panel" title="EB Dashboard">
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">						  
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" (click)="moreEvent('/buyer/personal-details')">Personal Details</a>
						</li>
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" (click)="moreEvent('/buyer/business-details')">Business Details</a>
						</li>	
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" (click)="moreEvent('/buyer/bank-details')">Bank Details</a>
						</li>
					</ul>	  
				</ng-template>
			</ngb-panel>
			
			
		</ngb-accordion>
	</div>
</div>
<!-- /MY POFILE -->
<!-- MY WISHLIST -->
<div class="moresidebr" [ngClass]="wishliststatus ? 'showcatg' : ''">
	<h4 class="fs18 f-700 mb-3 p-3 mb-0">
		<a href="javascript:void(0);" (click)="wishlistEvent()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Group_14366" data-name="Group 14366" transform="translate(-140 -468)">
			<path id="back_3_" data-name="back (3)" d="M19.288,10.1H3.973L8.583,5.487A.871.871,0,1,0,7.351,4.255l-6.1,6.1a.871.871,0,0,0,0,1.231l6.1,6.1a.871.871,0,1,0,1.231-1.231L3.973,11.838H19.288a.871.871,0,0,0,0-1.742Z" transform="translate(141.42 469.033)" fill="#1f1d44"/>
			<g id="Rectangle_1447" data-name="Rectangle 1447" transform="translate(140 468)" fill="none" stroke="#707070" stroke-width="1" opacity="0">
			  <rect width="24" height="24" stroke="none"/>
			  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
			</g>
		  </g>
		</svg>
		</a> 
	My Wishlist</h4>
	
	<div class="accordionarrow moreacrd morewallet">
		<ngb-accordion #moreacc="ngbAccordion" activeIds="wlt-panel">
			<ngb-panel id="wlt-panel" title="EB Dashboard">
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">						  
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" (click)="moreEvent('/buyer/buyer-wishlist')">Wishlist</a>
						</li>						
					</ul>	  
				</ng-template>
			</ngb-panel>
			
			
		</ngb-accordion>
	</div>
</div>
<!-- /MY WISHLIST -->
<!-- MY CATEGORY -->
<div class="moresidebr" [ngClass]="categorystatus ? 'showcatg' : ''">
	<h4 class="fs18 f-700 mb-3 p-3 mb-0">
		<a href="javascript:void(0);" (click)="categoryEvent()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Group_14366" data-name="Group 14366" transform="translate(-140 -468)">
			<path id="back_3_" data-name="back (3)" d="M19.288,10.1H3.973L8.583,5.487A.871.871,0,1,0,7.351,4.255l-6.1,6.1a.871.871,0,0,0,0,1.231l6.1,6.1a.871.871,0,1,0,1.231-1.231L3.973,11.838H19.288a.871.871,0,0,0,0-1.742Z" transform="translate(141.42 469.033)" fill="#1f1d44"/>
			<g id="Rectangle_1447" data-name="Rectangle 1447" transform="translate(140 468)" fill="none" stroke="#707070" stroke-width="1" opacity="0">
			  <rect width="24" height="24" stroke="none"/>
			  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
			</g>
		  </g>
		</svg>
		</a> 
	Select Product Categories</h4>
	
	<div class="accordionarrow moreacrd morewallet">
		<ngb-accordion #moreacc="ngbAccordion" activeIds="wlt-panel">
			<ngb-panel id="wlt-panel" title="">
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">						  
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" (click)="moreEvent('/buyer/manage-categories')">Manage Categories</a>
						</li>						
					</ul>	  
				</ng-template>
			</ngb-panel>
			
			
		</ngb-accordion>
	</div>
</div>
<!-- /MY CATEGORY -->
<!-- MY INVOICE -->
<div class="moresidebr" [ngClass]="invoicestatus ? 'showcatg' : ''">
	<h4 class="fs18 f-700 mb-3 p-3 mb-0">
		<a href="javascript:void(0);" (click)="invoiceEvent()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Group_14366" data-name="Group 14366" transform="translate(-140 -468)">
			<path id="back_3_" data-name="back (3)" d="M19.288,10.1H3.973L8.583,5.487A.871.871,0,1,0,7.351,4.255l-6.1,6.1a.871.871,0,0,0,0,1.231l6.1,6.1a.871.871,0,1,0,1.231-1.231L3.973,11.838H19.288a.871.871,0,0,0,0-1.742Z" transform="translate(141.42 469.033)" fill="#1f1d44"/>
			<g id="Rectangle_1447" data-name="Rectangle 1447" transform="translate(140 468)" fill="none" stroke="#707070" stroke-width="1" opacity="0">
			  <rect width="24" height="24" stroke="none"/>
			  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
			</g>
		  </g>
		</svg>
		</a> 
	Deal Invoices and Documents</h4>
	
	<div class="accordionarrow moreacrd morewallet">
		<ngb-accordion #moreacc="ngbAccordion" activeIds="wlt-panel">
			<ngb-panel id="wlt-panel" title="">
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">						  
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);">My Deal Invoices</a>
						</li>	
						<li class="nav-item">
							<a class="nav-link " href="javascript:void(0);">My Charges Invoices</a>
						</li>	
						<li class="nav-item">
							<a class="nav-link " href="javascript:void(0);">My Transportation Document</a>
						</li>	
					</ul>	  
				</ng-template>
			</ngb-panel>
			
			
		</ngb-accordion>
	</div>
</div>
<!-- /MY INVOICE -->
<!-- REPORTS-->
<div class="moresidebr" [ngClass]="reportstatus ? 'showcatg' : ''">
	<h4 class="fs18 f-700 mb-3 p-3 mb-0">
		<a href="javascript:void(0);" (click)="reportEvent()"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Group_14366" data-name="Group 14366" transform="translate(-140 -468)">
			<path id="back_3_" data-name="back (3)" d="M19.288,10.1H3.973L8.583,5.487A.871.871,0,1,0,7.351,4.255l-6.1,6.1a.871.871,0,0,0,0,1.231l6.1,6.1a.871.871,0,1,0,1.231-1.231L3.973,11.838H19.288a.871.871,0,0,0,0-1.742Z" transform="translate(141.42 469.033)" fill="#1f1d44"/>
			<g id="Rectangle_1447" data-name="Rectangle 1447" transform="translate(140 468)" fill="none" stroke="#707070" stroke-width="1" opacity="0">
			  <rect width="24" height="24" stroke="none"/>
			  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
			</g>
		  </g>
		</svg>
		</a> 
	Business Reports</h4>
	
	<div class="accordionarrow moreacrd morewallet">
		<ngb-accordion #moreacc="ngbAccordion" activeIds="wlt-panel">
			<ngb-panel id="wlt-panel" title="EB Dashboard">
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">						  
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);">Tax Report</a>
						</li>	
						<li class="nav-item">
							<a class="nav-link " href="javascript:void(0);">Purchase Report</a>
						</li>	
						<li class="nav-item">
							<a class="nav-link " href="javascript:void(0);">Payment Report</a>
						</li>
						<li class="nav-item">
							<a class="nav-link " href="javascript:void(0);">Order Report</a>
						</li>
					</ul>	  
				</ng-template>
			</ngb-panel>
			
			
		</ngb-accordion>
	</div>
</div>
<!-- /REPORTS -->
<!-- Categories -->
<!--div class="moresidebr" [ngClass]="catstatus ? 'showcatg' : ''">
	<h4 class="fs18 f-700 mb-3 p-3 mb-0">Categories</h4>	
	<div class="accordionarrow moreacrd">
		<ngb-accordion #moreacc="ngbAccordion" activeIds="ngb-panel-0">
			<ngb-panel title="Consumer Electronics">
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">
						  <li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >My Profile</a>
						  </li>
						  <li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >
								Wallets and Payments
								<span class="weight500 fs16 wltpay">₹ 1,05,668.8</span>
							</a>
						  </li>
						  <li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >My Orders</a>
						  </li>
						  <li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >My Wishlist</a>
						  </li>
						   <li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >My Categories</a>
						  </li>
						  <li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >My Invoices</a>
						  </li>
						  <li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Reports</a>
						  </li>					  
					</ul>	  
				</ng-template>
			</ngb-panel>
			<ngb-panel title="Equibiz Exclusive">
				<ng-template ngbPanelContent>
					<ul class="nav flex-column">
						  <li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Market Place for Brand & OEM</a>
						  </li>
						  <li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Market Place for OEM and Hardware Suppliers</a>
						  </li>
						  <li class="nav-item th-cmn-bd pb-2 mb-2">
							<a class="nav-link active" href="javascript:void(0);" >Component Supplier</a>
						  </li>
						  <li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Latest Gadgets on Equibiz</a>
						  </li>
						   <li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Latest gadgets listed by importers</a>
						  </li>						 				  
					</ul>
				</ng-template>
			</ngb-panel>
			<ngb-panel title="Assistance">
				<ng-template ngbPanelContent>
				  <ul class="nav flex-column">
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Delivery</a>
						</li>
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Product & Stock</a>
						</li>
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Collection</a>
						</li>
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Data Privacy</a>
						</li>
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Order Cancellation</a>
						</li>	
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Payment & Promo</a>
						</li>
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Returns & Refunds</a>
						</li>
						<li class="nav-item">
							<a class="nav-link active" href="javascript:void(0);" >Equibiz Account</a>
						</li>	
						
					</ul>
					<div class="p-3 pb-4 pt-3" >
						<a class="btn-d-outline mr-2 px-4 d-inline-block place-bid" href="javascript:void(0);" >Help Center</a>
					</div>
				</ng-template>
			</ngb-panel>
		</ngb-accordion>
	</div>
</div -->
<!-- /Categories -->

<!-- Categories -->
<div class="moresidebr" [ngClass]="catstatus ? 'showcatg' : ''">
	<h4 class="fs18 f-700 mb-3 p-3 mb-0">
		<a href="javascript:void(0);" (click)="swcatgkEvent()">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		  <g id="Group_14366" data-name="Group 14366" transform="translate(-140 -468)">
			<path id="back_3_" data-name="back (3)" d="M19.288,10.1H3.973L8.583,5.487A.871.871,0,1,0,7.351,4.255l-6.1,6.1a.871.871,0,0,0,0,1.231l6.1,6.1a.871.871,0,1,0,1.231-1.231L3.973,11.838H19.288a.871.871,0,0,0,0-1.742Z" transform="translate(141.42 469.033)" fill="#1f1d44"/>
			<g id="Rectangle_1447" data-name="Rectangle 1447" transform="translate(140 468)" fill="none" stroke="#707070" stroke-width="1" opacity="0">
			  <rect width="24" height="24" stroke="none"/>
			  <rect x="0.5" y="0.5" width="23" height="23" fill="none"/>
			</g>
		  </g>
		</svg>  
		</a> 
		Categories
	</h4>	
	<div class="p-3">
		<div class="eqmenu-section">
          <ngb-accordion [closeOthers]="true" activeIds="static-1" class="accordion theme-accordion" id="accordionExample">
            <ngb-panel *ngFor="let category of categories; let i = index;" id="static-{{i+1}}" title="{{ category.name }}">
                <ng-template ngbPanelContent>
					<!-- activeIds="mbpanel1" -->
					<ngb-accordion #a="ngbAccordion" class="sbchldcstm">
						<ngb-panel  *ngFor="let cat of category.child; let k = index;" id="mbpanel{{ k+1 }}">
							<ng-template ngbPanelHeader let-opened="opened">
							  <div class="d-flex align-items-center justify-content-between">
								<h5 class="m-0" *ngIf="cat.ifchild"><img [src]="cat.icon"> {{ cat.name }}</h5>
								<h5 class="m-0" *ngIf="!cat.ifchild" (click)="swcatgkEvent(cat.slug)"><img [src]="cat.icon"> {{ cat.name }} </h5>
								<button ngbPanelToggle class="btn btn-link p-0" *ngIf="cat.ifchild"></button>
							  </div>
							</ng-template>
							<ng-template ngbPanelContent *ngIf="cat.ifchild">
								<ng-container *ngFor="let sub of cat.child;">
									<h4 class="th-cmn-bd weight500 fs14" *ngIf="sub.ifchild">{{ sub.name }}</h4>
									<h4 class="th-cmn-bd weight500 fs14" (click)="swcatgkEvent(sub.slug)" *ngIf="!sub.ifchild">{{ sub.name }}</h4>
									<ul class="pb-4" *ngIf="sub.ifchild">
										<li *ngFor="let sub1 of sub.child;">
											<a href="javascript:void(0);" (click)="swcatgkEvent(sub1.slug)">{{ sub1.name }}</a>
										</li>
									</ul>
								</ng-container>
							</ng-template>
						</ngb-panel>
					</ngb-accordion>
                </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
	</div>
</div>
<!-- /Categories -->

<app-signup-modal #signIn></app-signup-modal>