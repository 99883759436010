import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { ToastrModule } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { DatePipe } from '@angular/common';
import { AppComponent } from './app.component';
import { WizardModule } from "ngx-wizard";
import { ArchwizardModule } from 'angular-archwizard';
import { ContactUsComponent } from './shared/contact-us/contact-us.component';
import { BuyerAssistanceComponent } from './buyer/buyer-assistance/buyer-assistance.component';
import { MoreArticlesComponent } from './buyer/buyer-assistance/more-articles/more-articles.component';
import { ArticleDetailsComponent } from './buyer/buyer-assistance/article-details/article-details.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { BuyerNotificationComponent } from './buyer/buyer-notification/buyer-notification.component';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { NgSelectizeModule } from 'ng-selectize';
import { BuyerComponent } from './buyer/buyer.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { TokenInterceptorService } from './helpers/token-interceptor.service';
import { ProductDetailsComponent } from './buyer/product-details/product-details.component';
import { BrandComponent } from './buyer/brand/brand.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { TestingComponent } from './testing/testing.component';
import { PaginationService } from './helpers/pagination.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BuyerdashboardComponent } from './buyer/buyerdashboard/buyerdashboard.component';
import { RouterModule } from '@angular/router';
import { SidebarBuyerComponent } from './buyer/buyerdashboard/sidebar-buyer/sidebar-buyer.component';
import { FooterBuyerComponent } from './buyer/buyerdashboard/footer-buyer/footer-buyer.component';
import { HeaderBuyerComponent } from './buyer/buyerdashboard/header-buyer/header-buyer.component';
import { BusinessDetailsBuyerComponent } from './buyer/buyerdashboard/business-details-buyer/business-details-buyer.component';
import { BankDetailsBuyerComponent } from './buyer/buyerdashboard/bank-details-buyer/bank-details-buyer.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PreviewComponent } from './shared/components/modal/preview/preview.component';
import { FilterPipe } from './pipes/filter.pipe';
import { CategoryComponent } from './buyer/category/category.component';
import { SearchComponent } from './buyer/search/search.component';
import { SerllerInfoComponent } from './buyer/serller-info/serller-info.component';
import { AboutSellerComponent } from './buyer/serller-info/about-seller/about-seller.component';
import { HelpComponent } from './buyer/serller-info/help/help.component';
import { SellerProductListingComponent } from './buyer/serller-info/product-listing/product-listing.component';
import { RatingReviewsComponent } from './buyer/serller-info/rating-reviews/rating-reviews.component';
import { DealComponent } from './buyer/deal/deal.component';
import { CartComponent } from './buyer/cart/cart.component';
import { NgbDateCustomParserFormatter } from './helpers/dateformat';
import { BidsOffersComponent } from './buyer/bids-offers/bids-offers.component';
import { MyBidsComponent } from './buyer/my-bids/my-bids.component';
import { BuyerWalletComponent } from './buyer/buyer-wallet/buyer-wallet.component';
import { BuyerAddMoneyComponent } from './buyer/buyer-add-money/buyer-add-money.component';
import { BuyerManagePaymentMethodsComponent } from './buyer/buyer-manage-payment-methods/buyer-manage-payment-methods.component';
import { BuyerAddPaymentMethodComponent } from './buyer/buyer-add-payment-method/buyer-add-payment-method.component';
import { BuyerTransactionHistoryComponent } from './buyer/buyer-transaction-history/buyer-transaction-history.component';
import { BuyerTrackOrdersComponent } from './buyer/buyer-track-orders/buyer-track-orders.component';
import { BuyerViewOrderDetailsComponent } from './buyer/buyer-view-order-details/buyer-view-order-details.component';
import { BuyerWishlistComponent } from './buyer/buyer-wishlist/buyer-wishlist.component';
import { CheckoutComponent } from './buyer/checkout/checkout.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { PersonalDetailsBuyerComponent } from './buyer/personal-details-buyer/personal-details-buyer.component';
import { WriteReviewComponent } from './buyer/write-review/write-review.component';
import { BuyerManageCategoriesComponent } from './buyer/buyer-manage-categories/buyer-manage-categories.component';
import { TermsOfUseComponent } from './shared/terms-of-use/terms-of-use.component';
import { InfringementPolicyComponent } from './shared/infringement-policy/infringement-policy.component';
import { AntiCounterfeitingPolicyComponent } from './shared/anti-counterfeiting-policy/anti-counterfeiting-policy.component';
import { CancellationReturnsPolicyComponent } from './shared/cancellation-returns-policy/cancellation-returns-policy.component';
import { CardConfirmationComponent } from './buyer/card-confirmation/card-confirmation.component';
import { AddMoneyCardConfirmationComponent } from './buyer/add-money-card-confirmation/add-money-card-confirmation.component';
import { PaymentSuccessComponent } from './buyer/payment-success/payment-success.component';
import { PaymentFailedComponent } from './buyer/payment-failed/payment-failed.component';
import { AllBidsAndOffersComponent } from './buyer/all-bids-and-offers/all-bids-and-offers.component';
import { HowItWorksComponent } from './shared/how-it-works/how-it-works.component';
import { ServicesComponent } from './shared/services/services.component';
import { PartnersComponent } from './shared/partners/partners.component';
import { AboutUsComponent } from './shared/about-us/about-us.component';
import { InsurancePolicyComponent } from './shared/insurance-policy/insurance-policy.component';
import { BuyerOrderHistoryComponent } from './buyer/buyer-order-history/buyer-order-history.component';

import { InvoiceComponent } from './buyer/invoice/invoice.component';
import { ReturnPolicyComponent } from './shared/return-policy/return-policy.component';
import { BuyerChargesInvoiceComponent } from './buyer/buyer-charges-invoice/buyer-charges-invoice.component';
import { BuyerTransportationDocumentsComponent } from './buyer/buyer-transportation-documents/buyer-transportation-documents.component';

import { environment } from "../environments/environment";
import { initializeApp } from "firebase/app";
import { BuyerBuyNowPayLaterComponent } from './buyer/buyer-buy-now-pay-later/buyer-buy-now-pay-later.component';
import { BnplPaymentSuccessComponent } from './buyer/bnpl-payment-success/bnpl-payment-success.component';
import { BnplTransactionReportsComponent } from './buyer/bnpl-transaction-reports/bnpl-transaction-reports.component';

initializeApp(environment.firebase);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
    declarations: [
        AppComponent,
        ContactUsComponent,
        BuyerAssistanceComponent,
        MoreArticlesComponent,
        ArticleDetailsComponent,
        PrivacyPolicyComponent,
        BuyerNotificationComponent,
        ScrollSpyDirective,
        BuyerComponent,
        ProductDetailsComponent,
        TestingComponent,
        BuyerdashboardComponent,
        SidebarBuyerComponent,
        FooterBuyerComponent,
        HeaderBuyerComponent,
        BusinessDetailsBuyerComponent,
        BankDetailsBuyerComponent,
        PreviewComponent,
        BrandComponent,
        FilterPipe,
        CategoryComponent,
        SearchComponent,
        SerllerInfoComponent,
        AboutSellerComponent,
        HelpComponent,
        SellerProductListingComponent,
        RatingReviewsComponent,
        DealComponent,
        CartComponent,
        BidsOffersComponent,
        MyBidsComponent,
        BuyerWalletComponent,
        BuyerAddMoneyComponent,
        BuyerManagePaymentMethodsComponent,
        BuyerAddPaymentMethodComponent,
        BuyerTransactionHistoryComponent,
        BuyerTrackOrdersComponent,
        BuyerViewOrderDetailsComponent,
        BuyerWishlistComponent,
        CheckoutComponent,
        PersonalDetailsBuyerComponent,
        WriteReviewComponent,
        BuyerManageCategoriesComponent,
        TermsOfUseComponent,
        InfringementPolicyComponent,
        AntiCounterfeitingPolicyComponent,
        CancellationReturnsPolicyComponent,
        CardConfirmationComponent,
        AddMoneyCardConfirmationComponent,
        PaymentSuccessComponent,
        PaymentFailedComponent,
        AllBidsAndOffersComponent,
        HowItWorksComponent,
        ServicesComponent,
        PartnersComponent,
        AboutUsComponent,
        InsurancePolicyComponent,
        BuyerOrderHistoryComponent,
        InvoiceComponent,
        ReturnPolicyComponent,
        BuyerChargesInvoiceComponent,
        BuyerTransportationDocumentsComponent,
        BuyerBuyNowPayLaterComponent,
        BnplPaymentSuccessComponent,
        BnplTransactionReportsComponent,
           ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        HttpClientModule,
        AngularEditorModule,
        RouterModule,
        ReactiveFormsModule,
        NgbModule,
        NgApexchartsModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        AutocompleteLibModule,
        SocialLoginModule,
        FormsModule,
        CreditCardDirectivesModule,
        DragDropModule,
        SlickCarouselModule,
        NgxSliderModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            progressBar: false,
            enableHtml: true,
            positionClass: 'toast-bottom-center',
            preventDuplicates: true
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        SharedModule,
        AppRoutingModule,
        NgMultiSelectDropDownModule.forRoot(),
        WizardModule,
        ArchwizardModule,
        NgSelectizeModule
    ],
    providers: [
        DatePipe,
        PaginationService,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true },
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider('141155467608-oj5kskrqg5gqdjdatipd9fir79l9pv72.apps.googleusercontent.com'),
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider('3191597614431464')
                    }
                ],
            } as SocialAuthServiceConfig,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
