<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="">
    <div class="equibiz-header">
      <div class="logo-bar">
        <div class="container">
          <div class="row d-flex justify-content-between">
             <div class="col-md-3 d-flex align-items-center">
                <a [routerLink]="['/home']"><img src="assets/images/spice-logo.png"></a>
             </div>
             <div class="col-md-9">
             	<div class="text-right right-header">
             		<ul class="header-dropdown">
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/how-it-works']">Home</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/about-us']">About Us</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/services']" class="active">Services</a></li>
					  <li class="menu-item"><a href="javascript:;" [routerLink]="['/partners']">Partners</a></li>
					</ul>
             	</div>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>

<section class="page-section pt-50 bg-white">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<div class="seller-banner-container" style="background-image: url('assets/images/services.jpg');">
					<div class="row">
						<div class="col-md-6 text-left">
							<h3 class="hs-line-6 font-alt mb-20">
								Experience the best Deal-Making Services on India’s most preferred B2B Platform and Marketplace
							</h3>
							<div class="section-text-xs text-left mb-30">
								Welcome to Equibiz
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-image-blue">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-30">All Services</h2>
			</div>
		</div>

		<div class="row seller-testi-carousel">
	      	<div class="col-md-12">
	      		<owl-carousel-o class="product-m" [options]="ProductSliderConfig">
	      			<ng-template carouselSlide>
						<div class="how-it-works-card services-card">
							<div class="icon">
								<img src="assets/images/icons/how-11.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Partner's Profiling</div>
							<div class="description text-left">
								<ul>
									<li>Equibiz’s onboarding process secures only KYC compliant and trusted B2B partners</li>
									<li>Organised display of sellers’ details to buyers, in order to facilitate informed deal booking over Equibiz</li>
									<li>Dealing with trusted B2B players enables partners to focus on the growth of their business rather than managing risk</li>
									<li>Connect with verified B2B partners and brands across the country</li>
								</ul>
							</div>
						</div>
					</ng-template> 
					<ng-template carouselSlide>
						<div class="how-it-works-card services-card">
							<div class="icon">
								<img src="assets/images/icons/how-13.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Logistics</div>
							<div class="description text-left">
								<ul>
									<li>Hassle-free third-party logistics services provided by Equibiz along with multiple deal booking options help partners to optimise business output</li>
									<li>Basic QC of stock before pick up is done by Equibiz</li>
									<li>Enable pick-up from the supplier place of business</li>
									<li>Track the status of your order</li>
									<li>Flexibility to choose logistic options for better time and cost management</li>
									<li>Last miles pickup and delivery for your convenience</li>
								</ul>
							</div>
						</div>
					</ng-template> 	
					<ng-template carouselSlide>
						<div class="how-it-works-card services-card">
							<div class="icon">
								<img src="assets/images/icons/how-07.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Secured Payment Mechanism</div>
							<div class="description text-left">
								<ul>
									<li>Simple onboarding process with virtual account number to track all payment and receipts</li>
									<li>Nodal account to hold payment for zero risk</li>
									<li>Seamless receipt and payment of funds through Net Banking and other payment gateway options</li>
									<li>Delivery vs payment mechanism protects partners from any potential loss</li>
								</ul>
							</div>
						</div>
					</ng-template> 	
					<ng-template carouselSlide>
						<div class="how-it-works-card services-card">
							<div class="icon">
								<img src="assets/images/icons/how-14.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Reliable Logistics</div>
							<div class="description text-left">
								<ul>
									<li>Efficient and cost-effective logistics support with basic quality check of goods before transport</li>
									<li>Track the status of your order</li>
									<li>Flexibility to choose logistics options for better time and cost management</li>
								</ul>
							</div>
						</div>
					</ng-template>
					<ng-template carouselSlide>
						<div class="how-it-works-card services-card">
							<div class="icon">
								<img src="assets/images/icons/how-15.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Negotiate and close deals within your preferred price bracket</div>
							<div class="description text-left">
								Enjoy best prices through our price discovery method
							</div>
						</div>
					</ng-template>
					<ng-template carouselSlide>
						<div class="how-it-works-card services-card">
							<div class="icon">
								<img src="assets/images/icons/how-16.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">100% Safe</div>
							<div class="description text-left">
								<ul>
									<li>Payment protection through Escrow/ Nodal Account</li>
									<li>Dispute Resolution</li>
								</ul>
							</div>
						</div>
					</ng-template> 
					<ng-template carouselSlide>
						<div class="how-it-works-card services-card">
							<div class="icon">
								<img src="assets/images/icons/how-17.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Gain good business reputation</div>
							<div class="description text-left">
								Online business profile creation through ratings and reviews
							</div>
						</div>
					</ng-template>
					<ng-template carouselSlide>
						<div class="how-it-works-card services-card">
							<div class="icon">
								<img src="assets/images/icons/how-18.png">
							</div>
							<div class="fs18 f-700 mb-10 text-dark">Reliable and Secure</div>
							<div class="description text-left">
								<ul>
									<li>Fully secured Payment System with Latest Features: Powered By AWS</li>
									<li>Equibiz acts as a custodian of money for buyers and goods for suppliers</li>
									<li>Equibiz secures only KYC compliant and trusted B2B partners</li>
									<li>Organised display of seller’s details to buyer, in order to facilitate informed deal booking over Equibiz</li>
								</ul>
							</div>
						</div>
					</ng-template>
        		</owl-carousel-o>
	      	</div>
	    </div>

	    <div class="row d-flex justify-content-center pt-50 mt-60">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-30">Upcoming Services</h2>
			</div>
		</div>

	    <div class="row d-flex justify-content-center">
	    	<!-- <div class="col-md-4">
	    		<div class="card-white mb-20">
	    			<div class="d-flex align-items-center">
	    				<img src="assets/images/icons/service-01.png" class="mr-3">
	    				<h3 class="mb-0 f-700 fs18 text-dark">Instant Invoicing</h3>
	    			</div>
	    		</div>
	    	</div> -->
	    	<div class="col-md-4">
	    		<div class="card-white mb-20">
	    			<div class="d-flex align-items-center">
	    				<img src="assets/images/icons/service-02.png" class="mr-3">
	    				<h3 class="mb-0 f-700 fs18 text-dark">Easy Finance</h3>
	    			</div>
	    		</div>
	    	</div>
	    	<!-- <div class="col-md-4">
	    		<div class="card-white mb-20">
	    			<div class="d-flex align-items-center">
	    				<img src="assets/images/icons/service-03.png" class="mr-3">
	    				<h3 class="mb-0 f-700 fs18 text-dark">E-way Bill</h3>
	    			</div>
	    		</div>
	    	</div> -->
	    	<!-- <div class="col-md-4">
	    		<div class="card-white">
	    			<div class="d-flex align-items-center">
	    				<img src="assets/images/icons/service-04.png" class="mr-3">
	    				<h3 class="mb-0 f-700 fs18 text-dark">Process automation</h3>
	    			</div>
	    		</div>
	    	</div>
	    	<div class="col-md-4">
	    		<div class="card-white">
	    			<div class="d-flex align-items-center">
	    				<img src="assets/images/icons/service-05.png" class="mr-3">
	    				<h3 class="mb-0 f-700 fs18 text-dark">Process automation</h3>
	    			</div>
	    		</div>
	    	</div> -->
	    	<div class="col-md-4">
	    		<div class="card-white">
	    			<div class="d-flex align-items-center">
	    				<img src="assets/images/icons/service-06.png" class="mr-3">
	    				<h3 class="mb-0 f-700 fs18 text-dark">Android and iOS Supported</h3>
	    			</div>
	    		</div>
	    	</div>
	    </div>
	</div>
</section>

<section class="page-section bg-orange">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12 text-center">
				<h2 class="hs-line-7 font-alt mb-30">How it works</h2>

				<div class="row seller-testi-carousel">
			      	<div class="col-md-12">
			      		<owl-carousel-o class="product-m" [options]="ProductSliderConfig">
			      			<ng-template carouselSlide>
								<div class="how-it-works-card">
									<div class="icon">
										<img src="assets/images/icons/how-01.png">
									</div>
									<div class="fs18 f-700 mb-10 text-dark">Pre-booking Deal Amount</div>
									<div class="description">
										Buyers prefund the wallet with the pre-booking deal amount, platform and logistics charges. Places pre-book request with seller on Equibiz.
									</div>
								</div>
							</ng-template> 
							<ng-template carouselSlide>
								<div class="how-it-works-card">
									<div class="icon">
										<img src="assets/images/icons/how-02.png">
									</div>
									<div class="fs18 f-700 mb-10 text-dark">Modify Order</div>
									<div class="description">
										Seller accepts or rejects the request. If rejected, the buyer is notified and can then initiate a new trade. Pre-booking amount platform and logistic charges are deducted from buyer’s wallet upon acceptance of the trade by the seller.
									</div>
								</div>
							</ng-template> 	
							<ng-template carouselSlide>
								<div class="how-it-works-card">
									<div class="icon">
										<img src="assets/images/icons/how-05.png">
									</div>
									<div class="fs18 f-700 mb-10 text-dark">Balance Payment</div>
									<div class="description">
										On acceptance, the deal is confirmed and the buyer must transfer the balance payment to the supplier's account.
									</div>
								</div>
							</ng-template> 	
							<ng-template carouselSlide>
								<div class="how-it-works-card">
									<div class="icon">
										<img src="assets/images/icons/how-03.png">
									</div>
									<div class="fs18 f-700 mb-10 text-dark">Picks Up the Stock</div>
									<div class="description">
										Once stock status is updated as ‘Ready’ by the seller, Equibiz arranges a QC and a logistics partner picks up the stock, as per the seller's instructions.
									</div>
								</div>
							</ng-template> 
							<ng-template carouselSlide>
								<div class="how-it-works-card">
									<div class="icon">
										<img src="assets/images/icons/how-04.png">
									</div>
									<div class="fs18 f-700 mb-10 text-dark">Stock is Dispatched</div>
									<div class="description">
										Stock is dispatched as per the chosen mode of transportation, buyer is notified on arrival.
									</div>
								</div>
							</ng-template>
							<ng-template carouselSlide>
								<div class="how-it-works-card">
									<div class="icon">
										<img src="assets/images/icons/how-04.png">
									</div>
									<div class="fs18 f-700 mb-10 text-dark">Successful Transaction</div>
									<div class="description">
										On acceptance of delivery, the payment is released to supplier after deduction of platform charges and other statutory deductions such as TDS, TCS, etc Parties are notified of a successful transaction.
									</div>
								</div>
							</ng-template>
		        		</owl-carousel-o>
			      	</div>
			    </div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-light-blue">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<h2 class="hs-line-7 text-center font-alt mb-10">Ultimate Trading Platform</h2>
			</div>
		</div>

		<div class="row d-flex justify-content-center">
			<div class="col-md-5 text-center">
				<p>eQuibiz is the one-stop shop for B2B players looking to boost their business. Here’s what sets us apart</p>
			</div>
		</div>

		<div class="row mt-40">
			<div class="col-md-4">
				<div class="card-alt set-h3 mb-20">
					<svg id="store" xmlns="http://www.w3.org/2000/svg" width="39.882" height="34.896" viewBox="0 0 39.882 34.896">
						<defs>
						    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
						      <stop offset="0" stop-color="#7fbbff"/>
						      <stop offset="1" stop-color="#0278ff"/>
						    </linearGradient>
					  	</defs>
					  	<g id="Group_16705" data-name="Group 16705" transform="translate(0 0)">
						    <g id="Group_16704" data-name="Group 16704">
						      <path id="Path_9720" data-name="Path 9720" d="M39.844,42.915l-2.256-9.028A2.491,2.491,0,0,0,35.171,32H4.714a2.5,2.5,0,0,0-2.42,1.887L.037,42.915a1.219,1.219,0,0,0-.037.3,6.089,6.089,0,0,0,5.92,6.232,5.817,5.817,0,0,0,4.674-2.41,5.736,5.736,0,0,0,9.347,0,5.736,5.736,0,0,0,9.347,0,5.813,5.813,0,0,0,4.674,2.41,6.089,6.089,0,0,0,5.92-6.232A1.219,1.219,0,0,0,39.844,42.915Z" transform="translate(0 -32)" fill="url(#linear-gradient)"/>
						    </g>
					  	</g>
					  	<g id="Group_16707" data-name="Group 16707" transform="translate(2.493 18.475)">
					    <g id="Group_16706" data-name="Group 16706">
					      <path id="Path_9721" data-name="Path 9721" d="M63.469,270.65a8.155,8.155,0,0,1-4.674-1.466,8.388,8.388,0,0,1-9.347,0,8.388,8.388,0,0,1-9.347,0,8.092,8.092,0,0,1-8.1.69v13.238a2.494,2.494,0,0,0,2.493,2.493h9.97v-9.97h9.97v9.97H64.4a2.494,2.494,0,0,0,2.493-2.493V269.874A8.087,8.087,0,0,1,63.469,270.65Z" transform="translate(-32 -269.184)" fill="url(#linear-gradient)"/>
					    </g>
					  </g>
					</svg>
					<h4>Seamless Platform</h4>
					<p>Seamless deal booking experiences with end to end solutions which aid businesses to efficiently manage their demand and supply</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt set-h3 mb-20">
					<svg id="rupee_14_" data-name="rupee (14)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43.667" height="43.667" viewBox="0 0 43.667 43.667">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7fbbff"/>
					      <stop offset="1" stop-color="#0278ff"/>
					    </linearGradient>
					  </defs>
					  <path id="Path_9717" data-name="Path 9717" d="M105.072,91a14.072,14.072,0,1,0,14.072,14.072A14.089,14.089,0,0,0,105.072,91Zm6.4,7.676h-3.84a6.367,6.367,0,0,1,1.139,2.559h2.7v2.559h-2.7a6.408,6.408,0,0,1-4.972,4.987l4.81,5.7-1.954,1.649-8.253-9.78h4.113a3.832,3.832,0,0,0,3.6-2.559h-7.44v-2.559h7.44a3.832,3.832,0,0,0-3.6-2.559H98.676V96.117h12.793Z" transform="translate(-83.239 -83.239)" fill="url(#linear-gradient)"/>
					  <path id="Path_9718" data-name="Path 9718" d="M21.833,0A21.833,21.833,0,1,0,43.667,21.833,21.919,21.919,0,0,0,21.833,0Zm0,38.464A16.631,16.631,0,1,1,38.464,21.833,16.65,16.65,0,0,1,21.833,38.464Z" fill="url(#linear-gradient)"/>
					</svg>
					<h4>Price Discovery Model</h4>
					<p>Equibiz aggregates demand and supply over the platform, and facilitates buyers and sellers to initiate transactions based on their agreed price and deal terms</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt set-h3 mb-20">
					<svg id="shield_2_" data-name="shield (2)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="31.739" height="38.59" viewBox="0 0 31.739 38.59">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7fbbff"/>
					      <stop offset="1" stop-color="#0278ff"/>
					    </linearGradient>
					  </defs>
					  <g id="Group_16703" data-name="Group 16703" transform="translate(0 0)">
					    <path id="Path_9719" data-name="Path 9719" d="M76.561,8.857,61.978.181a1.287,1.287,0,0,0-1.315,0L46.08,8.857a1.287,1.287,0,0,0-.629,1.106v4.952c0,10.431,6.027,19.958,15.4,23.589a1.286,1.286,0,0,0,.929,0c9.355-3.622,15.4-13.128,15.4-23.589V9.962A1.286,1.286,0,0,0,76.561,8.857ZM67.675,16.4l-8.062,8.062a1.286,1.286,0,0,1-1.819,0l-2.829-2.829a1.286,1.286,0,0,1,1.819-1.819l1.92,1.92,7.153-7.153A1.286,1.286,0,0,1,67.675,16.4Z" transform="translate(-45.451 0)" fill="url(#linear-gradient)"/>
					  </g>
					</svg>
					<h4>Secured Payment and Settlement System</h4>
					<p>Equibiz acts as a custodian of money and goods, ensuring minimal risk to our partners</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt set-h3 mb-20">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="57.118" height="37.597" viewBox="0 0 57.118 37.597">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7fbbff"/>
					      <stop offset="1" stop-color="#0278ff"/>
					    </linearGradient>
					  </defs>
					  <g id="user_33_" data-name="user (33)" transform="translate(0.5 0.499)">
					    <path id="Union_2" data-name="Union 2" d="M-5952.2-696.1a3.6,3.6,0,0,0,.226-1.219,18.19,18.19,0,0,0-3.5-10.717,10.98,10.98,0,0,1,2.276-.263,10.994,10.994,0,0,1,10.98,10.98,1.221,1.221,0,0,1-1.221,1.219Zm-32.713,0a1.22,1.22,0,0,1-1.219-1.221,15.877,15.877,0,0,1,15.859-15.858,15.877,15.877,0,0,1,15.859,15.858,1.222,1.222,0,0,1-1.221,1.221Zm-12.2,0a1.22,1.22,0,0,1-1.221-1.219,10.992,10.992,0,0,1,10.979-10.98,10.983,10.983,0,0,1,2.278.263,18.186,18.186,0,0,0-3.5,10.717,3.612,3.612,0,0,0,.224,1.219Zm37.817-20.74a6.1,6.1,0,0,1,6.1-6.1,6.1,6.1,0,0,1,6.1,6.1,6.1,6.1,0,0,1-6.1,6.1A6.1,6.1,0,0,1-5959.3-716.838Zm-33.984,0a6.1,6.1,0,0,1,6.1-6.1,6.1,6.1,0,0,1,6.1,6.1,6.1,6.1,0,0,1-6.1,6.1A6.1,6.1,0,0,1-5993.279-716.838Zm14.465-7.319a8.539,8.539,0,0,1,8.54-8.539,8.539,8.539,0,0,1,8.54,8.539,8.54,8.54,0,0,1-8.54,8.54A8.54,8.54,0,0,1-5978.814-724.157Z" transform="translate(5998.333 732.696)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill="url(#linear-gradient)"/>
					  </g>
					</svg>
					<h4>Genuine Buyers and Sellers</h4>
					<p>Mandatory KYC to ensure you only deal with verified B2B partners</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt set-h3 mb-20">
					<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="43.723" height="45.758" viewBox="0 0 43.723 45.758">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7fbbff"/>
					      <stop offset="1" stop-color="#0278ff"/>
					    </linearGradient>
					  </defs>
					  <path id="Support_1_" d="M44.654,23.43V21.293a17.293,17.293,0,1,0-34.585,0V23.43A5.094,5.094,0,0,0,6,28.413v5.595a5.092,5.092,0,0,0,5.086,5.086h4.069a2.036,2.036,0,0,0,2.034-2.034v-11.7a2.036,2.036,0,0,0-2.034-2.034H12.1V21.293a15.162,15.162,0,0,1,2.465-8.3l.48.48a3.043,3.043,0,0,0,2.168.9h.017a2.977,2.977,0,0,0,2.131-.9,11.18,11.18,0,0,1,16,0,2.977,2.977,0,0,0,2.13.9h.017a3.045,3.045,0,0,0,2.168-.9l.48-.48a15.162,15.162,0,0,1,2.465,8.3v2.034H39.568a2.036,2.036,0,0,0-2.034,2.034v11.7a2.036,2.036,0,0,0,2.034,2.034H42.62v2.034a3.055,3.055,0,0,1-3.052,3.052H31.789a3.565,3.565,0,0,0-3.41-2.543H26.344a3.56,3.56,0,0,0,0,7.121h2.034a3.566,3.566,0,0,0,3.41-2.543h7.779a5.092,5.092,0,0,0,5.086-5.086V38.991a5.1,5.1,0,0,0,4.069-4.983V28.413a5.094,5.094,0,0,0-4.069-4.983Z" transform="translate(-5.5 -3.5)" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1" fill="url(#linear-gradient)"/>
					</svg>
					<h4>Round the Clock Customer Support</h4>
					<p>We are listening to you. Approach our fantastic customer support with your queries</p>
				</div>
			</div>
			<div class="col-md-4">
				<div class="card-alt set-h3 mb-20">
					<svg id="insurance_3_" data-name="insurance (3)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32.754" height="43" viewBox="0 0 32.754 43">
					  <defs>
					    <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
					      <stop offset="0" stop-color="#7fbbff"/>
					      <stop offset="1" stop-color="#0278ff"/>
					    </linearGradient>
					  </defs>
					  <path id="Path_9714" data-name="Path 9714" d="M351,8.787v7.66h7.66Z" transform="translate(-326.645 -8.049)" fill="url(#linear-gradient)"/>
					  <path id="Path_9715" data-name="Path 9715" d="M180.732,192h-5.186A6.647,6.647,0,0,1,171,195.242v6.046a10.457,10.457,0,0,0,7.139,9.966,10.457,10.457,0,0,0,7.139-9.966v-6.046A6.647,6.647,0,0,1,180.732,192Zm1.861,8.174-4.751,4.751a1.26,1.26,0,0,1-1.781,0l-2.375-2.375a1.26,1.26,0,0,1,1.782-1.782l1.485,1.485,3.86-3.86a1.26,1.26,0,1,1,1.781,1.782Z" transform="translate(-161.762 -175.875)" fill="url(#linear-gradient)"/>
					  <path id="Path_9716" data-name="Path 9716" d="M84.1,10.918a1.26,1.26,0,0,1-1.26-1.26V0H65.619A4.624,4.624,0,0,0,61,4.619V38.381A4.624,4.624,0,0,0,65.619,43H89.135a4.624,4.624,0,0,0,4.619-4.619V10.918Zm2.939,14.5a13.044,13.044,0,0,1-9.3,12.494,1.259,1.259,0,0,1-.724,0,13.044,13.044,0,0,1-9.3-12.494V18.225a1.26,1.26,0,0,1,1.26-1.26,4.153,4.153,0,0,0,3.735-2.308l.177-.355a1.26,1.26,0,0,1,1.127-.7h6.719a1.26,1.26,0,0,1,1.127.7l.177.355a4.153,4.153,0,0,0,3.735,2.308,1.26,1.26,0,0,1,1.26,1.26Z" transform="translate(-61)" fill="url(#linear-gradient)"/>
					</svg>
					<h4>Transparent Policies, Processes and Charges</h4>
					<p>Transparent policies and processes ensure protection of interest for all our partners</p>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="page-section bg-white" *ngIf="!user_token">
	<div class="container">
		<div class="row d-flex justify-content-center">
			<div class="col-md-12">
				<a href="javascript:;" (click)="SignIn.openSigninModal('home')">
					<img src="assets/images/trade-now.svg" class="img-fluid">
				</a>
			</div>
		</div>	
	</div>
</section>
<app-footer-one></app-footer-one>

<app-signup-modal #signIn></app-signup-modal>